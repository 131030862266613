import React, { useState } from 'react';
import './Image.scss'

const Image = (props) => {
    const { file } = props
    const [fullscreen, setFullscreen] = useState(false)
    return (
        <div className="image-component">
            <div className="view" onClick={() => setFullscreen(true)}>
                <img src={file} alt="manual image" />
            </div>

            {
                fullscreen && (
                    <div className="fullscreen" onClick={() => setFullscreen(false)}>
                        <img src={file} alt="manual image" />
                    </div>
                )
            }
        </div>
    )
}

export default Image;