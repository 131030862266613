import React from 'react';
import { Link } from 'react-router-dom';
import Image from '../Components/Image/Image';

import image1 from '../assets/img/manuals/companies01.png'
import image2 from '../assets/img/manuals/companies02.png'
import image3 from '../assets/img/manuals/companies03.png'
import image4 from '../assets/img/manuals/companies04.png'
import image5 from '../assets/img/manuals/companies05.png'

import './Manuals.scss';

const Companies = (props) => {
    const { } = props;

    return (
        <div className="manuals">
            <h3>Empresas</h3>

            <p>
                Una empresa es un tipo de usuario del sistema. <br />
                Una empresa es responsable de contratar uno o más Lockers. <br />
                Una empresa puede ser del tipo Residentes o del tipo Retiro em Tienda.
            </p>

            <p>
                <ul>
                    <li>
                        <span>Empresa con Residentes:</span> Una empresa con residentes es un edificio que proporciona un
                        sistema de entrega de pedidos para sus residentes a través de un casillero inteligente.
                        El pedido puede ser retirado a través de un codigo alfanumérico o qrcode entregado por sms y correo electrónico.
                    </li>
                    <li>
                        <span>Empresa Retiro en Tienda:</span> Una empresa Retiro en Tienda, es una empresa que ofrece un
                        sistema de retiro inteligente, donde el cliente despues de comprar puede retirar fácilmente su pedido en un
                        Locker a través de un código alfanumérico o qrcode entregado por sms y correo electrónico.
                    </li>
                </ul>
            </p>

            <h3>Menu Empresa</h3>

            <p>
                1 - Haga clic en Empresas en el menú de la izquierda.
            </p>

            <Image file={image3} />

            <h3>Terminales</h3>

            <p>
                Aquí puede ver a qué terminal está asociada una empresa.
            </p>

            <p>
                1 - Primero seleccione la empresa y luego el service station
            </p>

            <p>
                2 - Los terminales asociados a esta empresa se mostrarán en una tabla por service station.
            </p>

            <Image file={image4} />

            <h3>Retiro en Tienda</h3>

            <p>
                Esta opción se usa para habilitar o deshabilitar la función de retiro de tienda de una empresa.
                Cuando esta opción está habilitada, automáticamente en el menú del dashboard de esta empresa,
                la opción Residentes es reemplazada por Agregar Pedido.
            </p>

            <Image file={image5} />

            <h3>Crear Residentes</h3>

            <p>
                Ver manual de <Link to="/admin/manuals/residents"> Residentes </Link>
            </p>

            <h3>Crear Clientes</h3>



            <p>
                1 - Seleccione una Empresa y un Service Station.
            </p>

            <Image file={image1} />

            <p>
                2 - Completar los campos
            </p>

            <Image file={image2} />

            <p>
                3 - Guardar
            </p>
        </div>
    )
}

export default Companies;