import React from 'react';
import { Link } from 'react-router-dom';

import image1 from '../assets/img/manuals/sameday01.png'
import Image from '../Components/Image/Image';

import './Manuals.scss';

const Sameday = (props) => {
    const { } = props;

    return (
        <div className="manuals">
            <h3>Sameday</h3>

            <p>
                Un sameday es una empresa que puede entregar a un terminal asociado a él por un administrador del sistema.
                Lo que se diferencia de este modelo de negocio es que en el caso de un sameday, las reservas tiene una duración máxima de 24 Horas.
            </p>

            <h3>
                Crear Sameday
            </h3>

            <p>
                Crear usuario con rol sameday <br />
                Ver Crear <Link to="/admin/manuals/users" >Usuario</Link>
            </p>

            <h3>Asociar Terminal a un Sameday</h3>

            <p>
                1 - Para asociar un terminal a un sameday, primero haga clic en Sameday en el menú de la izquierda.
            </p>

            <p>
                2 - Seleccione el sameday que quiere asociar un terminal.
            </p>

            <p>
                3 - Elige las terminales y guarda los cambios.
            </p>

            <Image file={image1} />
        </div>
    )
}

export default Sameday;