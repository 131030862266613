import React from 'react';
import PropTypes from 'prop-types';
import SweetAlert from 'react-bootstrap-sweetalert';

export function AlertDialog(props) {
    const {
        message, show, onConfirm, title, success,
    } = props;
    return (
        <SweetAlert
            title={title}
            show={show}
            error={!success}
            onConfirm={onConfirm}
        >
            {message}
        </SweetAlert>
    );
}

AlertDialog.propTypes = {
    message: PropTypes.string,
    show: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string,
    success: PropTypes.bool,
};

AlertDialog.defaultProps = {
    title: '',
    message: '',
    success: false,
};

export function ConfirmDialog(props) {
    const {
        show, onConfirm, onCancel, title, message, confirmTxt, cancelTxt, btnStyle,
    } = props;
    return (
        <SweetAlert
            show={show}
            warning
            showCancel
            confirmBtnText={confirmTxt}
            cancelBtnText={cancelTxt}
            confirmBtnBsStyle={btnStyle}
            cancelBtnBsStyle="default"
            title={title}
            onConfirm={onConfirm}
            onCancel={onCancel}
        >
            {message === '' ? '¿Está seguro?' : message}
        </SweetAlert>
    );
}

ConfirmDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    title: PropTypes.string,
    message: PropTypes.string,
    confirmTxt: PropTypes.string,
    cancelTxt: PropTypes.string,
    btnStyle: PropTypes.string,

};

ConfirmDialog.defaultProps = {
    title: '',
    message: '',
    confirmTxt: 'Sí, estoy seguro',
    cancelTxt: 'No, Cancelar',
    btnStyle: 'danger',
};
