/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

const Uploader = (props) => {
    const {
        onChange, text, name, accept,
    } = props;
    return (
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <span className="input-group-text py-0" id="inputGroupFileAddon01">Upload</span>
            </div>
            <div className="custom-file">
                <input type="file" className="custom-file-input" id="inputGroupFile01" name={name || 'uploader'} aria-describedby="inputGroupFileAddon01" onChange={onChange} accept={accept} />
                <label className="custom-file-label" htmlFor="inputGroupFile01">{text === undefined ? 'Escoge un archivo' : text}</label>
            </div>
        </div>
    );
};

export default Uploader;
