/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Spinner } from 'reactstrap';
import Input from '../../Components/Input/Input';
import Button from '../../Components/Button/Button';
import { updateBox } from '../../Service/Api';
import { StateContext } from '../../State';

const EditBox = (props) => {
    const context = useContext(StateContext);
    const [state, setState] = useState({
        inputs: {
            BoxDepth: '',
            BoxHeight: '',
            BoxWidth: '',
            BoxTypeName: '',
        },
        loading: false,
        errors: {},
    });

    useEffect(() => {
        const { data } = props;
        const { original } = data;
        const {
            BoxDepth, BoxHeight, BoxWidth, BoxTypeName, BoxType,
        } = original;
        setState({
            ...state,
            inputs: {
                BoxDepth, BoxHeight, BoxWidth, BoxTypeName, BoxType,
            },
        });
    }, []);

    const handleChangeInput = ({ target }) => {
        const { name, value } = target;
        setState({ ...state, inputs: { ...state.inputs, [name]: value } });
    };

    const validForm = () => {
        const { inputs } = state;
        const errors = {};
        let formIsValid = true;
        Object.keys(inputs).forEach((key) => {
            if (inputs[key].trim() === '') {
                errors[key] = 'Requerido';
                formIsValid = false;
            }
        });

        setState({ ...state, errors });

        return formIsValid;
    };

    const handleError = (error) => {
        const { status } = error.response;
        if (status === 401 || status === 403) {
            const [, dispatch] = context;
            dispatch({
                type: 'EXIT',
            });
        }
    };

    const handleUpdate = async () => {
        if (validForm()) {
            setState({ ...state, loading: true });
            const { inputs } = state;
            await updateBox(inputs).then((response) => {
                if (response && response.status === 201) {
                    const { callback } = props;
                    callback();
                }
            }).catch((error) => {
                handleError(error);
            });
            setState({ ...state, loading: false });
        }
    };

    return (
        <>
            <Row>
                <Col md={6}><Input name="BoxTypeName" value={state.inputs.BoxTypeName} onChange={handleChangeInput} label="Nombre Tipo de Caja" placeholder="Nombre del Tipo de Caja" icon="fas fa-box" errors={state.errors} required /></Col>
                <Col md={6}><Input name="BoxDepth" type="number" value={state.inputs.BoxDepth} onChange={handleChangeInput} label="Profundidad" placeholder="Profundidad del Tipo de caja" icon="fas fa-box" errors={state.errors} required /></Col>
                <Col md={6}><Input name="BoxHeight" type="number" value={state.inputs.BoxHeight} onChange={handleChangeInput} label="Altura" placeholder="Altura del Tipo de caja" icon="fas fa-box" errors={state.errors} required /></Col>
                <Col md={6}><Input name="BoxWidth" type="number" value={state.inputs.BoxWidth} onChange={handleChangeInput} label="Anchura" placeholder="Anchura del Tipo de caja" icon="fas fa-box" errors={state.errors} required /></Col>
            </Row>
            <div className="form-footer">
                {state.loading && <div className="spinner"><Spinner /></div>}
                <Button text="Actualizar" onClick={handleUpdate} disabled={state.loading} />
            </div>
        </>
    );
};

export default EditBox;
