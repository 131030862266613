/* eslint-disable react/no-unused-state */
/* eslint-disable no-param-reassign */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';
import TopBar from '../../Components/TopBar/TopBar';
import { StateContext } from '../../State';
import ModalView from '../../Layout/ModalView/ModalView';
import { tableConfig } from '../../config';
import {
    deleteCustomer, restoreUser, getAllBoxes,
} from '../../Service/Api';
import AddBox from './AddBox';
import EditBox from './EditBox';

class Boxes extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            showConfirm: false,
            data: [],
            loading: false,
            deleteId: null,
            restoreId: null,
        };
        this.handleRemove = this.handleRemove.bind(this);
        this.handleRestore = this.handleRestore.bind(this);
        this.loadData = this.loadData.bind(this);
        this.findData = this.findData.bind(this);
        this.removeUser = this.removeUser.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    findData = (id) => {
        const { data } = this.state;
        return data.find((item) => item.CustomerID.toString() === id.toString());
    }

    handleError = (error) => {
        console.log(error);
    }

    async loadData() {
        this.setState({
            loading: true,
        });

        await getAllBoxes()
            .then((response) => {
                const filteredData = response.data.filter((b) => {
                    const reg = new RegExp(/[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/);
                    return !reg.test(b.BoxTypeName);
                });

                filteredData.sort((a, b) => {
                    if (parseInt(a.BoxType) > parseInt(b.BoxType)) return 1;
                    if (parseInt(a.BoxType) < parseInt(b.BoxType)) return -1;
                    return 0;
                });
                this.setState({
                    data: filteredData,
                    loading: false,
                    deleteId: null,
                    restoreId: null,
                });
            }).catch((error) => {
                this.handleError(error);
            });
    }

    async removeUser() {
        const { deleteId } = this.state;
        await deleteCustomer(deleteId).then((response) => {
            if (response && response.status === 200) {
                this.loadData();
            }
        }).catch((error) => {
            this.handleError(error);
        });
    }

    async restoreUser() {
        const { restoreId } = this.state;
        await restoreUser(restoreId).then((response) => {
            if (response && response.status === 200) {
                this.loadData();
            }
        }).catch((error) => {
            this.handleError(error);
        });
    }

    handleNewFields = (terminals) => {
        terminals.forEach((item) => {
            item.box_length = item.boxes.length;
            item.DepartmentName = item.department[0].DepartmentName;
        });
        return terminals;
    }

    handleRemove(id) {
        this.setState({
            showConfirm: true,
            deleteId: id,
        });
    }

    handleRestore(id) {
        this.setState({
            showConfirm: true,
            restoreId: id,
        });
    }

    handleStatus = (status) => {
        switch (parseInt(status)) {
            case 1:
                return 'Retirado';
            case 4:
                return 'Aguardando Courier';
            default:
                return 'En el Locker';
        }
    }

    render() {
        const { data, loading } = this.state;
        const columns = [
            {
                Header: 'ID',
                accessor: (row) => row.BoxType,
                id: 'id',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        {row.original.BoxType}
                    </div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['id'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Nombre',
                accessor: (row) => row.BoxTypeName,
                id: 'name',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'left', paddingLeft: '25px' }}>
                        {row.original.BoxTypeName}
                    </div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['name'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Acciones',
                id: 'actions',
                filterable: false,
                sortable: false,
                maxWidth: 100,
                Cell: (row) => (
                    <div className="form-actions">
                        <ModalView size="lg" title="Editar Caja" type="edit" callback={this.loadData}>
                            <EditBox data={row} />
                        </ModalView>
                    </div>
                ),
            },
        ];

        return (
            <>
                <TopBar>
                    <ModalView size="lg" title="Crear Caja" type="add" callback={this.loadData}>
                        <AddBox />
                    </ModalView>
                </TopBar>
                <ReactTable
                    data={data}
                    columns={columns}
                    {...tableConfig}
                    loading={loading}
                />
            </>
        );
    }
}

Boxes.contextType = StateContext;

export default Boxes;
