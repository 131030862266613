import React from 'react';
import Folder from '../../Components/Folder/Folder';

import './Home.scss'
const Home = (props) => {
    const { history } = props;
    return (
        <div className="manual-content">
            <Folder history={history} url="/admin/company/manuals/api" label="Api Retiro en Tienda" icon="fas fa-code" />
        </div>
    )
}

export default Home;