import React from 'react';
import { Link } from 'react-router-dom';

import image1 from '../assets/img/manuals/residents01.png'
import image2 from '../assets/img/manuals/residents02.png'
import image3 from '../assets/img/manuals/residents03.png'
import Image from '../Components/Image/Image';

import './Manuals.scss';

const Residents = (props) => {
    const { } = props;

    return (
        <div className="manuals">

            <h3>Clientes</h3>

            <p>
                Los clientes son las personas a las que se entregarán los pedidos de una empresa tipo ecommerce o sameday. <br />
                Un cliente está asociado a un service station, lo que hace con que esté disponible en todos los terminales asociados a ese service station.
            </p>

            <h3>Crear Cliente</h3>

            <p>
                Ver manual de <Link to="/admin/manuals/api">api ecommerce</Link>
            </p>
            <p>
                Ver manual de <Link to="/admin/manuals/api">api sameday</Link>
            </p>

        </div>
    )
}

export default Residents;