import React from 'react';
import { CSVLink } from 'react-csv';
import PropTypes from 'prop-types';
import './DownloadCSVButton.scss';

function DownloadCSVButton(props) {
    const { data, filename, disabled } = props;
    if (disabled) {
        return <i className="fas fa-file-download" style={{ fontSize: '30px', margin: '10px 0', color: '#666' }} />
    } else {
        return (
            <CSVLink data={data} filename={filename} separator=";" className="button-download-csv" target="_blank" disabled>
                <i className="fas fa-file-download" />
            </CSVLink>
        );
    }
}

DownloadCSVButton.propTypes = {
    data: PropTypes.array.isRequired,
    filename: PropTypes.string.isRequired,
};

export default DownloadCSVButton;