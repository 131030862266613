/* eslint-disable react/sort-comp */
/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Spinner } from 'reactstrap';
import { StateContext } from '../../State';
import Input from '../../Components/Input/Input';
import Button from '../../Components/Button/Button';
import { createCustomer, updateClient } from '../../Service/Api';

class CustomerForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            createMode: true,
            data: {
                DepartmentID: '-1',
            },
            errors: {},
            loading: false,
        };
        this.handleCreate = this.handleCreate.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const { data } = this.props;
        if (data) {
            this.setState({
                data,
                createMode: false,
            });
        }
    }

    formatDbDate = (data) => {
        const departments = [];
        data.forEach((item) => {
            departments.push({
                id: item.DepartmentID,
                name: item.DepartmentName,
            });
        });
        return departments;
    }

    onChange(event) {
        const { name, value } = event.target;
        const { data, errors } = this.state;
        data[name] = name === 'DepartmentID' ? value.toString() : value;
        errors[name] = '';
        this.setState({
            data,
            errors,
        });
    }

    handleError = (error) => {
        const { status } = error.response;
        if (status === 401 || status === 403) {
            const [, dispatch] = this.context;
            dispatch({
                type: 'EXIT',
            });
        } else {
            this.setState({
                errors: this.formatError(error.response.data),
            });
        }
    }

    formatError = (value) => {
        const errors = [];
        Object.keys(value).forEach((keys) => {
            errors[keys] = value[keys][0];
        });
        return errors;
    }

    validForm() {
        const { data } = this.state;
        const {
            Address, CustomerName, CustomerMobile, Email,
        } = data;
        const errors = {};
        let formIsValid = true;

        if (!CustomerName || CustomerName.trim().length === 0) {
            formIsValid = false;
            errors.CustomerName = ['Requerido'];
        }

        if (!Address || Address.trim().length === 0) {
            formIsValid = false;
            errors.Address = ['Requerido'];
        }

        if (!CustomerMobile || CustomerMobile.trim().length === 0) {
            formIsValid = false;
            errors.CustomerMobile = ['Requerido'];
        }


        if (!Email || Email.trim().length === 0) {
            formIsValid = false;
            errors.Email = ['Requerido'];
        }

        this.setState({
            errors,
        });

        return formIsValid;
    }

    async handleCreate() {
        if (this.validForm()) {
            this.toggleLoading(true);
            const { data } = this.state;
            await createCustomer(data).then((response) => {
                if (response && response.status === 200) {
                    const { callback } = this.props;
                    callback();
                }
            }).catch((error) => {
                this.handleError(error);
            });
            this.toggleLoading(false);
        }
    }

    async handleUpdate() {
        if (this.validForm()) {
            this.toggleLoading(true);
            const { data } = this.state;
            await updateClient(data).then((response) => {
                if (response && response.status === 200) {
                    const { callback } = this.props;
                    callback();
                }
            }).catch((error) => {
                this.handleError(error);
            });
            this.toggleLoading(false);
        }
    }

    toggleLoading(value) {
        this.setState({
            loading: value,
        });
    }

    render() {
        const { data } = this.state;
        const { readOnly } = this.props;
        const {
            createMode, errors, loading,
        } = this.state;

        const {
            Address, CustomerID, CustomerName, CustomerMobile, Email,
        } = data;

        return (
            <>
                <Row>
                    {data.CustomerID && (
                        <Col md={12}><Input name="CustomerID" value={CustomerID} onChange={this.onChange} readOnly label="ID Residente" icon="fas fa-hashtag" errors={errors} required /></Col>
                    )}
                    <Col md={12}><Input name="CustomerName" value={CustomerName} onChange={this.onChange} readOnly={readOnly} label="Nombre" placeholder="Nombre del residente" icon="fas fa-user" errors={errors} required /></Col>
                    <Col md={12}><Input name="Address" value={Address} onChange={this.onChange} readOnly={readOnly} label="Número Departamento" placeholder="Número de departamento" icon="fas fa-map-marked-alt" errors={errors} required /></Col>
                    <Col md={12}><Input name="CustomerMobile" mask="phone" value={CustomerMobile} onChange={this.onChange} readOnly={readOnly} label="Telefono del residente" placeholder="Telefono del Service Station" icon="fas fa-phone" errors={errors} required /></Col>
                    <Col md={12}><Input name="Email" value={Email} onChange={this.onChange} readOnly={readOnly} label="Email" placeholder="Email del residente" icon="fas fa-envelope" errors={errors} required /></Col>
                </Row>
                {!readOnly && (
                    <div className="form-footer">
                        {loading && <div className="spinner"><Spinner /></div>}
                        <Button text={createMode ? 'Crear' : 'Actualizar'} onClick={createMode ? this.handleCreate : this.handleUpdate} />
                    </div>
                )}
            </>
        );
    }
}

CustomerForm.propTypes = {
    callback: PropTypes.func,
    data: PropTypes.object,
    readOnly: PropTypes.bool,
};

CustomerForm.defaultProps = {
    callback: null,
    data: null,
    readOnly: false,
};

CustomerForm.contextType = StateContext;

export default CustomerForm;
