/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/prop-types */
import React from 'react';

const DownloadCsv = (props) => {
    const { handleCreateCsv } = props;

    return <button className="btn p-0" onClick={handleCreateCsv} type="button"><i className="fas fa-file-download" style={{ fontSize: '30px', margin: '10px 0', color: '#666' }} /></button>;
};

export default DownloadCsv;
