/* eslint-disable no-console */
/* eslint-disable no-case-declarations */
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import Button from '../../Components/Button/Button';
import Select from '../../Components/Select/Select';

import { getSamedayEcommerce, saveSamedayEcommerce } from '../../Service/Api';

import './Sameday.scss';

const Sameday = () => {
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState(-1);
    const [terminals, setTerminals] = useState([]);
    const [terminalChecked, setTerminalsChecked] = useState({});
    const [loading, setLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);


    const loadData = () => {
        setLoading(true);
        getSamedayEcommerce().then((response) => {
            setUsers(response.data.users);
            setTerminals(response.data.terminals);
            setLoading(false);
        });
    };

    useEffect(() => {
        loadData();
    }, []);

    const onChange = ({ target }) => {
        const { name, value, checked } = target;

        switch (name) {
            case 'user':
                setUser(value);
                const u = users.find((item) => parseInt(item.id) === parseInt(value));
                let st = {};
                u.sameday_terminal.forEach((item) => {
                    st = { ...st, [item.TerminalNo]: true };
                });
                setTerminalsChecked({ ...st });
                break;
            case 'terminal':
                const tc = { ...terminalChecked, ...{ [value]: checked } };
                setTerminalsChecked(tc);
                break;
            default:
                console.log('Default action selected');
        }
    };

    const handleSubmit = () => {
        const ids = [];
        Object.keys(terminalChecked).forEach((key) => {
            if (terminalChecked[key]) {
                ids.push(key);
            }
        });

        const params = {
            user_id: user,
            terminals: ids,
        };
        setLoading(true);
        saveSamedayEcommerce(params)
            .then(() => {
                setUser(-1);
                loadData();
                setShowMessage(true);
                setTimeout(() => {
                    setShowMessage(false);
                }, 5000);
            });
    };

    return (
        <div className="container-ecommerce">
            <div className="content">
                <Row>
                    <Col md={12}>
                        <Select label="Empresa Sameday" value={user.toString()} options={users} placeholder="Seleccione..." name="user" onChange={onChange} required loading={loading} />
                    </Col>
                </Row>

                <Row className="justify-content-between px-5 mb-3">
                    {user !== -1 && terminals && terminals.map((item) => (
                        <Col md={3} className={terminalChecked[item.TerminalNo] === true ? 'checkbox alert-secondary' : 'checkbox'} key={item.TerminalNo}>
                            <input type="checkbox" name="terminal" onChange={onChange} value={item.TerminalNo} checked={terminalChecked[item.TerminalNo] === true} />
                            {item.TerminalName}
                        </Col>
                    ))}
                </Row>

                {showMessage && (
                    <Row>
                        <Col md={12}>
                            <div className="alert alert-secondary">Datos guardados exitosamente</div>
                        </Col>
                    </Row>
                )}

                {user !== -1 && (
                    <Row>
                        <Col md={12}>
                            <Button text="Guardar" onClick={handleSubmit} disabled={loading} />
                        </Col>
                    </Row>
                )}

            </div>
        </div>
    );
};

export default Sameday;
