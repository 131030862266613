import React from 'react';

import '../Manuals.scss';

const Api = (props) => {
    const { } = props;
    const API = process.env.REACT_APP_API_URL;
    return (
        <div className="manuals">
            <h3>API Sameday</h3>
            <p>Integración con la API de sameday</p>
            <a href={`${API}/documentation/sameday.html`} target="_blank">Ver Documentación</a>
        </div>
    )
}

export default Api;