export default [
    {
        id: 1,
        title: 'Paquetes',
        path: '/admin/packages',
        icon: 'fas fa-box-open',
    },
    {
        id: 2,
        title: 'Histórico',
        path: '/admin/historic',
        icon: 'fas fa-history',
    },
];
