export default [

    {
        id: 8,
        title: 'Usuarios',
        path: '/admin/users',
        icon: 'fas fa-user-friends',
    },
    {
        id: 2,
        title: 'Service Station',
        path: '/admin/service-station',
        icon: 'fas fa-archive',
    },
    {
        id: 1,
        title: 'Terminales',
        path: '/admin/terminals',
        icon: 'fas fa-desktop',
    },
    {
        id: 16,
        title: 'Cajas',
        path: '/admin/boxes',
        icon: 'fas fa-box',
    },
    {
        id: 17,
        title: 'Dispositivos',
        path: '/admin/devices',
        icon: 'fas fa-microchip',
    },
    {
        id: 15,
        title: 'Residentes',
        path: '/admin/customers',
        icon: 'fas fa-user',
    },
    {
        id: 5,
        title: 'Clientes',
        path: '/admin/clients',
        icon: 'fas fa-users',
    },
    {
        id: 3,
        title: 'Empresas',
        path: '/admin/dual-locker',
        icon: 'fas fa-check-double',
    },
    {
        id: 4,
        title: 'Empresas Locker',
        path: '/admin/courier-app',
        icon: 'fas fa-truck',
    },
    {
        id: 6,
        title: 'Paquetes',
        path: '/admin/packages',
        icon: 'fas fa-box-open',
    },
    {
        id: 7,
        title: 'Histórico',
        path: '/admin/historic',
        icon: 'fas fa-history',
    },

    {
        id: 9,
        title: 'Reservas',
        path: '/admin/reservations',
        icon: 'fas fa-calendar-alt',
    },
    {
        id: 10,
        title: 'Ecommerce',
        path: '/admin/ecommerce',
        icon: 'fas fa-store',

    },
    {
        id: 11,
        title: 'Ecommerce SandBox',
        path: '/admin/ecommerce-example',
        icon: 'fas fa-building',
    },
    {
        id: 12,
        title: 'Sameday',
        path: '/admin/sameday',
        icon: 'fas fa-user-clock',
    },
    {
        id: 13,
        title: 'Sameday SandBox',
        path: '/admin/sameday-example',
        icon: 'fas fa-building',
    },
    {
        id: 16,
        title: 'Template Correo',
        path: '/admin/template-mail',
        icon: 'fas fa-envelope',
    },
    {
        id: 14,
        title: 'Manuales',
        path: '/admin/manuals',
        icon: 'fas fa-file-pdf',
    },
];
