/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useEffect, useState } from 'react';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';
import { useHistory } from 'react-router';
import { StateContext } from '../../State';
import { get } from '../../Service/Api';
import { tableConfig } from '../../config';

const MultiBoxReservations = () => {
    const context = useContext(StateContext);
    const history = useHistory();
    const [state, setState] = useState({
        data: [],
        loading: false,
    });

    const _getReservations = async () => {
        setState({ ...state, loading: true });
        await get(`${process.env.REACT_APP_API_URL}/api/v2/multiboxes/reservation`)
            .then((response) => {
                const reservations = response.data.map((reservation) => {
                    const boxes = reservation.terminal.boxes;
                    const grouped = boxes.reduce((acc, current) => {
                        if (acc[current.DeskNo]) {
                            acc[current.DeskNo].push(current);
                            return acc;
                        }

                        acc[current.DeskNo] = [current];
                        return acc;
                    }, {});

                    const selectedIndex = JSON.parse(reservation.BoxNo);
                    if (Array.isArray(selectedIndex)) {
                        const boxesObjects = selectedIndex.map((index) => {
                            return boxes.find((box) => box.BoxNo === index);
                        });

                        reservation.box_number = boxesObjects.map((box) => {
                            let number = box.DeskBoxNo;
                            Object.keys(grouped).forEach((key) => {
                                if (box.DeskNo > key) {
                                    number += grouped[key].length;
                                }
                            });
                            return number;
                        });

                        reservation.box_number = reservation.box_number.sort((a, b) => {
                            if (a < b) return -1;
                            if (a > b) return 1;
                            return 0;
                        });
                    }
                    return reservation;
                });

                setState({ ...state, loading: false, data: reservations });
            })
            .catch((error) => {
                const { status } = error.response;
                if (status === 401 || status === 403) {
                    const [, dispatch] = context;
                    dispatch({
                        type: 'EXIT',
                    });
                }
            });
    };

    useEffect(() => {
        _getReservations();
    }, []);

    const columns = [
        {
            Header: 'ID',
            accessor: (row) => row.id,
            id: 'id',
            Cell: (row) => (
                <div style={{ width: '100%', textAlign: 'center' }}>{`# ${row.original.id}`}</div>
            ),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['id'] }),
            filterAll: true,
            filterable: true,
        },
        {
            Header: 'Nombre',
            accessor: (row) => row.customer.CustomerName,
            id: 'CustomerName',
            Cell: (row) => (
                <div style={{ width: '100%', textAlign: 'center' }}>{row.original.customer.CustomerName}</div>
            ),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['CustomerName'] }),
            filterAll: true,
            filterable: true,
        },
        {
            Header: 'Correo Electronico',
            accessor: (row) => row.customer.Email,
            id: 'Email',
            Cell: (row) => (
                <div style={{ width: '100%', textAlign: 'center' }}>{row.original.customer.Email}</div>
            ),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['Email'] }),
            filterAll: true,
            filterable: true,
        },
        {
            Header: 'Telefono',
            accessor: (row) => row.customer.CustomerMobile,
            id: 'CustomerMobile',
            Cell: (row) => (
                <div style={{ width: '100%', textAlign: 'center' }}>{row.original.customer.CustomerMobile}</div>
            ),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['CustomerMobile'] }),
            filterAll: true,
            filterable: true,
        },
        {
            Header: 'Codigo',
            accessor: (row) => row.code,
            id: 'code',
            Cell: (row) => (
                <div style={{ width: '100%', textAlign: 'center' }}>{row.original.code}</div>
            ),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['code'] }),
            filterAll: true,
            filterable: true,
        },
        {
            Header: 'Cajas',
            accessor: (row) => row.box_number,
            id: 'BoxNo',
            Cell: (row) => (
                <div style={{ width: '100%', textAlign: 'center' }}>{row.original.box_number.join(', ')}</div>
            ),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['BoxNo'] }),
            filterAll: true,
            filterable: true,
        },
    ];


    return (
        <div className="container-fluid rounded mt-4 px-5 py-4" style={{ background: '#fff' }}>
            <div className="row">
                <div className="col-12 text-right my-3">
                    <button className="modal-add-button" onClick={() => history.push('/admin/multi-box/client/add')} type="button">
                        <i className="fas fa-plus-circle" />
                    </button>
                </div>
            </div>
            <ReactTable
                data={state.data}
                columns={columns}
                {...tableConfig}
                loading={state.loading}
            />
        </div>
    );
};

export default MultiBoxReservations;
