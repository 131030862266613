const toolbar = [
    'heading',
    '|',
    'bold',
    'fontSize',
    'fontColor',
    'underline',
    'italic',
    'link',
    'bulletedList',
    'numberedList',
    '|',
    'indent',
    'outdent',
    '|',
    'undo',
    'redo',
    'alignment',
];

export default toolbar;
