/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';
import ReactSelect from 'react-select';
import DateTime from 'react-datetime';
import { tableConfig16 } from '../../config';

import { getEcommerceToken, getEcommerceBoxStatus, ecommerceReservation } from '../../Service/Api';

import './EcommerceSandbox.scss';
import 'react-datetime/css/react-datetime.css';
import 'moment/locale/es';

import Input from '../../Components/Input/Input';
import Button from '../../Components/Button/Button';

const EcommerceSandbox = () => {
    const [dateValue, setDateValue] = useState(moment.now());
    const [locations, setLocations] = useState([]);
    const [errors, setErrors] = useState({});
    const [terminal, setTerminal] = useState('-1');
    const [terminalData, setTerminalData] = useState([]);
    const [customer, setCustomer] = useState({});
    const [loading, setLoading] = useState(true);
    const [BoxNo, setBoxNo] = useState(null);
    const [result, setResult] = useState({});


    const columns = [
        {
            Header: 'Box No',
            accessor: (row) => row.DeskBoxNo,
            id: 'boxno',
            Cell: (row) => (row.original.DeskBoxNo),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['boxno'] }),
            filterAll: true,
            filterable: true,
        },
        {
            Header: 'Box Status',
            accessor: (row) => (row.available ? 'Disponible' : 'Indisponible'),
            id: 'status',
            Cell: (row) => (<div style={{ width: '100%', textAlign: 'center' }}>{row.original.available ? 'Disponible' : 'Indisponible'}</div>),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['status'] }),
            filterAll: true,
            filterable: true,
        },
        {
            Header: 'Tamaño',
            accessor: (row) => (row.type.BoxTypeName),
            id: 'size',
            Cell: (row) => (<div style={{ width: '100%', textAlign: 'center' }}>{row.original.type.BoxTypeName}</div>),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['size'] }),
            filterAll: true,
            filterable: true,
        },
        {
            Header: 'Ancho (cm)',
            accessor: (row) => (parseInt(row.type.BoxWidth) / 10),
            id: 'width',
            Cell: (row) => (<div style={{ textAlign: 'center', width: '100%' }}>{parseInt(row.original.type.BoxWidth) / 10}</div>),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['width'] }),
            filterAll: true,
            filterable: true,
        },
        {
            Header: 'Altura (cm)',
            accessor: (row) => (parseInt(row.type.BoxHeight) / 10),
            id: 'height',
            Cell: (row) => (<div style={{ textAlign: 'center', width: '100%' }}>{parseInt(row.original.type.BoxHeight) / 10}</div>),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['height'] }),
            filterAll: true,
            filterable: true,
        },
        {
            Header: 'Profundidad (cm)',
            accessor: (row) => (parseInt(row.type.BoxDepth) / 10),
            id: 'depth',
            Cell: (row) => (<div style={{ textAlign: 'center', width: '100%' }}>{parseInt(row.original.type.BoxDepth) / 10}</div>),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['depth'] }),
            filterAll: true,
            filterable: true,
        },
        {
            Header: 'Action',
            accessor: (row) => (parseInt(row.type.BoxDepth) / 10),
            id: 'action',
            Cell: (row) => (
                <div style={{ textAlign: 'center', width: '100%' }}>
                    <input type="radio" name="box" id={row.original.BoxNo} disabled={!row.original.available} value={row.original.BoxNo} onChange={onChangeRadio} checked={row.original.BoxNo === BoxNo} />
                </div>
            ),
            filterAll: false,
            filterable: false,
            sortable: false,
        },

    ];

    const loadData = (date = null) => {
        getEcommerceToken().then((response) => {
            getEcommerceBoxStatus(response.data.token, date === null ? moment().format('YYYY-MM-DD') : date.format('YYYY-MM-DD'))
                .then((r) => {
                    setLocations(formatTerminal(r.data));
                    setLoading(false);
                }).catch(() => {
                    setLocations([]);
                    setLoading(false);
                    alert('Fecha Invalida');
                });
        });
    };

    useEffect(() => {
        loadData();
    }, []);

    const formatTerminal = (data) => {
        data.forEach((item) => {
            item.name = `${item.TerminalName} - ${item.Location}`;
            item.id = item.TerminalNo;
            item.value = item.Location;
            item.label = `${item.TerminalName} - ${item.Location}`;
        });

        return data;
    };

    const onChange = (e) => {
        if (e !== null) {
            setTerminal(e);
            setErrors({ ...errors, TerminalNo: '' });
            setTerminalData(e.boxes);
        } else {
            setTerminalData([]);
            setTerminal(-1);
        }
    };

    const onChangeRadio = ({ target }) => {
        const { value } = target;
        setBoxNo(value);
        setErrors({ ...errors, BoxNo: '' });
    };

    const onChangeCustomer = ({ target }) => {
        const c = { ...customer };
        c[target.name] = target.value;
        setCustomer(c);
        const e = { ...errors };
        e[target.name] = '';
        setErrors(e);
    };

    const getErrors = (e) => {
        const errs = {};
        Object.keys(e).forEach((key) => {
            switch (key) {
                case 'TerminalNo':
                    errs.TerminalNo = e[key][0];
                    break;
                default:
                    errs[key] = e[key][0];
            }
        });

        return errs;
    };

    const submitReservation = () => {
        const params = {
            ...customer,
            BoxNo,
            TerminalNo: terminal.TerminalNo,
            day: moment(dateValue).format('YYYY-MM-DD'),
        };

        getEcommerceToken().then((r) => {
            ecommerceReservation(params, r.data.token)
                .then((response) => {
                    setResult({
                        status: r.status,
                        ...response.data,
                    });
                })
                .catch(({ response }) => {
                    const errs = { ...response.data };
                    setErrors(getErrors(errs));
                    setResult({
                        status: response.status,
                        ...errs,
                    });
                });
        });
    };

    const handleDateChange = (value) => {
        setDateValue(value);
        loadData(value);
        setTerminal(-1);
        setTerminalData([]);
        setBoxNo(null);
    };

    return (
        <div className="esandbox-container">
            <div className="esandbox-content">


                <div className="row mb-3">
                    <div className="col-6">
                        <label>Fecha Reserva *</label>
                        <form action="" autoComplete="off">
                            <DateTime
                                locale="es"
                                timeFormat={false}
                                closeOnSelect
                                autoComplete="off"
                                onChange={handleDateChange}
                                value={dateValue}
                                className="datepicker"
                            />
                        </form>
                    </div>

                    <div className="col-6">
                        <label> Direccion Locker * </label>
                        <ReactSelect
                            className="select-css"
                            options={locations}
                            onChange={onChange}
                            isLoading={loading}
                            value={terminal}
                            placeholder="Elige una opcion..."
                            styles={{
                                control: (provided) => ({ ...provided, ...{ border: '1px solid #868686' } }),
                            }}
                            isClearable
                            isSearchable
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <Input onChange={onChangeCustomer} value={customer.fullname} label="Nombre" name="fullname" errors={errors} required />
                    </div>
                    <div className="col-6">
                        <Input onChange={onChangeCustomer} mask="rut" value={customer.rut} label="Rut" name="rut" errors={errors} required />
                    </div>
                    <div className="col-6">
                        <Input onChange={onChangeCustomer} value={customer.email} label="Correo" name="email" errors={errors} required />
                    </div>
                    <div className="col-6">
                        <Input onChange={onChangeCustomer} mask="phone" value={customer.phone} label="Telefono" name="phone" errors={errors} required />
                    </div>
                    <div className="col-12">
                        <Input onChange={onChangeCustomer} value={customer.address} label="Direccion" name="address" errors={errors} required />
                    </div>
                </div>
                {errors.BoxNo !== '' && errors.BoxNo !== undefined && (
                    <div className="row">
                        <div className="col-12">
                            <div className="alert alert-danger w-100">Elige una box para continuar</div>
                        </div>
                    </div>
                )}

                {errors.TerminalNo !== '' && errors.TerminalNo !== undefined && (
                    <div className="row">
                        <div className="col-12">
                            <div className="alert alert-danger w-100">Elige un Locker para continuar</div>
                        </div>
                    </div>
                )}

                {terminalData.length > 0 && (
                    <div className="row">
                        <div className="col-12">
                            <form action="" onSubmit={(e) => e.preventDefault()}>
                                <ReactTable
                                    data={terminalData}
                                    columns={columns}
                                    {...tableConfig16}
                                />
                            </form>
                        </div>


                    </div>
                )}

                <div className="row justify-content-center">
                    <div className="col-4 mt-5">
                        <Button text="Reservar" onClick={submitReservation} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <pre>
                            {JSON.stringify(result, null, 2)}
                        </pre>
                    </div>
                </div>

            </div>
        </div>
    );
};


export default EcommerceSandbox;
