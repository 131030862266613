/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { post } from '../../Service/Api';
import { validateEmail } from '../../Service/Utils';

const SendTest = (props) => {
    const url = process.env.REACT_APP_API_URL;
    const [email, setEmail] = useState('');
    const [id, setId] = useState(-1);
    const [userId, setUserId] = useState(-1);

    useEffect(() => {
        const {id, user_id} = props.match.params;
        setId(id);
        setUserId(user_id);
    }, []);

    const handleSubmit = () => {
        if (!validateEmail(email)) {
            toast.error('Informe un correo valido');
            return false;
        }
        post(`${url}/mailer/send/test`, { email, id, user_id: userId })
            .then(() => {
                toast.success('Correo electrónico enviado con exito');
            })
            .catch(() => {
                toast.error('No fue posible enviar el correo electrónico');
            });
    };

    return (
        <div className="bg-white p-5">
            <div className="title-container">
                Plantilla de Prueba
            </div>

            <div className="row mt-3">
                <div className="col-12">
                    Ingrese un correo electrónico para recibir la prueba.
                    <div className="input-group">
                        <input
                            className="form-control mt-2"
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Correo Electrónico"
                        />
                    </div>
                </div>
            </div>
            <div className="row mt-5 justify-content-center">
                <div className="col-4 text-center">
                    <button type="button" className="button-main" onClick={handleSubmit}>Enviar</button>
                </div>
            </div>
        </div>
    );
};

export default SendTest;
