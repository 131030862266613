import React from 'react';
import Image from '../Components/Image/Image';

import image1 from '../assets/img/manuals/terminal01.png'
import image2 from '../assets/img/manuals/terminal02.png'
import image3 from '../assets/img/manuals/terminal03.png'
import image4 from '../assets/img/manuals/terminal04.png'
import image5 from '../assets/img/manuals/terminal05.png'

import './Manuals.scss';

const Terminals = (props) => {
    const { } = props;

    return (
        <div className="manuals">
            <h3>Terminales</h3>
            <p>
                Una terminal es un Locker inteligente que puede o no, contener hijos, que se conocen dentro del sistema como Side Lockers.
            </p>

            <Image file={image1} />

            <h3>Crear Terminal</h3>

            <p>
                1 - Para crear una terminal, primero haga clic en Terminales en el menú de la izquierda.
                Luego haga clic en el símbolo de agregar en la esquina superior derecha.
            </p>
            <Image file={image2} />

            <p>
                2 - Completos los datos.
            </p>

            <Image file={image3} />
            <p>
                3 - Haga click en Crear
            </p>

            <h3>Cajas y Side Lockers</h3>

            <p>
                Es posible agregar o quitar casillas disponibles en un Locker, en el caso de que una cerradura deje de funcionar, o si desea
                agregar un SideLocker por ejemplo. Para realizar estas acciones, haga clic en el icono de la caja del terminal que desea editar.
            </p>
            <Image file={image5} />
            <p />
            <h3>Configuraciones</h3>

            <p>
                <ul>
                    <li>1 - Muestra la configuración relacionada con el driveboard</li>
                    <li>2 - Agrega un Side Lockerl a un terminal</li>
                    <li>3 - Muestra las configuraciones del Locker o de un Sidelocker</li>
                </ul>
            </p>
            <Image file={image4} />
        </div>
    )
}

export default Terminals;
