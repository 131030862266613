import React from 'react';
import Image from '../Components/Image/Image';

// Images
import image1 from '../assets/img/manuals/users1.png'
import image2 from '../assets/img/manuals/users2.png'

import './Manuals.scss';

const Users = (props) => {
    const { } = props;

    return (
        <div className="manuals">
            <h3>Usuarios</h3>

            <p>Un usuario es una entidad que pertenece al sistema. Cada usuario debe estar asociado a un rol,
            que define qué es esta entidad dentro del sistema, ya sea un administrador, una empresa o un courier
            por ejemplo.</p>

            <div>
                Los roles pueden ser:
                <ul>
                    <li>Administrador - Responsable de la administración del sistema</li>
                    <li>Empresa - Relativo a una empresa que contrata un Locker. Cada empresa debe tener 1/n Service Stations asociados.</li>
                    <li>Courier - Empresa encargada de realizar entregas en un Locker</li>
                    <li>Ecommerce - Empresa de comercio electrónico que quiere entregar sus productos en un Locker. Un comercio electrónico
                    debe estar asociado a un Courier que será el responsable de realizar las entregas en una relación en que
                        el comercio electrónico sea un cliente del courier.</li>
                    <li>Sameday - Empresa donde una reserva tiene una duración máxima de 24 horas</li>
                </ul>
            </div>

            <h3>Crear Usuarios</h3>


            <p>1 - Haga clic en usuarios en el menú de la izquierda y luego en el símbolo de agregar a la derecha</p>

            <Image file={image1} />

            <p>2 - Completa los datos</p>

            <Image file={image2} />

            <p>3 - Haga clic en crear</p>
        </div>
    )
}

export default Users;