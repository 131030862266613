import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Col, Row, Spinner } from "reactstrap";
import Button from "../../Components/Button/Button";
import Input from "../../Components/Input/Input";
import { createDevice, updateDevice } from "../../Service/Api";

const INITIAL = {
    data: {
        AccessToken: '',
        DeviceToken: '',
        DeviceName: '',
        ID: ''
    },
    errors: {}
}

const DeviceForm = (props) => {
    const [createMode, setCreateMode] = useState(true);
    const [state, setState] = useState(INITIAL);
    const [loading, setLoading] = useState();

    const { readOnly } = props;
    const {
        AccessToken, DeviceToken, DeviceName, ID
    } = state.data;
    const { errors } = state;

    const handleData = () => {
        if (props.data) {
            setCreateMode(false);
            setState({
                data: props.data,
                errors: []
            });
        }
    }
    useEffect(handleData, []);

    const onChange = (event) => {
        const { name, value } = event.target;
        const { data, errors } = state;
        data[name] = value;
        errors[name] = '';
        setState({
            data,
            errors,
        });
    }

    const handleCreate = async () => {
        if (validForm()) {
            try {
                const response = await createDevice(state.data);
                if (response && response.data.errorInfo && response.data.errorInfo[0] === "23000") {
                    toast("El ID seleccionado ya existe", { type: "error" });
                } else {
                    toast("Dispositivo agregado correctamente", { type: "success" });
                    const { callback } = props;
                    callback();
                }
            } catch (error) {
                console.log(error);
                toast("Hubo un error inténtelo mas tarde", { type: "error" });
            }
        }
    }

    const validForm = () => {
        const errors = {};
        let formIsValid = true;

        if (!AccessToken || AccessToken.trim().length === 0) {
            formIsValid = false;
            errors.AccessToken = ['Requerido'];
        }

        if (!DeviceToken || DeviceToken.trim().length === 0) {
            formIsValid = false;
            errors.DeviceToken = ['Requerido'];
        }

        if (!DeviceName || DeviceName.trim().length === 0) {
            formIsValid = false;
            errors.DeviceName = ['Requerido'];
        }

        if (!ID || ID.trim().length === 0) {
            formIsValid = false;
            errors.ID = ['Requerido'];
        }

        setState({
            ...state,
            errors,
        });

        return formIsValid;
    }

    const handleUpdate = async () => {
        if (validForm()) {
            try {
                const response = await updateDevice(state.data);
                if (response && response.data.errorInfo && response.data.errorInfo[0] === "23000") {
                    toast("El ID seleccionado ya existe", { type: "error" });
                } else {
                    toast("Dispositivo actualizado correctamente", { type: "success" });
                    const { callback } = props;
                    callback();
                }
            } catch (error) {
                console.log(error);
                toast("Hubo un error inténtelo mas tarde", { type: "error" });
            }
        }
    }

    return (
        <>
            <Row>
                <Col md={12}><Input disabled={!createMode} name="ID" value={ID} onChange={onChange} readOnly={readOnly} label="ID" placeholder="ID" icon="fas fa-microchip" errors={errors} required /></Col>
                <Col md={12}><Input name="AccessToken" value={AccessToken} onChange={onChange} readOnly={readOnly} label="Access Token" placeholder="Access Token" icon="fas fa-microchip" errors={errors} required /></Col>
                <Col md={12}><Input name="DeviceToken" value={DeviceToken} onChange={onChange} readOnly={readOnly} label="Device Token" placeholder="Device Token" icon="fas fa-microchip" errors={errors} required /></Col>
                <Col md={12}><Input name="DeviceName" value={DeviceName} onChange={onChange} readOnly={readOnly} label="Nombre" placeholder="Nombre" icon="fas fa-microchip" errors={errors} required /></Col>
            </Row>
            {!readOnly && (
                <div className="form-footer">
                    {loading && <div className="spinner"><Spinner /></div>}
                    <Button text={createMode ? 'Crear' : 'Actualizar'} onClick={createMode ? handleCreate : handleUpdate} />
                </div>
            )}
        </>
    );
}

export default DeviceForm;