/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import TopBar from '../../Components/TopBar/TopBar';
import { StateContext } from '../../State';
import { tableConfig } from '../../config';
import { ConfirmDialog } from '../../Components/Dialog/Dialog';
import {
    getAllTemplates,
} from '../../Service/Api';

class TemplateMail extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            showConfirm: false,
            data: [],
            loading: false,
            deleteId: null,
        };
        this.loadData = this.loadData.bind(this);
        this.findData = this.findData.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
        const { location } = this.props;
        const { search } = location;
        const params = new URLSearchParams(search);
        if (params.has('is_saved') && parseInt(params.get('is_saved')) === 1) {
            toast.success('Template añadido con exito');
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    findData = (id) => {
        const { data } = this.state;
        return data.find((item) => item.CustomerID.toString() === id.toString());
    }

    handleError = (error) => {
        const { status } = error.response;
        if (status === 401 || status === 403) {
            const [, dispatch] = this.context;
            dispatch({
                type: 'EXIT',
            });
        }
    }

    async loadData() {
        this.setState({
            loading: true,
        });

        await getAllTemplates()
            .then((response) => {
                this.setState({
                    data: response.data,
                    loading: false,
                    deleteId: null,
                });
            }).catch((error) => {
                this.handleError(error);
            });
    }

    handleClick = () => {
        const { history } = this.props;
        history.push('/admin/template-mail/create');
    }

    render() {
        const {
            showConfirm, data, loading, deleteId,
        } = this.state;
        const columns = [
            {
                Header: 'ID',
                accessor: (row) => row.id,
                id: 'id',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        {row.original.id}
                    </div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['id'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Nombre',
                accessor: (row) => row.user.name,
                id: 'name',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'left', paddingLeft: '25px' }}>
                        {row.original.user.name}
                    </div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['name'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Usuario',
                accessor: (row) => row.user.username,
                id: 'username',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'left', paddingLeft: '25px' }}>
                        {row.original.user.username}
                    </div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['username'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Status',
                accessor: (row) => (row.is_active ? 'Activo' : 'Deshabilitado'),
                id: 'status',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'left', paddingLeft: '25px' }}>
                        {row.original.is_active ? 'Activo' : 'Deshabilitado'}
                    </div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['status'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Acciones',
                id: 'actions',
                filterable: false,
                sortable: false,
                maxWidth: 100,
                Cell: (row) => (
                    <div className="form-actions">
                        <Link to={`/admin/template-mail/view/${row.original.id}/${row.original.user_id}`}>
                            <i className="far fa-paper-plane" />
                        </Link>
                        <Link to={`/admin/template-mail/edit/${row.original.id}`}>
                            <i className="fas fa-pencil-alt" />
                        </Link>
                    </div>
                ),
            },
        ];

        return (
            <>
                <TopBar>
                    <button type="button" className="modal-add-button" onClick={this.handleClick}>
                        <i className="fas fa-plus-circle" />
                    </button>
                </TopBar>

                <ReactTable
                    data={data}
                    columns={columns}
                    {...tableConfig}
                    loading={loading}
                />

                <ConfirmDialog
                    show={showConfirm}
                    title={deleteId ? 'Eliminar Residente' : 'Restablecer usuario'}
                    onConfirm={() => {
                        if (deleteId) {
                            this.removeUser();
                        }
                        this.setState({ showConfirm: false });
                    }}
                    onCancel={() => {
                        this.setState({ showConfirm: false });
                    }}
                />
            </>
        );
    }
}

TemplateMail.contextType = StateContext;

export default TemplateMail;
