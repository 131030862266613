/* eslint-disable react/prop-types */
/* eslint-disable no-case-declarations */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import './Tabs.scss';

const DuaLocker = (props) => {
    const { data, selectTab, children } = props;
    return (
        <div className="section">
            <div className="tabs">
                {data.map((item, index) => (
                    <div className={index === 0 ? 'tabItem active' : 'tabItem'} key={item.id} onClick={(e) => selectTab(e, item.id)}>{item.title}</div>
                ))}
            </div>
            <div className="content">
                {children}
            </div>
        </div>
    );
};

export default DuaLocker;
