import React from 'react';
import Image from '../Components/Image/Image';

import image1 from '../assets/img/manuals/company_locker01.png'
import image2 from '../assets/img/manuals/company_locker02.png'
import image3 from '../assets/img/manuals/company_locker03.png'

import './Manuals.scss';

const CompanyLocker = (props) => {
    const { } = props;

    return (
        <div className="manuals">
            <h3>Empresas Locker</h3>

            <p>
                Esta opción hace referencia a las empresas que están disponibles en los Lockers como Couriers
            </p>

            <Image file={image1} />

            <h3>Agregar empresas en los Lockers</h3>

            <p>
                1 - Para crear una empresa en un locker, primero haga clic en Empresas Locker en el menú de la izquierda.
                Luego haga clic en el símbolo de agregar en la esquina superior derecha.
            </p>

            <Image file={image2} />

            <p>
                2 - Completa los datos
            </p>

            <Image file={image3} />

            <p>
                3 - Crear
            </p>

        </div>
    )
}

export default CompanyLocker;