import React from 'react';
import Folder from '../../Components/Folder/Folder';

import './Home.scss'
const Home = (props) => {
    const { history } = props;
    return (
        <div className="manual-content">
            <Folder history={history} url="/admin/manuals/users" label="Usuarios" icon="fas fa-user-friends" />
            <Folder history={history} url="/admin/manuals/service-station" label="Service Station" icon="fas fa-archive" />
            <Folder history={history} url="/admin/manuals/terminals" label="Terminales" icon="fas fa-desktop" />
            <Folder history={history} url="/admin/manuals/residents" label="Residentes" icon="fas fa-user" />
            <Folder history={history} url="/admin/manuals/clients" label="Clientes" icon="fas fa-users" />
            <Folder history={history} url="/admin/manuals/companies" label="Empresas" icon="fas fa-check-double" />
            <Folder history={history} url="/admin/manuals/companies-locker" label="Empresas Locker" icon="fas fa-box-open" />
            <Folder history={history} url="/admin/manuals/ecommerce" label="Ecommerce" icon="fas fa-store" />
            <Folder history={history} url="/admin/manuals/sameday" label="Sameday" icon="fas fa-user-clock" />
            <Folder history={history} url="/admin/manuals/api" label="Api's" icon="fas fa-cog" />
            <Folder history={history} url="/admin/manuals/installation" label="Manual Instalacion" icon="fas fa-cog" />
        </div>
    )
}

export default Home;