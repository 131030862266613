/* eslint-disable no-param-reassign */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';
import TopBar from '../../Components/TopBar/TopBar';
import UserForm from './ServiceStationForm';
import { StateContext } from '../../State';
import ModalView from '../../Layout/ModalView/ModalView';
import { tableConfig } from '../../config';
import { ConfirmDialog } from '../../Components/Dialog/Dialog';
import {
    deleteUser, restoreUser, getDepartments,
} from '../../Service/Api';

class ServiceStation extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            showConfirm: false,
            data: [],
            loading: false,
            deleteId: null,
            restoreId: null,
        };
        this.handleRemove = this.handleRemove.bind(this);
        this.handleRestore = this.handleRestore.bind(this);
        this.loadData = this.loadData.bind(this);
        this.findData = this.findData.bind(this);
        this.removeUser = this.removeUser.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    findData = (id) => {
        const { data } = this.state;
        return data.find((item) => item.DepartmentID.toString() === id.toString());
    }

    handleError = (error) => {
        const { status } = error.response;
        if (status === 401 || status === 403) {
            const [, dispatch] = this.context;
            dispatch({
                type: 'EXIT',
            });
        }
    }

    async loadData() {
        this.setState({
            loading: true,
        });

        await getDepartments()
            .then((response) => {
                if (this._isMounted) {
                    this.setState({
                        data: response.data,
                        loading: false,
                        deleteId: null,
                        restoreId: null,
                    });
                }
            }).catch((error) => {
                this.handleError(error);
            });
    }

    async removeUser() {
        const { deleteId } = this.state;
        await deleteUser(deleteId).then((response) => {
            if (response && response.status === 200) {
                this.loadData();
            }
        }).catch((error) => {
            this.handleError(error);
        });
    }

    async restoreUser() {
        const { restoreId } = this.state;
        await restoreUser(restoreId).then((response) => {
            if (response && response.status === 200) {
                this.loadData();
            }
        }).catch((error) => {
            this.handleError(error);
        });
    }

    handleNewFields = (terminals) => {
        terminals.forEach((item) => {
            item.box_length = item.boxes.length;
            item.DepartmentName = item.department[0].DepartmentName;
        });
        return terminals;
    }

    handleRemove(id) {
        this.setState({
            showConfirm: true,
            deleteId: id,
        });
    }

    handleRestore(id) {
        this.setState({
            showConfirm: true,
            restoreId: id,
        });
    }

    handleStatus = (status) => {
        switch (parseInt(status)) {
            case 1:
                return 'Retirado';
            case 4:
                return 'Aguardando Courier';
            default:
                return 'En el Locker';
        }
    }

    render() {
        const {
            showConfirm, data, loading, deleteId, restoreId,
        } = this.state;
        const columns = [
            {
                Header: 'Service Station ID',
                accessor: (row) => row.DepartmentID,
                id: 'departmentid',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        {row.original.DepartmentID}
                    </div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['departmentid'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Nombre',
                accessor: (row) => row.DepartmentName,
                id: 'name',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'left', paddingLeft: '25px' }}>
                        {row.original.DepartmentName}
                    </div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['name'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Dirección',
                accessor: (row) => row.Address,
                id: 'address',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'left', paddingLeft: '25px' }}>
                        {row.original.Address}
                    </div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['address'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Contato',
                accessor: (row) => row.ContactPerson,
                id: 'contact',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'left', paddingLeft: '25px' }}>
                        {row.original.ContactPerson}
                    </div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['contact'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Telefono',
                accessor: (row) => row.ContactTel,
                id: 'phone',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'left', paddingLeft: '25px' }}>
                        {row.original.ContactTel}
                    </div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['phone'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Terminal',
                accessor: (row) => (row.terminal ? row.terminal.TerminalName : row.terminal),
                id: 'terminal',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'left', paddingLeft: '25px' }}>
                        {row.original.terminal ? row.original.terminal.TerminalName : ''}
                    </div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['terminal'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Empresa',
                accessor: (row) => (row.company && row.company.user ? `${row.company.user.name} ${row.company.user.last_name}` : row.company),
                id: 'company',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'left', paddingLeft: '25px' }}>
                        <span style={{ textTransform: 'capitalize' }}>
                            {row.original.company && row.original.company.user ? `${row.original.company.user.name} ${row.original.company.user.last_name}` : ''}
                        </span>
                    </div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['company'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Acciones',
                id: 'actions',
                filterable: false,
                sortable: false,
                maxWidth: 100,
                Cell: (row) => (
                    <div className="form-actions">
                        <ModalView title="Editar Service Station" type="edit" callback={this.loadData}>
                            <UserForm data={this.findData(row.original.DepartmentID)} />
                        </ModalView>
                    </div>
                ),
            },
        ];

        return (
            <>
                <TopBar>
                    <ModalView title="Crear Service Station" type="add" callback={this.loadData}>
                        <UserForm />
                    </ModalView>
                </TopBar>

                <ReactTable
                    data={data}
                    columns={columns}
                    {...tableConfig}
                    loading={loading}
                />

                <ConfirmDialog
                    show={showConfirm}
                    title={deleteId ? 'Eliminar usuario' : 'Restablecer usuario'}
                    onConfirm={() => {
                        if (deleteId) {
                            this.removeUser();
                        }
                        if (restoreId) {
                            this.restoreUser();
                        }
                        this.setState({ showConfirm: false });
                    }}
                    onCancel={() => {
                        this.setState({ showConfirm: false });
                    }}
                />
            </>
        );
    }
}

ServiceStation.contextType = StateContext;

export default ServiceStation;
