/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-param-reassign */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';
import TopBar from '../../Components/TopBar/TopBar';
import UserForm from './DLClientForm';
import { StateContext } from '../../State';
import ModalView from '../../Layout/ModalView/ModalView';
import { tableConfig } from '../../config';
import { ConfirmDialog } from '../../Components/Dialog/Dialog';
import {
    deleteCustomer, restoreUser, getAllClients,
} from '../../Service/Api';
import DownloadCSVButton from '../../Components/DownloadCSVButton/DownloadCSVButton';
import UploadForm from './UploadForm';
import csvExample from './model_retiro_en_tienda';

class Customer extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            showConfirm: false,
            data: [],
            loading: false,
            deleteId: null,
            restoreId: null,
        };
        this.handleRemove = this.handleRemove.bind(this);
        this.handleRestore = this.handleRestore.bind(this);
        this.loadData = this.loadData.bind(this);
        this.findData = this.findData.bind(this);
        this.removeUser = this.removeUser.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    findData = (id) => {
        const { data } = this.state;
        return data.find((item) => item.CustomerID.toString() === id.toString());
    }

    handleError = (error) => {
        const { status } = error.response;
        if (status === 401 || status === 403) {
            const [, dispatch] = this.context;
            dispatch({
                type: 'EXIT',
            });
        }
    }

    async loadData() {
        this.setState({
            loading: true,
        });

        await getAllClients()
            .then((response) => {
                this.setState({
                    data: response.data,
                    loading: false,
                    deleteId: null,
                    restoreId: null,
                });
            }).catch((error) => {
                this.handleError(error);
            });
    }

    async removeUser() {
        const { deleteId } = this.state;
        await deleteCustomer(deleteId).then((response) => {
            if (response && response.status === 200) {
                this.loadData();
            }
        }).catch((error) => {
            this.handleError(error);
        });
    }

    async restoreUser() {
        const { restoreId } = this.state;
        await restoreUser(restoreId).then((response) => {
            if (response && response.status === 200) {
                this.loadData();
            }
        }).catch((error) => {
            this.handleError(error);
        });
    }

    handleNewFields = (terminals) => {
        terminals.forEach((item) => {
            item.box_length = item.boxes.length;
            item.DepartmentName = item.department[0].DepartmentName;
        });
        return terminals;
    }

    handleRemove(id) {
        this.setState({
            showConfirm: true,
            deleteId: id,
        });
    }

    handleRestore(id) {
        this.setState({
            showConfirm: true,
            restoreId: id,
        });
    }

    handleStatus = (status) => {
        switch (parseInt(status)) {
            case 1:
                return 'Retirado';
            case 4:
                return 'Aguardando Courier';
            default:
                return 'En el Locker';
        }
    }

    render() {
        const {
            showConfirm, data, loading, deleteId,
        } = this.state;
        const columns = [
            {
                Header: 'ID',
                accessor: (row) => row.CustomerID,
                id: 'id',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        {row.original.CustomerID}
                    </div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['id'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Nombre',
                accessor: (row) => row.CustomerName,
                id: 'name',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'left', paddingLeft: '25px' }}>
                        {row.original.CustomerName}
                    </div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['name'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Producto',
                accessor: (row) => row.Address,
                id: 'address',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'left', paddingLeft: '25px' }}>
                        {row.original.Address}
                    </div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['address'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Numero Pedido',
                accessor: (row) => row.dsu.order_number,
                id: 'order_number',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'left', paddingLeft: '25px' }}>
                        {row.original.dsu.order_number}
                    </div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['order_number'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Email',
                accessor: (row) => row.Email,
                id: 'contact',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'left', paddingLeft: '25px' }}>
                        {row.original.Email}
                    </div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['contact'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Telefono',
                accessor: (row) => row.CustomerMobile,
                id: 'phone',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'left', paddingLeft: '25px' }}>
                        {row.original.CustomerMobile}
                    </div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['phone'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Acciones',
                id: 'actions',
                filterable: false,
                sortable: false,
                maxWidth: 100,
                Cell: (row) => (
                    <div className="form-actions">
                        <ModalView title="Editar Residente" type="edit" callback={this.loadData}>
                            <UserForm data={this.findData(row.original.CustomerID)} />
                        </ModalView>
                    </div>
                ),
            },
        ];

        return (
            <>
                <TopBar>
                    <div className="d-flex justify-content-between py-3" style={{ color: '#666 !important' }}>
                        <div className="d-flex">
                            <div className="d-flex mr-5" style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <DownloadCSVButton data={csvExample} filename="modelo_retiro_en_tienda.csv" />
                                Descargar Archivo Base
                            </div>
                            <div
                                className="d-flex mr-5"
                                style={{
                                    flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '30px', color: '#666 !important',
                                }}
                            >
                                <ModalView size="lg" title="Carga masiva" type="upload" callback={this.loadData}>
                                    <UploadForm />
                                </ModalView>
                                <span style={{ fontSize: '14px' }}>Carga Masiva</span>
                            </div>
                        </div>

                        <ModalView title="Crear Residente" type="add" callback={this.loadData}>
                            <UserForm />
                        </ModalView>
                    </div>

                </TopBar>

                <ReactTable
                    data={data}
                    columns={columns}
                    {...tableConfig}
                    loading={loading}
                />

                <ConfirmDialog
                    show={showConfirm}
                    title={deleteId ? 'Eliminar Residente' : 'Restablecer usuario'}
                    onConfirm={() => {
                        if (deleteId) {
                            this.removeUser();
                        }
                        this.setState({ showConfirm: false });
                    }}
                    onCancel={() => {
                        this.setState({ showConfirm: false });
                    }}
                />
            </>
        );
    }
}

Customer.contextType = StateContext;

export default Customer;
