export default [
    {
        'Numero Pedido': '',
        'Nombre Cliente': '',
        Rut: '',
        Producto: '',
        Telefono: '',
        Email: '',
    },
];
