/* eslint-disable no-case-declarations */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import Sidebar from 'react-sidebar';
import { StateContext } from '../../State';
import Menu from '../../Components/Menu/Menu';
import MenuButton from '../../Components/MenuButton/MenuButton';
import Title from '../../Components/Title/Title';
import Users from '../../Pages/Users/Users';
import Terminal from '../../Pages/Terminal/Terminal';
import Packages from '../../Pages/Packages/Packages';
import History from '../../Pages/History/History';
import Reservation from '../../Pages/Reservation/Reservation';
import EcommerceSandbox from '../../Pages/EcommerceSandbox/EcommerceSandbox';
import SamedaySandbox from '../../Pages/SamedaySandbox/SamedaySandbox';
import Token from '../../Pages/Token/Token';
import ServiceStation from '../../Pages/ServiceStation/ServiceStation';
import Customer from '../../Pages/Customer/Customer';
import Ecommerce from '../../Pages/Ecommerce/Ecommerce';
import Sameday from '../../Pages/Sameday/Sameday';
import Courier from '../../Pages/Courier/Courier';
import Clients from '../../Pages/Clients/Clients';
import DualLocker from '../../Pages/Clients/DualLocker';
import Company from '../../Pages/Company/Company';

// Manuals Pages
import ManualsHome from '../../Pages/Manuals/Home';
import CompanyManualsHome from '../../Pages/Manuals/HomeCompany';
import EcommerceManualsHome from '../../Pages/Manuals/HomeEcommerce';
import SamedayManualsHome from '../../Pages/Manuals/HomeSameday';

// Manuals
import UserManual from '../../Manuals/Users';
import ServiceStationManual from '../../Manuals/ServiceStation';
import TerminalsManual from '../../Manuals/Terminals';
import ResidentsManual from '../../Manuals/Residents';
import ClientManual from '../../Manuals/Client';
import CompanyManual from '../../Manuals/Companies';
import CompanyLocker from '../../Manuals/CompanyLocker';
import EcommerceManual from '../../Manuals/Ecommerce';
import SamedayManual from '../../Manuals/Sameday';
import ApiManual from '../../Manuals/Api';

// Manuals Company
import CompanyManualsApi from '../../Manuals/Company/Api';

// Manuals Ecommerce
import EcommerceManualsApi from '../../Manuals/Ecommerce/Api';

// Manuals Sameday
import SamedayManualsApi from '../../Manuals/Sameday/Api';


// Manuals Sameday
import ManualInstalacion from '../../Manuals/ManualInstallation';

// Menus
import menuAdmin from './data/dataMenuAdmin';
import menuEcommerce from './data/dataMenuEcommerce';
import menuCourier from './data/dataMenuCourier';
import menuSameDay from './data/dataMenuSameDay';
import menuCompany from './data/dataMenuCompany';
import menuDefault from './data/dataDefaultMenu';


import avatar from './avatar.png';

import './Main.scss';
import TemplateMail from '../../Pages/TemplateMail';
import AddTemplate from '../../Pages/TemplateMail/AddTemplate';
import EditTemplate from '../../Pages/TemplateMail/EditTemplate';
import SendTest from '../../Pages/TemplateMail/SendTest';
import Boxes from '../../Pages/Boxes/Boxes';
import MultiBox from '../../Pages/Multibox';
import MultiBoxReservations from '../../Pages/MultiBoxReservations/MultiBoxReservations';
import Devices from '../../Pages/Devices/Devices';

const mql = window.matchMedia('(min-width: 800px)');

class Main extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            docked: mql.matches,
            transitions: false,
            dataMenu: [],
            username: '',
        };

        this.menuButtonClick = this.menuButtonClick.bind(this);
        this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
        this.checkLoggedIn = this.checkLoggedIn.bind(this);
        this.findTitle = this.findTitle.bind(this);
    }

    componentDidMount() {
        mql.addListener(this.mediaQueryChanged);
        this.findTitle();
        this.checkLoggedIn();
    }

    componentWillUnmount() {
        mql.removeListener(this.mediaQueryChanged);
    }

    mediaQueryChanged() {
        this.setState({
            docked: mql.matches,
        });
    }

    checkLoggedIn() {
        const [{ role, user }, dispatch] = this.context;
        const { history, location } = this.props;
        if (role.name === undefined) {
            dispatch({
                type: 'LOAD_SESSION',
            });
        } else {
            switch (role.name) {
                case 'administrator':
                    this.setState({
                        dataMenu: menuAdmin,
                        username: `${user.name} ${user.last_name}`,
                        role: `${role.name}`,
                    });
                    if (location.pathname === '/') {
                        history.push('/admin/terminals');
                    }
                    break;
                case 'courier':
                    this.setState({
                        dataMenu: menuCourier,
                        username: `${user.name} ${user.last_name}`,
                        role: `${role.name}`,
                    });
                    if (location.pathname === '/') {
                        history.push('/admin/packages');
                    }
                    break;
                case 'ecommerce':
                    this.setState({
                        dataMenu: menuEcommerce,
                        username: `${user.name} ${user.last_name}`,
                        role: `${role.name}`,
                    });
                    if (location.pathname === '/') {
                        history.push('/admin/packages');
                    }
                    break;
                case 'sameday':
                    this.setState({
                        dataMenu: menuSameDay,
                        username: `${user.name} ${user.last_name}`,
                        role: `${role.name}`,
                    });
                    if (location.pathname === '/') {
                        history.push('/admin/packages');
                    }
                    break;
                case 'company':
                    let t = [];
                    if (user.features.length > 0) {
                        user.features[0].id = parseInt(`7${user.features[0].id}`);
                        t = [...menuCompany, ...user.features];
                    } else {
                        t = [...menuCompany, ...[
                            {
                                id: 3,
                                title: 'Residentes',
                                path: '/admin/customers',
                                icon: 'fas fa-user',
                            },
                        ]];
                    }
                    this.setState({
                        dataMenu: t,
                        username: `${user.name} ${user.last_name}`,
                        role: `${role.name}`,
                    });
                    if (location.pathname === '/') {
                        history.push('/admin/packages');
                    }
                    break;
                default:
                    this.setState({
                        dataMenu: menuDefault,
                        username: `${user.name} ${user.last_name}`,
                        role: `${role.name}`,
                    });
                    if (location.pathname === '/') {
                        history.push('/admin/packages');
                    }
            }
        }
    }

    findTitle() {
        const { location } = this.props;
        const { pathname } = location;
        const { dataMenu } = this.state;

        const item = dataMenu.find((v) => v.path === pathname);
        document.title = item ? `Elock | ${item.title}` : 'Elock';
        return item ? item.title : '';
    }

    menuButtonClick(event) {
        event.preventDefault();
        const { docked } = this.state;
        this.setState({
            transitions: true,
            docked: !docked,
        });
    }

    render() {
        const {
            docked, transitions, dataMenu, username, role,
        } = this.state;

        const sidebarProps = {
            sidebar: <Menu items={dataMenu} username={username} avatar={avatar} role={role} />,
            docked,
            shadow: false,
            transitions,
        };

        return (
            <Sidebar {...sidebarProps} styles={{ sidebar: { width: '300px' } }}>
                <div className="top-area">
                    <MenuButton onClick={this.menuButtonClick} />
                    <Title text={this.findTitle()} />
                </div>
                <div className="content-area">
                    <Switch>
                        <Route exact path="/admin/users" component={Users} />
                        <Route exact path="/admin/terminals" component={Terminal} />
                        <Route exact path="/admin/boxes" component={Boxes} />
                        <Route exact path="/admin/devices" component={Devices} />
                        <Route exact path="/admin/packages" component={Packages} />
                        <Route exact path="/admin/historic" component={History} />
                        <Route exact path="/admin/courier-app" component={Courier} />
                        <Route exact path="/admin/clients" component={Clients} />
                        <Route exact path="/admin/reservations" component={Reservation} />
                        <Route exact path="/admin/ecommerce-example" component={EcommerceSandbox} />
                        <Route exact path="/admin/sameday-example" component={SamedaySandbox} />
                        <Route exact path="/admin/tokens" component={Token} />
                        <Route exact path="/admin/service-station" component={ServiceStation} />
                        <Route exact path="/admin/customers" component={Customer} />
                        <Route exact path="/admin/ecommerce" component={Ecommerce} />
                        <Route exact path="/admin/sameday" component={Sameday} />
                        <Route exact path="/admin/dual-locker" component={Company} />
                        <Route exact path="/admin/dual-locker/client" component={DualLocker} />

                        {/* MultiBox Component */}
                        <Route exact path="/admin/multi-box/client" component={MultiBoxReservations} />
                        <Route exact path="/admin/multi-box/client/add" component={MultiBox} />

                        {/* Template Mail */}
                        <Route exact path="/admin/template-mail" component={TemplateMail} />
                        <Route exact path="/admin/template-mail/create" component={AddTemplate} />
                        <Route exact path="/admin/template-mail/edit/:id" component={EditTemplate} />
                        <Route exact path="/admin/template-mail/view/:id/:user_id" component={SendTest} />

                        {/* Manuals Pages */}
                        <Route exact path="/admin/manuals" component={ManualsHome} />
                        <Route exact path="/admin/company/manuals" component={CompanyManualsHome} />
                        <Route exact path="/admin/ecommerce/manuals" component={EcommerceManualsHome} />
                        <Route exact path="/admin/sameday/manuals" component={SamedayManualsHome} />

                        {/* Manual Admin */}
                        <Route exact path="/admin/manuals/users" component={UserManual} />
                        <Route exact path="/admin/manuals/service-station" component={ServiceStationManual} />
                        <Route exact path="/admin/manuals/terminals" component={TerminalsManual} />
                        <Route exact path="/admin/manuals/residents" component={ResidentsManual} />
                        <Route exact path="/admin/manuals/clients" component={ClientManual} />
                        <Route exact path="/admin/manuals/companies" component={CompanyManual} />
                        <Route exact path="/admin/manuals/companies-locker" component={CompanyLocker} />
                        <Route exact path="/admin/manuals/ecommerce" component={EcommerceManual} />
                        <Route exact path="/admin/manuals/sameday" component={SamedayManual} />
                        <Route exact path="/admin/manuals/api" component={ApiManual} />

                        {/* Manuals Company */}
                        <Route exact path="/admin/company/manuals/api" component={CompanyManualsApi} />

                        {/* Manuals Ecommerce */}
                        <Route exact path="/admin/ecommerce/manuals/api" component={EcommerceManualsApi} />

                        {/* Manuals Sameday */}
                        <Route exact path="/admin/sameday/manuals/api" component={SamedayManualsApi} />

                        {/* Manuals Sameday */}
                        <Route exact path="/admin/manuals/installation" component={ManualInstalacion} />
                    </Switch>
                </div>
            </Sidebar>
        );
    }
}

Main.contextType = StateContext;

Main.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }),
};

Main.defaultProps = {
    location: {
        pathname: '',
    },
};

export default withRouter(Main);
