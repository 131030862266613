/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable no-case-declarations */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Tabs from '../../Components/Tabs/Tabs';
import { getTerminalCompany, post, toogleCompanyFeatureDualLocker } from '../../Service/Api';
import Terminals from './Terminals';
import DualLocker from './DualLocker';
import AddClient from './AddClient';
import MultiBox from './MultiBox';

const DuaLocker = () => {
    const [tab, setTab] = useState(1);
    const [terminals, setTerminals] = useState([]);
    const [serviceStation, setServiceStation] = useState(-1);
    const [serviceStations, setServiceStations] = useState([]);
    const [user, setUser] = useState(-1);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dualLocker, setDualLocker] = useState(false);
    const [multiBoxData, setMultiboxData] = useState({
        user: -1,
        switch: false,
        features: [],
        feature: -1,
        onChange: (data) => setMultiboxData({ ...data }),
    });

    const loadData = () => {
        setLoading(true);
        getTerminalCompany().then((response) => {
            setUsers(response.data);
            setLoading(false);
        });
    };

    useEffect(() => {
        loadData();
    }, []);

    const selectTab = ({ currentTarget }, item) => {
        const active = document.querySelector('.tabItem.active');
        active.classList.remove('active');
        currentTarget.classList.add('active');
        setTab(item);
        setUser(-1);
        setServiceStation(-1);
    };

    const convertDataServiceStation = (st) => {
        const data = [];
        st.forEach((element) => {
            data.push({ ...element, ...{ id: element.DepartmentID, name: element.DepartmentName } });
        });
        return data;
    };

    const onChange = ({ target }) => {
        const { name, value, checked } = target;

        switch (name) {
            case 'user':
                setUser(value);
                const u = users.find((item) => parseInt(item.id) === parseInt(value));
                setServiceStations(convertDataServiceStation(u.service_station));
                const feature = u.features.find((item) => item.title === 'Agregar Pedido');
                setDualLocker(feature !== undefined);
                setServiceStation(-1);
                break;
            case 'serviceStation':
                const st = serviceStations.find((item) => item.id === value);
                setTerminals(st.terminals);
                setServiceStation(value);
                break;
            case 'dual-loacker-switch':
                setDualLocker(checked);
                break;
            default:
                console.log('Default action selected');
        }
    };

    const handleCompanyfeature = () => {
        const params = {
            feature: dualLocker ? 'dual_locker' : 'remove',
            user_id: user,
        };
        toogleCompanyFeatureDualLocker(params)
            .then(() => {
                loadData();
                toast.success(`Dual Locker ${dualLocker ? 'habilitado' : 'deshabilitado'} exitosamente`, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    };

    const clearData = () => {
        setUser(-1);
        setServiceStation(-1);
        setTerminals([]);
    };

    const _handleFeatureMultibox = async () => {
        let action = false;
        if (multiBoxData.switch) {
            await post(`${process.env.REACT_APP_API_URL}/company/feature/attach`, { user_id: multiBoxData.user, feature_id: multiBoxData.feature })
                .then(() => {
                    loadData();
                    action = true;
                });
        } else {
            await post(`${process.env.REACT_APP_API_URL}/company/feature/detach`, { user_id: multiBoxData.user, feature_id: multiBoxData.feature })
                .then(() => {
                    loadData();
                });
        }

        toast.success(`Feature ${action ? 'habilitada' : 'deshabilitada'} exitosamente`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

        setMultiboxData({ ...multiBoxData, feature: -1, user: -1, switch: false });
    };


    const menuData = [{ id: 1, title: 'Terminales' }, { id: 4, title: 'Opciones' }, { id: 3, title: 'Añadir Cliente Retiro en tienda' }];

    return (
        <Tabs data={menuData} selectTab={selectTab}>
            {tab === 1 && (
                <Terminals
                    user={user}
                    users={users}
                    serviceStation={serviceStation}
                    serviceStations={serviceStations}
                    terminals={terminals}
                    loading={loading}
                    onChange={onChange}
                />
            )}

            {tab === 2 && (
                <DualLocker
                    onChange={onChange}
                    user={user}
                    users={users}
                    loading={loading}
                    dualLocker={dualLocker}
                    saveDualLocker={handleCompanyfeature}
                />
            )}

            {tab === 3 && (
                <AddClient
                    user={user}
                    users={users}
                    serviceStation={serviceStation}
                    serviceStations={serviceStations}
                    terminals={terminals}
                    loading={loading}
                    onChange={onChange}
                    callback={clearData}
                />
            )}

            {tab === 4 && (
                <MultiBox
                    onChange={onChange}
                    users={users}
                    loading={loading}
                    data={multiBoxData}
                    saveMultibox={_handleFeatureMultibox}
                />
            )}
        </Tabs>
    );
};

export default DuaLocker;
