/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable no-lonely-if */
/* eslint-disable react/no-array-index-key */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import ReactSelect from 'react-select';
import Button from '../../Components/Button/Button';
import Input from '../../Components/Input/Input';
import { orderBoxDoorNumber } from '../../helpers/utils';
import { get, post } from '../../Service/Api';
import Modal from '../../Components/Modal';
import { StateContext } from '../../State';

const MultiBox = () => {
    const [state, setState] = useState({
        inputs: {
            CustomerName: '',
            Email: '',
            CustomerMobile: '',
            Address: '',
            TerminalNo: undefined,
        },
        errors: {},
        terminals: [],
        boxes: [],
        selectedBoxes: [],
        reservation: {},
        modal: {
            open: false,
        },
        emptyMessage: 'No hay cajas disponibles en este Locker.',
        showEmptyMessage: false,
    });

    const context = useContext(StateContext);
    const history = useHistory();

    useEffect(() => {
        get(`${process.env.REACT_APP_API_URL}/terminals-company/user`)
            .then((response) => {
                const terminals = response.data.map((terminal, index) => ({
                    TerminalNo: terminal.TerminalNo,
                    Location: terminal.Location,
                    TerminalName: terminal.TerminalName,
                    label: `${terminal.TerminalName} - ${terminal.Location}`,
                    value: index,
                }));
                setState({ ...state, terminals });
            }).catch((error) => {
                const { status } = error.response;
                if (status === 401 || status === 403) {
                    const [, dispatch] = context;
                    dispatch({
                        type: 'EXIT',
                    });
                }
            });
    }, []);

    const _getAvailableBoxes = async (payload) => {
        setState({ ...state, showEmptyMessage: false });
        await get(`${process.env.REACT_APP_API_URL}/terminals-company/boxes/${payload.TerminalNo}`)
            .then((response) => {
                const ordered = response.data.sort(orderBoxDoorNumber);
                const empty = ordered.length === 0;
                setState({ ...state, inputs: { ...state.inputs, TerminalNo: payload, Address: payload.Location }, boxes: ordered, showEmptyMessage: empty });
            }).catch((error) => {
                const { status } = error.response;
                if (status === 401 || status === 403) {
                    const [, dispatch] = context;
                    dispatch({
                        type: 'EXIT',
                    });
                }
            });
    };

    const _handleOnChange = ({ target }) => {
        const { name, value } = target;
        setState({ ...state, inputs: { ...state.inputs, [name]: value } });
    };

    const _handleSelect = ({ value }) => {
        if (value) {
            _getAvailableBoxes(value);
        } else {
            setState({ ...state, boxes: [], inputs: { ...state.inputs, TerminalNo: undefined } });
        }
    };

    const _handleSelectBox = ({ target }, BoxNo) => {
        const { selectedBoxes } = state;
        if (target.checked) {
            if (!selectedBoxes.find((box) => box === BoxNo)) {
                selectedBoxes.push(BoxNo);
                setState({ ...state, selectedBoxes });
            }
        } else {
            const boxes = selectedBoxes.filter((box) => box !== BoxNo);
            setState({ ...state, selectedBoxes: boxes });
        }
    };

    const _validate = () => {
        const { inputs, selectedBoxes } = state;
        const errors = {};
        Object.keys(inputs).forEach((key) => {
            if (key !== 'TerminalNo') {
                if (inputs[key].trim() === '') {
                    errors[key] = ['Este campo es requerido'];
                }
            }
        });

        if (selectedBoxes.length === 0) {
            errors.boxes = ['Selecciona al menos una caja antes de continuar'];
        }

        setState({ ...state, errors });
        return !(Object.keys(errors).length > 0);
    };

    const _submitReservation = () => {
        if (_validate()) {
            setState({ ...state, loading: true });
            const request = {
                ...state.inputs,
                TerminalNo: state.inputs.TerminalNo.TerminalNo,
                boxes: state.selectedBoxes,
            };
            post(`${process.env.REACT_APP_API_URL}/api/v2/multiboxes/reservation/create`, request)
                .then((response) => {
                    setState({
                        terminals: state.terminals,
                        reservation: response.data.reservation,
                        modal: { open: true },
                        inputs: {
                            CustomerName: '',
                            Email: '',
                            CustomerMobile: '',
                            Address: '',
                            TerminalNo: undefined,
                        },
                        errors: {},
                        boxes: [],
                        selectedBoxes: [],
                        emptyMessage: 'No hay cajas disponibles en este Locker.',
                        showEmptyMessage: false,
                    });
                });
        }
    };

    return (
        <div className="container rounded mt-4 px-5 py-4" style={{ background: '#fff' }}>

            <Modal open={state.modal.open}>
                <div className="row align-items-center" style={{ background: '#fff', borderRadius: '20px', width: '350px', height: '350px' }}>
                    <div className="col-12 text-center">
                        <img src={state.reservation.qrcode_url} alt="qrcode" />
                    </div>
                    <div className="col-12 text-center" style={{ fontWeight: 'bold' }}>
                        Codigo para entrega:
                    </div>
                    <div className="col-12 text-center" style={{ fontSize: '1.5em', fontWeight: 'bold' }}>
                        {`${state.reservation.code}`}
                    </div>
                    <div className="col-12 ">
                        <Button text="Cerrar" onClick={() => setState({ ...state, modal: { open: false } })} />
                    </div>
                </div>
            </Modal>

            <div className="row mb-4">
                <div className="col-12 text-left">
                    <button className="btn pr-5" style={{ fontWeight: 'bold' }} type="button" onClick={() => history.push('/admin/multi-box/client')}>
                        <i className="fas fa-arrow-left mr-3" />
                        Volver
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <Input
                        name="CustomerName"
                        value={state.inputs.CustomerName}
                        onChange={_handleOnChange}
                        readOnly={false}
                        label="Nombre"
                        placeholder="Nombre"
                        icon="fas fa-user"
                        errors={state.errors}
                        required
                    />
                </div>
                <div className="col-6">
                    <Input
                        name="Email"
                        value={state.inputs.Email}
                        onChange={_handleOnChange}
                        readOnly={false}
                        label="Email"
                        placeholder="email@domain.cl"
                        icon="fas fa-envelope"
                        errors={state.errors}
                        required
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Input
                        name="CustomerMobile"
                        value={state.inputs.CustomerMobile}
                        onChange={_handleOnChange}
                        readOnly={false}
                        label="Telefono"
                        placeholder="569 9 4312 3212"
                        icon="fas fa-phone"
                        errors={state.errors}
                        mask="phone"
                        required
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <ReactSelect
                        className="select-css"
                        options={state.terminals}
                        onChange={(e) => _handleSelect({ name: 'TerminalNo', value: e })}
                        isLoading={false}
                        placeholder="Elige una opcion..."
                        value={state.inputs.TerminalNo}
                        styles={{
                            control: (provided) => ({ ...provided, ...{ border: '1px solid #868686' } }),
                        }}
                        isClearable
                        isSearchable
                    />

                </div>
            </div>
            <div className="input-container">
                {state.errors.boxes && <div className="warning">{state.errors.boxes}</div>}
            </div>
            {state.boxes.length > 0 && (
                <div className="row mt-3 py-2 px-5" style={{ borderBottom: '1px solid #e3e3e3' }}>
                    <div className="col-1" />
                    <div className="col-5">
                        Tamaño
                    </div>
                    <div className="col-6">
                        Numero
                    </div>
                </div>
            )}

            {state.boxes && state.boxes.map((box) => (
                <div className="row py-2 px-5" style={{ borderBottom: '1px solid #e3e3e3' }} key={`${box.BoxNo}-box`}>
                    <div className="col-1">
                        <input type="checkbox" style={{ width: '20px', height: '20px' }} onChange={(e) => _handleSelectBox(e, box.BoxNo)} />
                    </div>
                    <div className="col-5">
                        {box.type.BoxTypeName}
                    </div>
                    <div className="col-6">
                        {box.door_number}
                    </div>
                </div>
            ))}

            {state.showEmptyMessage === true && (
                <div className="row">
                    <div className="col-12 text-center mt-3" style={{ fontSize: '1.3em' }}>
                        {state.emptyMessage}
                    </div>
                </div>
            )}

            <div className="row mt-4">
                <div className="col-12">
                    <Button text="Reservar" onClick={_submitReservation} disabled={state.loading} />
                </div>
            </div>
        </div>
    );
};

export default MultiBox;
