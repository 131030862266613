/* eslint-disable react/prop-types */
import moment from 'moment';
import React, { useState } from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import Button from '../../Components/Button/Button';
import Input from '../../Components/Input/Input';
import Select from '../../Components/Select/Select';

const FilterForm = (props) => {
    const { handleFilter, filters } = props;
    const [state, setState] = useState({
        data: {
            StoredDateStart: filters.find((filter) => filter.id === 'StoredDateStart') ? moment(filters.find((filter) => filter.id === 'StoredDateStart').value).format('YYYY-MM-DD') : moment().subtract(30, 'days').format('YYYY-MM-DD'),
            StoredDateEnd: filters.find((filter) => filter.id === 'StoredDateEnd') ? moment(filters.find((filter) => filter.id === 'StoredDateEnd').value).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
            PackageId: filters.find((filter) => filter.id === 'PackageId') ? filters.find((filter) => filter.id === 'PackageId').value : '',
            CustomerName: filters.find((filter) => filter.id === 'CustomerName') ? filters.find((filter) => filter.id === 'CustomerName').value : '',
            TerminalName: filters.find((filter) => filter.id === 'TerminalName') ? filters.find((filter) => filter.id === 'TerminalName').value : '',
            Location: filters.find((filter) => filter.id === 'Location') ? filters.find((filter) => filter.id === 'Location').value : '',
            Address: filters.find((filter) => filter.id === 'Address') ? filters.find((filter) => filter.id === 'Address').value : '',
            PackageStatus: filters.find((filter) => filter.id === 'PackageStatus') ? filters.find((filter) => filter.id === 'PackageStatus').value : 2,
        },
        errors: {},
    });

    const _onChange = ({ target }) => {
        const { name, value } = target;
        state.data[name] = value;
        setState({ ...state });
    };

    const _handleFilter = () => {
        const { callback } = props;
        const filters = [];
        Object.keys(state.data).forEach((key) => {
            if (state.data[key] !== null && state.data[key] !== undefined && state.data[key] !== '') {
                filters.push({ id: key, value: state.data[key] });
            }
        });
        callback();
        handleFilter(filters);
    };

    return (
        <>
            <Row>
                <Col md={6}>
                    <Input
                        name="StoredDateStart"
                        value={state.data.StoredDateStart}
                        onChange={_onChange}
                        label="Fecha Inicio"
                        placeholder="Fecha inicio"
                        icon="fas fa-calendar-alt"
                        errors={state.errors}
                        type="date"
                    />
                </Col>
                <Col md={6}>
                    <Input
                        name="StoredDateEnd"
                        value={state.data.StoredDateEnd}
                        onChange={_onChange}
                        label="Fecha Termino"
                        placeholder="Fecha termino"
                        icon="fas fa-calendar-alt"
                        errors={state.errors}
                        type="date"
                    />
                </Col>
                <Col md={6}>
                    <Input
                        name="PackageId"
                        value={state.data.PackageId}
                        onChange={_onChange}
                        label="Package ID"
                        placeholder="Id del paquete"
                        icon="fas fa-archive"
                        errors={state.errors}
                    />
                </Col>
                <Col md={6}>
                    <Input
                        name="CustomerName"
                        value={state.data.CustomerName}
                        onChange={_onChange}
                        label="Nombre"
                        placeholder="Nombre del Residente"
                        icon="fas fa-user"
                        errors={state.errors}
                    />
                </Col>
                <Col md={6}>
                    <Input
                        name="TerminalName"
                        value={state.data.TerminalName}
                        onChange={_onChange}
                        label="Terminal"
                        placeholder="Nombre del terminal"
                        icon="fas fa-terminal"
                        errors={state.errors}
                    />
                </Col>
                <Col md={6}>
                    <Input
                        name="Location"
                        value={state.data.Location}
                        onChange={_onChange}
                        label="Dirección"
                        placeholder="Dirección"
                        icon="fas fa-map"
                        errors={state.errors}
                    />
                </Col>

                <Col md={6}>
                    <Input
                        name="Address"
                        value={state.data.Address}
                        onChange={_onChange}
                        label="Departamento"
                        placeholder="Departamento"
                        icon="fas fa-building"
                        errors={state.errors}
                    />
                </Col>

                <Col md={6}>
                    <Select
                        label="Status"
                        value={state.data.PackageStatus}
                        options={[
                            { id: 0, name: 'En el Locker', value: '0' },
                            { id: 1, name: 'Retirado', value: '1' },
                            { id: 2, name: 'Todos', value: '2' },
                        ]}
                        placeholder="Seleccione..."
                        name="PackageStatus"
                        onChange={_onChange}
                        errors={state.errors}
                        required
                    />
                </Col>
            </Row>
            <div className="form-footer">
                {state.loading && (
                    <div className="spinner">
                        <Spinner />
                    </div>
                )}
                <Button text="Filtrar" onClick={_handleFilter} />
            </div>
        </>
    );
};

export default FilterForm;
