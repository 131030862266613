/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Select from '../../Components/Select/Select';
import Input from '../../Components/Input/Input';
import Button from '../../Components/Button/Button';
import { createCustomer } from '../../Service/Api';

const AddClient = (props) => {
    const [errors, setErrors] = useState({});
    const [data, setData] = useState({});
    const {
        user, users, serviceStation, serviceStations, onChange, loading, callback,
    } = props;

    const readOnly = false;

    const onChangeInput = ({ target }) => {
        const { name, value } = target;
        const d = { ...data };
        d[name] = value;
        const e = { ...errors };
        e[name] = undefined;
        setErrors(e);
        setData(d);
    };

    const handleSubmit = () => {
        const params = { ...data, feature: 'dual_locker', DepartmentID: serviceStation };
        createCustomer(params)
            .then(() => {
                toast.success('Cliente registrado exitosamente', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                callback();
                setData({});
            }).catch(({ response }) => {
                setErrors(response.data);
            });
    };

    const validateData = () => {
        setErrors({});
        const e = {};
        let formIsValid = true;
        console.log(data);
        const {
            order_number, CustomerName, Address, CustomerMobile, Email, IDCard,
        } = data;

        if (!order_number || order_number.trim().length === 0) {
            formIsValid = false;
            e.order_number = ['Requerido'];
        }

        if (!CustomerName || CustomerName.trim().length === 0) {
            formIsValid = false;
            e.CustomerName = ['Requerido'];
        }

        if (!Address || Address.trim().length === 0) {
            formIsValid = false;
            e.Address = ['Requerido'];
        }

        if (!CustomerMobile || CustomerMobile.trim().length === 0) {
            formIsValid = false;
            e.CustomerMobile = ['Requerido'];
        }


        if (!Email || Email.trim().length === 0) {
            formIsValid = false;
            e.Email = ['Requerido'];
        }

        if (!IDCard || IDCard.trim().length === 0) {
            formIsValid = false;
            e.IDCard = ['Requerido'];
        }
        console.log(e);

        if (formIsValid) {
            handleSubmit();
        } else {
            setErrors(e);
        }
    };


    return (
        <>
            <Row>
                <Col md={12}>
                    <Select label="Empresa" value={user.toString()} options={users} placeholder="Seleccione..." name="user" onChange={onChange} required loading={loading} />
                </Col>
            </Row>

            <Row>
                <Col md={12}>
                    <Select label="Service Station" value={serviceStation.toString()} options={serviceStations} placeholder="Seleccione..." name="serviceStation" onChange={onChange} required loading={loading} />
                </Col>
            </Row>

            {serviceStation !== -1 && (
                <Row>
                    <form onSubmit={(e) => e.preventDefault()} className="w-100" autoComplete="off">
                        <Col md={12}><Input name="order_number" value={data.order_number} onChange={onChangeInput} readOnly={readOnly} label="Número Pedido" placeholder="Numero del pedido" icon="fas fa-hashtag" errors={errors} required /></Col>
                        <Col md={12}><Input name="CustomerName" value={data.CustomerName} onChange={onChangeInput} readOnly={readOnly} label="Nombre" placeholder="Nombre del cliente" icon="fas fa-user" errors={errors} required /></Col>
                        <Col md={12}><Input name="IDCard" value={data.IDCard} onChange={onChangeInput} readOnly={readOnly} label="Rut" mask="rut" placeholder="Rut del cliente" icon="fas fa-id-card" errors={errors} required /></Col>
                        <Col md={12}><Input name="Address" value={data.Address} onChange={onChangeInput} readOnly={readOnly} label="Producto" placeholder="Nombre del producto" icon="fas fa-box-open" errors={errors} required /></Col>
                        <Col md={12}><Input name="CustomerMobile" mask="phone" value={data.CustomerMobile} onChange={onChangeInput} readOnly={readOnly} label="Telefono del cliente" placeholder="Telefono del cliente" icon="fas fa-phone" errors={errors} required /></Col>
                        <Col md={12}><Input name="Email" value={data.Email} onChange={onChangeInput} readOnly={readOnly} label="Email" placeholder="Email del cliente" icon="fas fa-envelope" errors={errors} required /></Col>
                        <Col md={12}><Button onClick={validateData} text="Guardar" /></Col>
                    </form>
                </Row>
            )}
        </>
    );
};

export default AddClient;
