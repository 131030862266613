/* eslint-disable react/prop-types */
import React from 'react';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Select from '../../Components/Select/Select';

const Terminals = (props) => {
    const {
        user, users, serviceStation, serviceStations, terminals, onChange, loading,
    } = props;
    return (
        <>
            <Row>
                <Col md={12}>
                    <Select label="Empresa" value={user.toString()} options={users} placeholder="Seleccione..." name="user" onChange={onChange} required loading={loading} />
                </Col>
            </Row>

            <Row>
                <Col md={12}>
                    <Select label="Service Station" value={serviceStation.toString()} options={serviceStations} placeholder="Seleccione..." name="serviceStation" onChange={onChange} required loading={loading} />
                </Col>
            </Row>

            <Row className="justify-content-between px-0 mb-3">
                <Col md={12}>
                    <table className="table table-striped">
                        <thead className="thead-dark">
                            <tr style={{ textAlign: 'center' }}>
                                <th scope="col">#</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Dirección</th>
                                <th scope="col">Username</th>
                            </tr>
                        </thead>
                        <tbody>
                            {user !== -1 && terminals && terminals.map((item) => (

                                <tr style={{ textAlign: 'center' }}>
                                    <th scope="row">{item.TerminalNo}</th>
                                    <td>{item.TerminalName}</td>
                                    <td>{item.Location}</td>
                                    <td>{item.Username}</td>
                                </tr>

                            ))}
                        </tbody>
                    </table>
                </Col>
            </Row>
        </>
    );
};


export default Terminals;
