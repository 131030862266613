/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import Button from '../../Components/Button/Button';

import { getApiTokens, createNewTokensKeys } from '../../Service/Api';
import './Token.scss';

const Token = () => {
    const [key, setKey] = useState('');
    const [token, setToken] = useState('');

    useEffect(() => {
        getTokens();
    }, []);

    const getTokens = () => {
        getApiTokens()
            .then((response) => {
                setKey(response.data.api_key);
                setToken(response.data.token_key);
            }).catch(({ response }) => {
                console.log('Api Token Error...');
            });
    };

    const createNewKeys = () => {
        createNewTokensKeys()
            .then(() => {
                getTokens();
            });
    };

    return (
        <div className="token-content">
            <div className="row">
                <div className="code col-12">
                    {`API_KEY: ${key}`}
                </div>
                <div className=" code col-12">
                    {`TOKEN_KEY: ${token}`}
                </div>
            </div>

            <div className="row justify-content-center p-2 m-2">
                <div className="col-12 col-md-6">
                    <Button text="Generar Nuevas Claves" onClick={createNewKeys} />
                </div>
            </div>
        </div>
    );
};

export default Token;
