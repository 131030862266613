/* eslint-disable camelcase */
import text from '../../helpers/textTemplate';

export const company_model = {
    required_words: [
        '%CustomerName%',
        '%QrCode%',
        '%OpenBoxKey%',
        '%Location%',
        '%ProductName%',
    ],
    initial_text: text,
};

export const sameday_model = {
    required_words: [
        '%CustomerName%',
        '%Company%',
        '%QrCode%',
        '%OpenBoxKey%',
        '%Location%',
        '%StartDate%',
    ],
    initial_text: '<p>Estimado %CustomerName%</p><p>Tu reserva para %Company% se realizó correctamente.</p><p>Debes dejar el paquete en el locker ubicado en %Location%, día %StartDate%.</p><p>&nbsp;</p><p>Digite su codigo de reserva, o utilize su codigo QR en la opcion "Servicios".</p><p>&nbsp;</p><p style="text-align:center;">%QrCode%</p><p>&nbsp;</p><p>Código de reserva: %OpenBoxKey%.</p><p>&nbsp;</p><p>* Puede dejar su pedido utilizando uno de los códigos dados anteriormente</p><p>* Esta reserva es válida solo para el día informado anteriormente</p>',
};
