import React, { useEffect, useState } from "react";
import TopBar from "../../Components/TopBar/TopBar";
import ModalView from "../../Layout/ModalView/ModalView";
import DeviceForm from "./DeviceForm";
import ReactTable from 'react-table';
import matchSorter from "match-sorter";
import { getDevices } from "../../Service/Api";

const Devices = () => {

    const [devices, setDevices] = useState();

    const _getDevices = async () => {
        const response = await getDevices();
        setDevices(response.data);
    }

    useEffect(() => {
        _getDevices();
    }, []);

    const findData = (ID) => {
        return devices.find((item) => item.ID === ID);
    }

    const columns = [
        {
            Header: 'ID',
            accessor: 'DeviceID',
            Cell: (row) => (
                <span>{row.original.ID}</span>
            ),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['DeviceID'] }),
            filterAll: true,
            filterable: true,
        }, {
            Header: 'DeviceName',
            accessor: 'DeviceName',
            Cell: (row) => (
                <span>{row.original.DeviceName}</span>
            ),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['DeviceName'] }),
            filterAll: true,
            filterable: true,
        }, {
            Header: 'AccessToken',
            accessor: 'AccessToken',
            Cell: (row) => (
                <span>{row.original.AccessToken}</span>
            ),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['AccessToken'] }),
            filterAll: true,
            filterable: true,
        },
        {
            Header: 'DeviceToken',
            accessor: 'DeviceToken',
            Cell: (row) => (
                <span>{row.original.DeviceToken}</span>
            ),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['DeviceToken'] }),
            filterAll: true,
            filterable: true,
        },
        {
            Header: 'Terminal',
            accessor: 'Terminal',
            Cell: (row) => (
                <span>{row.original.terminal ? row.original.terminal.TerminalName : 'Sin terminal asignada'}</span>
            ),
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['Terminal'] }),
            filterAll: true,
            filterable: true,
        },
        {
            Header: 'Acciones',
            id: 'actions',
            filterable: false,
            sortable: false,
            maxWidth: 100,
            Cell: (row) => (
                <div className="form-actions">
                    <ModalView title="Editar dispositivo" type="edit" callback={_getDevices}>
                        <DeviceForm data={findData(row.original.ID)} />
                    </ModalView>
                </div>
            ),
        }
    ];

    return (
        <>
            <TopBar>
                <ModalView title="Crear Dispositivo" type="add" callback={_getDevices}>
                    <DeviceForm />
                </ModalView>
            </TopBar>

            <ReactTable
                data={devices}
                columns={columns}
            />
        </>
    );
}

export default Devices;