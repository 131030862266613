/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { v4 } from "uuid";
import toolbar from "../../helpers/toolbar";
import UploadImage from "../../Components/UploadImage";
import { toBase64 } from "../../Service/Utils";
import { get, put } from "../../Service/Api";
import { company_model, sameday_model } from "./template_model";

const EditTemplate = (props) => {
  const [state, setState] = useState({
    text: "",
    subject: "",
    is_active: false,
    image: [],
    user_id: -1,
    required_words: [],
  });

  const [editor, setEditor] = useState(null);

  const [companies, setCompanies] = useState([]);
  const url = process.env.REACT_APP_API_URL;
  const history = useHistory();

  useEffect(() => {
    if (editor) {
      get(`${url}/users/template-mail-users`).then((response) => {
        setCompanies(response.data);
        get(`${url}/mailer/${props.match.params.id}`).then((template) => {
          const image = [];
          const { data } = template;
          image.push({ src: data.image });
          const company = response.data.find(
            (c) => c.id === parseInt(data.user_id)
          );
          setState({
            ...state,
            ...data,
            image,
            required_words:
              company.role_name === "company"
                ? company_model.required_words
                : sameday_model.required_words,
          });
          editor.setData(data.text);
        });
      });
    }
  }, [editor]);

  const handleImage = (e) => {
    const { target } = e;
    const { files } = target;
    Array.from(files).forEach((item) => {
      toBase64(item)
        .then((response) => {
          const image = [];
          image.push({ src: response, id: v4() });
          setState({ ...state, image });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const handleSubmit = () => {
    const errors = [];
    state.required_words.forEach((word) => {
      const searchWord = state.text.indexOf(word);
      if (searchWord === -1 && searchWord !== '%ProductName%') {
        errors.push({ word });
      }
    });

    if (errors.length > 0) {
      toast.error(
        "Compruebe si todas las palabras clave están presentes en la plantilla."
      );
      return;
    }

    const params = JSON.parse(JSON.stringify(state));
    params.image = state.image.length ? params.image[0].src : null;
    put(`${url}/mailer/${props.match.params.id}`, params)
      .then(() => {
        history.push("/admin/template-mail?is_saved=1");
      })
      .catch(({ response }) => {
        if (response && response.status === 422) {
          toast.error("Complete todos los campos");
        }

        if (response && response.status === 500) {
          toast.error(
            "Compruebe si todas las palabras clave están presentes en la plantilla."
          );
        }
      });
  };

  const _handleSelectCompany = ({ target }) => {
    const { name, value } = target;
    state[name] = value;
    const company = companies.find((c) => c.id === parseInt(value));
    if (company && company.role_name === "company") {
      setState({
        ...state,
        required_words: company_model.required_words,
      });
    } else {
      setState({
        ...state,
        required_words: sameday_model.required_words,
      });
    }
  };

  return (
    <div className="bg-white p-5">
      <div className="title-container">Editar Template</div>

      <div className="row mt-3">
        <div className="col-12">
          Empresa
          <select
            value={state.user_id}
            onChange={_handleSelectCompany}
            className="form-select"
            aria-label="Default select example"
            name="user_id"
            disabled
          >
            <option value={-1} selected>
              Selecciona una empresa
            </option>
            {companies &&
              companies.map((item) => (
                <option value={item.id}>{item.name}</option>
              ))}
          </select>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          Subject
          <div className="input-group">
            <input
              className="form-control"
              type="text"
              value={state.subject}
              onChange={(e) => setState({ ...state, subject: e.target.value })}
              placeholder="Título del correo"
            />
          </div>
        </div>
      </div>

      <div className="row mt-3 border border-danger rounded p-3">
        <div className="col-12">
          <b>Palabras Claves Requeridas</b>
        </div>
        <div className="col-12">
          <ul>
            {state.required_words.map((word, index) => (
              <li key={`keyword-${index}`}>{word}</li>
            ))}
          </ul>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          Texto
          <CKEditor
            editor={Editor}
            data=""
            config={{ toolbar }}
            onReady={(editor) => {
              setEditor(editor);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              setState({ ...state, text: data });
            }}
          />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          Logo (Tamaño Max: 200x200 - Peso Max: 1MB - Formatos: png, jpg)
          <UploadImage noMultiple onChange={handleImage} data={state.image} />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          Status
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="status"
              checked={state.is_active}
              onChange={(e) =>
                setState({ ...state, is_active: e.target.checked })
              }
            />
            <label className="form-check-label" htmlFor="status">
              Activar
            </label>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <button className="button-main" onClick={handleSubmit}>
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditTemplate;
