import React from 'react';
import { Link } from 'react-router-dom';

import image1 from '../assets/img/manuals/ecommerce01.png'
import Image from '../Components/Image/Image';

import './Manuals.scss';

const Ecommerce = (props) => {
    const { } = props;

    return (
        <div className="manuals">
            <h3>Ecommerce</h3>

            <p>
                Un ecommerce es una empresa que puede entregar a un terminal asociado a él por un administrador del sistema.
            </p>

            <h3>
                Crear Ecommerce
            </h3>

            <p>
                Crear usuario con rol ecommerce <br />
                Ver Crear <Link to="/admin/manuals/users" >Usuario</Link>
            </p>

            <h3>Asociar Terminal a un Ecommerce</h3>

            <p>
                1 - Para asociar un terminal a un ecommerce, primero haga clic en Ecommerce en el menú de la izquierda.
            </p>

            <p>
                2 - Seleccione el ecommerce que quiere asociar un terminal.
            </p>

            <p>
                3 - Elige las terminales y guarda los cambios.
            </p>

            <Image file={image1} />
        </div>
    )
}

export default Ecommerce;