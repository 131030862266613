/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
/* eslint-disable no-case-declarations */
import React, { useContext, useEffect, useState } from 'react';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import * as XLSX from 'xlsx';
import Button from '../../Components/Button/Button';
import Uploader from '../../Components/Uploader/Uploader';
import { massiveCustomerLoad, getDepartments } from '../../Service/Api';
import Select from '../../Components/Select/Select';

import { StateContext } from '../../State';

const UploadForm = (props) => {
    const { callback } = props;
    const [text, setText] = useState(undefined);
    const [errors, setErrors] = useState({});
    const [disabled, setDisabled] = useState(true);
    const [profiles, setProfiles] = useState([]);
    const [DepartmentID, setDepartmentID] = useState('-1');
    const context = useContext(StateContext);
    const [state, setState] = useState({
        fileErrors: [],
        data: [],
    });

    const formatDbDate = (data) => {
        const departments = [];
        data.forEach((item) => {
            departments.push({
                id: item.DepartmentID,
                name: item.DepartmentName,
            });
        });
        return departments;
    };

    const handleError = (error) => {
        const { status } = error.response;
        if (status === 401 || status === 403) {
            const [, dispatch] = context;
            dispatch({
                type: 'EXIT',
            });
        }
    };

    useEffect(() => {
        getDepartments()
            .then((response) => {
                setProfiles(formatDbDate(response.data));
            })
            .catch((error) => {
                handleError(error);
            });
    }, []);

    const _processData = (dataString) => {
        const validHeaders = [
            'Numero Pedido',
            'Nombre Cliente',
            'Rut',
            'Producto',
            'Telefono',
            'Email',
        ];

        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

        let headerError = 0;
        const fileErrors = [];
        const headerErrors = {};

        headers.forEach((h) => {
            if (!validHeaders.includes(h)) {
                headerError++;
                headerErrors[`Encabezado ${h}`] = 'invalido';
            }
        });

        if (headerError > 0) {
            fileErrors.push({
                line: 1,
                ...headerErrors,
            });
        }

        const list = [];
        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            if (headers && row.length === headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] === '"') d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] === '"') d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j]] = d;
                    }
                }

                // remove the blank rows
                if (Object.values(obj).filter((x) => x).length > 0) {
                    list.push(obj);
                }
            }
        }

        list.forEach((register, index) => {
            const errorLog = {};
            Object.keys(register).forEach((key) => {
                if (register[key].trim().length === 0) {
                    errorLog[key] = 'requerido';
                }
            });
            if (Object.keys(errorLog).length > 0) {
                errorLog.line = index + 2;
                fileErrors.push(errorLog);
            }
        });

        if (fileErrors.length) {
            setState({ ...state, fileErrors });
        } else {
            setDisabled(false);
            setState({ ...state, data: list, fileErrors });
        }
    };

    const onChange = (event) => {
        const { target } = event;
        const { name, value } = target;
        setState({ ...state, fileErrors: [] });
        switch (name) {
            case 'csv':
                setDisabled(true);
                const e = { ...errors };
                delete e.file;
                setErrors(e);
                if (target.files.length) {
                    if (target.files[0].type === 'text/csv'
                        || target.files[0].type === 'application/vnd.ms-excel'
                        || target.files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                        // Set Name file
                        setText(target.files[0].name);
                        const file = target.files[0];
                        const reader = new FileReader();
                        reader.onload = (evt) => {
                            /* Parse data */
                            const bstr = evt.target.result;
                            const wb = XLSX.read(bstr, { type: 'binary' });
                            /* Get first worksheet */
                            const wsname = wb.SheetNames[0];
                            const ws = wb.Sheets[wsname];
                            /* Convert array of arrays */
                            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
                            _processData(data);
                        };
                        reader.readAsBinaryString(file);
                    } else {
                        setErrors({ ...errors, ...{ file: 'Formato de archivo inválido' } });
                    }
                } else {
                    setText(undefined);
                }
                break;
            default:
                setDepartmentID(value);
        }
    };

    const handleSubmit = () => {
        const [, dispatch] = context;
        dispatch({
            type: 'SET_LOADING',
            value: true,
        });
        massiveCustomerLoad({ data: state.data, DepartmentID }).then(() => {
            dispatch({
                type: 'SET_LOADING',
                value: false,
            });
            callback();
        }).catch(() => {
            dispatch({
                type: 'SET_LOADING',
                value: false,
            });
            callback();
        });
    };

    return (
        <Row>
            <Col md={12}>
                <Uploader name="csv" onChange={onChange} text={text} accept=".csv, .xlsx" />
            </Col>
            <Col md={12}><Select label="Service Station" value={DepartmentID} options={profiles} placeholder="Seleccione..." name="DepartmentID" onChange={onChange} readOnly={false} errors={errors} required /></Col>
            <Col md={12}>
                <Button text="Guardar" onClick={handleSubmit} disabled={disabled || DepartmentID === '-1'} />
            </Col>
            <Col md={12}>
                {Object.keys(errors).map((item) => (
                    <Row key={item} className="justify-content-center">
                        <Col className="alert alert-danger my-3" md={8}>
                            {errors[item]}
                        </Col>
                    </Row>
                ))}
            </Col>

            <div className="col-12 px-5 my-2" style={{ maxHeight: '250px', overflowY: 'auto' }}>
                {state.fileErrors.map((item) => (
                    <div className="row border rounded my-2 alert alert-danger">
                        <div className="col-12">
                            {`Error encontrado en la linea ${item.line}`}
                        </div>
                        {Object.keys(item).map((key) => {
                            if (key !== 'line') {
                                return (
                                    <div className="col-12">
                                        {`${key} es ${item[key]}`}
                                    </div>
                                );
                            }

                            return <></>;
                        })}
                    </div>
                ))}
            </div>
        </Row>
    );
};

export default UploadForm;
