/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-alert */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ReactSelect from 'react-select';
import DateTime from 'react-datetime';
import collect from 'collect.js';

import {
    getSamedayToken,
    getSamedayBoxStatus,
    samedayReservation,
    getEcommerceToken,
    getEcommerceBoxStatus,
    ecommerceReservation,

} from '../../Service/Api';


import './AddReservation.scss';
import 'react-datetime/css/react-datetime.css';
import 'moment/locale/es';

import box from '../../assets/img/box2.svg';

import Input from '../../Components/Input/Input';
import Button from '../../Components/Button/Button';

const SamedaySandbox = (props) => {
    const [errors, setErrors] = useState({});
    const [terminal, setTerminal] = useState('-1');
    const [terminalData, setTerminalData] = useState([]);
    const [customer, setCustomer] = useState({});
    const [loading, setLoading] = useState(true);
    const [BoxNo, setBoxNo] = useState(null);
    const [dateValue, setDateValue] = useState(moment.now());
    const [locations, setLocations] = useState([]);
    const [availables, setAvailables] = useState([]);
    const [submitLoading, setSubmitLoading] = useState(false);

    const { context, callback } = props;
    const [{ user, role }] = context;

    const loadData = (date = null) => {
        setLoading(true);
        if (role.name === 'sameday') {
            getSamedayToken(user.id).then((response) => {
                getSamedayBoxStatus(response.data.token, date === null ? moment().format('YYYY-MM-DD') : date.format('YYYY-MM-DD'))
                    .then((r) => {
                        setLocations(formatTerminal(r.data));
                        setLoading(false);
                    }).catch(() => {
                        setLocations([]);
                        setLoading(false);
                        alert('Fecha Invalida');
                    });
            });
        } else {
            getEcommerceToken(user.id).then((response) => {
                getEcommerceBoxStatus(response.data.token, date === null ? moment().format('YYYY-MM-DD') : date.format('YYYY-MM-DD'))
                    .then((r) => {
                        setLocations(formatTerminal(r.data));
                        setLoading(false);
                    }).catch(() => {
                        setLocations([]);
                        setLoading(false);
                        alert('Fecha Invalida');
                    });
            });
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const formatTerminal = (data) => {
        data.forEach((item) => {
            item.name = `${item.TerminalName} - ${item.Location}`;
            item.id = item.TerminalNo;
            item.value = item.Location;
            item.label = `${item.TerminalName} - ${item.Location}`;
        });

        return data;
    };

    const onChange = (e) => {
        if (e !== null) {
            setTerminal(e);
            setErrors({ ...errors, TerminalNo: '' });
            setTerminalData(e.boxes);
            getAvailablesBoxes(e.boxes);
            setBoxNo(null);
        } else {
            setTerminalData([]);
            setTerminal(-1);
            setBoxNo(null);
        }
    };

    const onChangeCustomer = ({ target }) => {
        const c = { ...customer };
        c[target.name] = target.value;
        setCustomer(c);
        const e = { ...errors };
        e[target.name] = '';
        setErrors(e);
    };

    const getAvailablesBoxes = (boxes) => {
        const availableBox = boxes.filter((item) => item.available);
        const collection = collect(availableBox);
        const grouped = collection.groupBy('type.BoxTypeName');
        const json = JSON.parse(grouped.toJson());
        setAvailables(json);
    };

    const getErrors = (e) => {
        const errs = {};
        Object.keys(e).forEach((key) => {
            switch (key) {
                case 'TerminalNo':
                    errs.TerminalNo = e[key][0];
                    break;
                default:
                    errs[key] = e[key][0];
            }
        });

        return errs;
    };

    const submitReservation = () => {
        const params = {
            ...customer,
            BoxNo,
            TerminalNo: terminal.TerminalNo,
            day: moment(dateValue).format('YYYY-MM-DD'),
        };
        setSubmitLoading(true);
        if (role.name === 'sameday') {
            getSamedayToken(user.id).then((r) => {
                samedayReservation(params, r.data.token)
                    .then(() => {
                        setSubmitLoading(false);
                        callback();
                    })
                    .catch(({ response }) => {
                        setSubmitLoading(false);
                        const errs = { ...response.data };
                        setErrors(getErrors(errs));
                    });
            });
        } else {
            getEcommerceToken(user.id).then((r) => {
                ecommerceReservation(params, r.data.token)
                    .then(() => {
                        setSubmitLoading(false);
                        callback();
                    })
                    .catch(({ response }) => {
                        setSubmitLoading(false);
                        const errs = { ...response.data };
                        setErrors(getErrors(errs));
                    });
            });
        }
    };

    const handleDateChange = (value) => {
        setDateValue(value);
        loadData(value);
        setTerminal(-1);
        setTerminalData([]);
        setBoxNo(null);
    };

    const selectBox = (value) => {
        const a = { ...availables };
        Object.keys(a).forEach((item) => {
            a[item].selected = false;
        });
        a[value].selected = true;
        setAvailables(a);
        setBoxNo(a[value][0].BoxNo);

        const e = { ...errors };
        e.BoxNo = '';
        setErrors(e);
    };

    return (
        <div className="esandbox-container">
            <div className="esandbox-content">

                <div className="row mb-3">
                    <div className="col-6">
                        <label>Fecha Reserva *</label>
                        <form action="" autoComplete="off">
                            <DateTime
                                locale="es"
                                timeFormat={false}
                                closeOnSelect
                                autoComplete="off"
                                onChange={handleDateChange}
                                value={dateValue}
                                className="datepicker"
                            />
                        </form>
                    </div>

                    <div className="col-6">
                        <label> Direccion Locker * </label>
                        <ReactSelect
                            className="select-css"
                            options={locations}
                            onChange={onChange}
                            isLoading={loading}
                            placeholder="Elige una opcion..."
                            value={terminal}
                            styles={{
                                control: (provided) => ({ ...provided, ...{ border: '1px solid #868686' } }),
                            }}
                            isClearable
                            isSearchable
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <Input onChange={onChangeCustomer} value={customer.fullname} label="Nombre" name="fullname" errors={errors} required />
                    </div>
                    <div className="col-6">
                        <Input onChange={onChangeCustomer} mask="rut" value={customer.rut} label="Rut" name="rut" errors={errors} required />
                    </div>
                    <div className="col-6">
                        <Input onChange={onChangeCustomer} value={customer.email} label="Correo" name="email" errors={errors} required />
                    </div>
                    <div className="col-6">
                        <Input onChange={onChangeCustomer} mask="phone" value={customer.phone} label="Telefono" name="phone" errors={errors} required />
                    </div>
                    <div className="col-12">
                        <Input onChange={onChangeCustomer} value={customer.address} label="Direccion" name="address" errors={errors} required />
                    </div>
                </div>

                {errors.BoxNo !== '' && errors.BoxNo !== undefined && (
                    <div className="row">
                        <div className="col-12">
                            <div className="alert alert-danger w-100">Elige una caja para continuar</div>
                        </div>
                    </div>
                )}

                {errors.TerminalNo !== '' && errors.TerminalNo !== undefined && (
                    <div className="row">
                        <div className="col-12">
                            <div className="alert alert-danger w-100">Elige un Locker para continuar</div>
                        </div>
                    </div>
                )}


                {terminalData.length > 0 && (
                    <>
                        <div className="row">
                            <div className="col-12 title">
                                Elija una caja
                            </div>
                        </div>
                        <div className="row boxes">

                            {Object.keys(availables).map((item) => (
                                <div
                                    className={`col Mediana ${availables[item].selected ? 'selected' : ''} `}
                                    key={item}
                                    onClick={() => selectBox(item)}
                                >
                                    <img src={box} alt="box" />
                                    {item}
                                    <div className="info">
                                        Altura:
                                        {' '}
                                        {parseInt(availables[item][0].type.BoxHeight) / 10}
                                        {' cm'}
                                        <br />
                                        Ancho:
                                        {' '}
                                        {parseInt(availables[item][0].type.BoxWidth) / 10}
                                        {' cm'}
                                        <br />
                                        Profundidad:
                                        {' '}
                                        {parseInt(availables[item][0].type.BoxDepth) / 10}
                                        {' cm'}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}

                <div className="row justify-content-center">
                    <div
                        className="col-4 mt-5"
                        style={{
                            display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
                        }}
                    >
                        <Button text="Reservar" onClick={submitReservation} disabled={submitLoading} />
                        {
                            submitLoading && (
                                <div className="mt-5">
                                    <div className="spinner-border" style={{ color: '#838383' }} />
                                </div>
                            )
                        }
                    </div>
                </div>

            </div>
        </div>
    );
};


export default SamedaySandbox;
