/* eslint-disable camelcase */
import axios from 'axios';
import { decode } from './Utils';

const url = process.env.REACT_APP_API_URL;

axios.interceptors.request.use((config) => {
    const tokenSession = localStorage.getItem('t');

    if (tokenSession) {
        const token = decode(tokenSession);
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = token;
    }

    return config;
}, (err) => Promise.reject(err));

// GENERAL

export async function login(data) {
    return axios.post(`${url}/sign-in`, { ...data });
}

export async function logout() {
    return axios.post(`${url}/logout`);
}

export async function recover(email) {
    return axios.post(`${url}/password`, { email });
}

export async function restore(data) {
    return axios.post(`${url}/reset`, { ...data });
}

// USERS

export async function getUsers() {
    return axios.get(`${url}/users`);
}

export async function addUser(data) {
    return axios.post(`${url}/users`, { ...data });
}

export async function updateUser(data, id) {
    return axios.put(`${url}/users/${id}`, { ...data });
}

export async function deleteUser(id) {
    return axios.delete(`${url}/users/${id}`);
}

export async function restoreUser(id) {
    return axios.get(`${url}/users/recovery/${id}`);
}


// ROLES

export async function getRoles() {
    return axios.get(`${url}/roles`);
}


// Terminal

export async function getTerminals() {
    return axios.get(`${url}/terminals`);
}

export async function getTerminal(id) {
    return axios.get(`${url}/terminals/${id}`);
}

export async function createTerminal(data) {
    return axios.post(`${url}/terminals`, data);
}

export async function updateTerminal(data, id) {
    return axios.put(`${url}/terminals/${id}`, data);
}

export async function deleteTerminal(id) {
    return axios.delete(`${url}/terminals/${id}`);
}

export async function updateBoxes(params) {
    return axios.post(`${url}/terminals/sidelockers`, params);
}

export async function getTerminalEcommerce() {
    return axios.get(`${url}/terminals-ecommerce`);
}

export async function saveTerminalEcommerce(params) {
    return axios.post(`${url}/terminals-ecommerce`, params);
}


// DEPARTMENTS

export async function getDepartments() {
    return axios.get(`${url}/departments`);
}

export async function updateDepartment(data) {
    return axios.put(`${url}/department/${data.DepartmentID}`, data);
}

export async function createDepartment(data) {
    return axios.post(`${url}/department`, data);
}

export async function getCompany(data) {
    return axios.get(`${url}/users/company`, data);
}

export async function getTerminalCompany() {
    return axios.get(`${url}/terminals-company`);
}

export async function saveTerminalCompany(params) {
    return axios.post(`${url}/terminals-company`, params);
}

export async function toogleCompanyFeatureDualLocker(params) {
    return axios.post(`${url}/company/toogle-feature/dual-locker`, params);
}

// PACKAGES

export async function getPackages() {
    return axios.get(`${url}/package`);
}

export async function massiveCustomerLoad(data) {
    return axios.post(`${url}/customers/massive-customer-load`, data);
}

// HISTORY

export async function getHistory() {
    return axios.get(`${url}/delivery-history`);
}


// RESERVATIONS

export async function getReservations() {
    return axios.get(`${url}/reservations`);
}

export async function sendSMS(params) {
    return axios.post(`${url}/reservations/msg-sms`, params);
}

export async function sendEmail(params) {
    return axios.post(`${url}/reservations/email`, params);
}

export async function deleteReservation(id) {
    return axios.delete(`${url}/reservations/${id}`);
}


// API TOKEN KEY
export async function getApiTokens() {
    return axios.get(`${url}/ecommerce/token/read`);
}

export async function createNewTokensKeys() {
    return axios.post(`${url}/ecommerce/token/create`);
}

// Ecommerce

export async function getEcommerceToken(id = null) {
    if (id === null) {
        return axios.get(`${url}/get-token`);
    }

    return axios.get(`${url}/get-token/${id}`);
}

export async function getEcommerceBoxStatus(token, day) {
    const instance = axios.create({
        headers: {
            Authorization: token,
        },
    });
    return instance.get(`${url}/ecommerce/get-boxes?day=${day}`);
}

export async function ecommerceReservation(params, token) {
    const instance = axios.create({
        headers: {
            Authorization: token,
        },
    });
    return instance.post(`${url}/ecommerce/reservations`, params);
}

// SAMEDAY

export async function getSamedayToken(id = null) {
    if (id === null) {
        return axios.get(`${url}/sameday/get-token`);
    }

    return axios.get(`${url}/sameday/get-token/${id}`);
}

export async function getSamedayBoxStatus(token, day) {
    const instance = axios.create({
        headers: {
            Authorization: token,
        },
    });
    return instance.get(`${url}/sameday/availables?start_date=${day}`);
}

export async function samedayReservation(params, token) {
    const instance = axios.create({
        headers: {
            Authorization: token,
        },
    });
    return instance.post(`${url}/sameday/reservations`, params);
}

export async function getSamedayEcommerce() {
    return axios.get(`${url}/sameday-ecommerce`);
}

export async function saveSamedayEcommerce(params) {
    return axios.post(`${url}/sameday-ecommerce`, params);
}


// CUSTOMERS

export async function getAllCustomers() {
    return axios.get(`${url}/customers`);
}

export async function getAllCustomersByCompany() {
    return axios.get(`${url}/customers/company`);
}

export async function createCustomer(data) {
    return axios.post(`${url}/customers`, data);
}

export async function updateCustomer(data) {
    return axios.put(`${url}/customers/${data.CustomerID}`, data);
}

export async function deleteCustomer(id) {
    return axios.delete(`${url}/customers/${id}`);
}

export async function deleteCustomers(params) {
    return axios.post(`${url}/customers/delete-many`, params);
}

export async function uploadCsv(data) {
    return axios.post(`${url}/customers/load-csv`, data);
}


// COURIER

export async function getCouriers() {
    return axios.get(`${url}/courier`);
}

export async function getCouriersApp() {
    return axios.get(`${url}/company`);
}

export async function createCompany(data) {
    return axios.post(`${url}/company`, data);
}

export async function updateCompany(data) {
    return axios.put(`${url}/company/${data.CompanyID}`, data);
}

export async function deleteCompany(id) {
    return axios.delete(`${url}/company/${id}`);
}


// CLIENTS

export async function getAllClients() {
    return axios.get(`${url}/clients`);
}

export async function updateClient(data) {
    return axios.put(`${url}/clients/${data.CustomerID}`, data);
}

// Templates

export async function getAllTemplates() {
    return axios.get(`${url}/mailer`);
}

// Boxes

export async function getAllBoxes() {
    return axios.get(`${url}/boxes`);
}

export async function createBox(data) {
    return axios.post(`${url}/boxes`, data);
}

export async function updateBox(data) {
    return axios.put(`${url}/boxes/${data.BoxType}`, data);
}

// devices
export async function getDevices() {
    return axios.get(`${url}/devices`);
}
export async function createDevice(data) {
    return axios.post(`${url}/devices`, data);
}
export async function updateDevice(data, id) {
    return axios.put(`${url}/devices`, data);
}

// Verbs

export async function get(path) {
    return axios.get(`${path}`);
}

export async function post(path, params) {
    return axios.post(path, params);
}

export async function put(path, params) {
    return axios.put(path, params);
}
