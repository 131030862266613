/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import classes from './DualLocker.module.scss';
import Select from '../../Components/Select/Select';
import { get } from '../../Service/Api';

const MultiBox = (props) => {
    const {
        users, loading, data, saveMultibox,
    } = props;

    const _handleOnChange = ({ target }) => {
        if (target.name === 'switch') {
            data[target.name] = target.checked;
        } else {
            data[target.name] = target.value;
        }

        data.onChange({ ...data });
    };

    useEffect(() => {
        data.onChange({ ...data, user: -1, switch: false });
        get(`${process.env.REACT_APP_API_URL}/company/feature/get`)
            .then((response) => {
                response.data.map((f) => {
                    f.name = f.title;
                    return f;
                });
                data.onChange({ ...data, features: response.data });
            });
    }, []);

    return (
        <div className={classes.section}>

            <div className="row mt-3">
                <div className="col">
                    <Select label="Features" value={data.feature} options={data.features} placeholder="Seleccione..." name="feature" onChange={_handleOnChange} required loading={loading} />
                </div>
            </div>

            <div className="row mt-3">
                <div className="col">
                    <Select label="Empresa" value={data.user.toString()} options={users} placeholder="Seleccione..." name="user" onChange={_handleOnChange} required loading={loading} />
                </div>
            </div>

            {data.user !== -1 && (
                <div className="controls">
                    <div className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" id="customSwitch1" name="switch" checked={data.switch} onChange={_handleOnChange} />
                        <label className="custom-control-label" htmlFor="customSwitch1">
                            {data.switch ? 'Deshabilitar' : 'Habilitar'}
                            {' '}
                            {data.features.find((feature) => parseInt(feature.id) === parseInt(data.feature))
                                ? data.features.find((feature) => parseInt(feature.id) === parseInt(data.feature)).name
                                : ''}
                            {' '}
                            para esta empresa
                        </label>
                    </div>

                    <div className="col mt-4 d-flex justify-content-end">
                        <button type="button" className=" w-100 btn btn-secondary" onClick={saveMultibox}>Guardar</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MultiBox;
