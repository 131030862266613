/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';
import moment from 'moment';
import TopBar from '../../Components/TopBar/TopBar';
import ModalView from '../../Layout/ModalView/ModalView';
import AddReservation from './AddReservation';
import { StateContext } from '../../State';
import { tableConfig } from '../../config';
import { ConfirmDialog } from '../../Components/Dialog/Dialog';
import {
    getReservations, deleteReservation, restoreUser, sendSMS, sendEmail,
} from '../../Service/Api';

import './Reservation.scss';
import { checkString } from '../../Service/Utils';

class Packages extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            showConfirm: false,
            data: [],
            loading: false,
            deleteId: null,
            restoreId: null,
        };
        this.handleRemove = this.handleRemove.bind(this);
        this.handleRestore = this.handleRestore.bind(this);
        this.loadData = this.loadData.bind(this);
        this.findData = this.findData.bind(this);
        this.removeUser = this.removeUser.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    findData = (id) => {
        const { data } = this.state;
        return data.find((item) => item.TerminalNo.toString() === id.toString());
    }

    handleError = (error) => {
        const { status } = error.response;
        if (status === 401 || status === 403) {
            const [, dispatch] = this.context;
            dispatch({
                type: 'EXIT',
            });
        }
    }

    async loadData() {
        this.setState({
            loading: true,
        });


        await getReservations()
            .then((response) => {
                if (this._isMounted) {
                    this.setState({
                        data: response.data,
                        loading: false,
                        deleteId: null,
                        restoreId: null,
                    });
                }
            }).catch((error) => {
                this.handleError(error);
            });
    }

    async removeUser() {
        const { deleteId } = this.state;
        await deleteReservation(deleteId).then((response) => {
            if (response && response.status === 200) {
                this.loadData();
            }
        }).catch((error) => {
            this.handleError(error);
        });
    }

    async restoreUser() {
        const { restoreId } = this.state;
        await restoreUser(restoreId).then((response) => {
            if (response && response.status === 200) {
                this.loadData();
            }
        }).catch((error) => {
            this.handleError(error);
        });
    }

    handleNewFields = (terminals) => {
        terminals.forEach((item) => {
            item.box_length = item.boxes.length;
            item.DepartmentName = item.department[0].DepartmentName;
        });
        return terminals;
    }

    handleRemove(id) {
        this.setState({
            showConfirm: true,
            deleteId: id,
        });
    }

    handleRestore(id) {
        this.setState({
            showConfirm: true,
            restoreId: id,
        });
    }

    handleStatus = (status) => {
        switch (parseInt(status)) {
            case 1:
                return 'En el Locker';
            case 4:
                return 'Aguardando Courier';
            default:
                return 'Retirado';
        }
    }

    handleSMS = (id) => {
        sendSMS({ id }).then(() => {
            alert('SMS enviado con exito');
        }).catch(() => {
            alert('Envío fallido. Intente reenviar en unos momentos');
        });
    }

    handleEmail = (id) => {
        sendEmail({ id }).then(() => {
            alert('Email enviado con exito');
        }).catch(() => {
            alert('Envío fallido. Intente reenviar en unos momentos');
        });
    }

    filterFunction = (rows, filter, props) => {
        const r = [];
        rows.forEach((item) => {
            if (props === 'start_date' || props === 'expiration_date') {
                if (checkString(filter.value, item[props].format('DD-MM-YYYY HH:mm:ss')) !== -1) {
                    r.push(item);
                }
            }
        });
        return r;
    }

    render() {
        const {
            showConfirm, data, loading, deleteId, restoreId,
        } = this.state;

        const [{ role }] = this.context;

        const columns = [
            {
                Header: 'Terminal',
                accessor: (row) => row.terminal.TerminalName,
                id: 'terminal_name',
                Cell: (row) => (<div style={{ width: '100%', textAlign: 'center' }}>{row.original.terminal.TerminalName}</div>),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['terminal_name'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Box',
                accessor: (row) => row.box_number,
                id: 'box_number',
                Cell: (row) => (<div style={{ width: '100%', textAlign: 'center' }}>{row.original.box_number}</div>),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['box_number'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Inicio Reserva',
                id: 'start_date',
                accessor: (row) => moment(row.start_date),
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'center' }}>{moment(row.original.start_date).format('DD-MM-YYYY HH:mm:ss')}</div>
                ),
                filterMethod: (filter, rows) => this.filterFunction(rows, filter, 'start_date'),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Termino Reserva',
                accessor: (row) => moment(row.expiration_date),
                id: 'expiration_date',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'center' }}>{moment(row.original.expiration_date).format('DD-MM-YYYY HH:mm:ss')}</div>
                ),
                filterMethod: (filter, rows) => this.filterFunction(rows, filter, 'expiration_date'),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Tipo',
                id: 'type',
                accessor: (row) => row.type.name,
                Cell: (row) => (
                    <div style={{ textAlign: 'center', width: '100%', textTransform: 'capitalize' }}>{row.original.type.name}</div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['type'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Empresa',
                id: 'empresa',
                accessor: (row) => (row.company ? row.company.name : row.package.company ? row.package.company.CompanyName : ''),
                Cell: (row) => (
                    <div style={{ textAlign: 'center', width: '100%', textTransform: 'capitalize' }}>{row.original.company ? row.original.company.name : row.original.package.company ? row.original.package.company.CompanyName : ''}</div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['empresa'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Codigo',
                accessor: (row) => row.code,
                id: 'code',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'center' }}>{row.original.code}</div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['code'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Direccion',
                accessor: (row) => row.terminal.Location,
                id: 'location',
                Cell: (row) => (
                    <div>
                        {row.original.terminal.Location}
                    </div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['location'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Acciones',
                id: 'actions',
                Cell: (row) => (
                    <div style={{
                        width: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',
                    }}
                    >
                        <>
                            {role.name === 'administrator' && (
                                <>
                                    <span style={{ margin: '0 10px', cursor: 'pointer' }} className="fas fa-envelope" onClick={() => this.handleEmail(row.original.id)} />
                                    <span style={{ margin: '0 10px', cursor: 'pointer' }} className="fas fa-comment-alt" onClick={() => this.handleSMS(row.original.id)} />
                                    {row.original.type.name !== 'delivery' && (
                                        <span style={{ margin: '0 10px', cursor: 'pointer' }} className="fas fa-trash-alt" onClick={() => this.handleRemove(row.original.id)} />
                                    )}
                                </>
                            )}

                        </>
                    </div>
                ),
                filterAll: false,
                filterable: false,
            },

        ];


        return (
            <>
                <TopBar>
                    {role.name === 'sameday' && (
                        <ModalView title="Crear Reserva" type="add" size="xl" callback={this.loadData}>
                            <AddReservation context={this.context} />
                        </ModalView>
                    )}

                    {role.name === 'ecommerce' && (
                        <ModalView title="Crear Reserva" type="add" size="xl" callback={this.loadData}>
                            <AddReservation context={this.context} />
                        </ModalView>
                    )}
                </TopBar>

                <div className="row p-3" style={{ color: '#868686' }}>
                    <div className="w-100" style={{ border: '2px solid #868686', borderRadius: '8px', padding: '10px' }}>
                        <div className="col-12">
                            <h2>Tipos de Reservas</h2>
                        </div>
                        <div className="col-12 pl-4 pb-2">
                            <b style={{ textDecoration: 'underline' }}>Delivery:</b>
                            {' '}
                        Paquete entregado en un casillero
                        </div>

                        <div className="col-12 pl-4 pb-2">
                            <b style={{ textDecoration: 'underline' }}>Ecommerce:</b>
                            {' '}
                        Reserva realizada por un ecommerce para que un courier la entregue
                        </div>

                        <div className="col-12 pl-4 pb-2">
                            <b style={{ textDecoration: 'underline' }}>Sameday:</b>
                            {' '}
                        Reserva realizada por un sameday aguardando usuario final realizar la entrega
                        </div>
                    </div>
                </div>

                <ReactTable
                    data={data}
                    columns={columns}
                    {...tableConfig}
                    loading={loading}
                />

                <ConfirmDialog
                    show={showConfirm}
                    title={deleteId ? 'Eliminar Reserva' : 'Restablecer usuario'}
                    message="Esta acción no se puede deshacer! ¿Está seguro?"
                    onConfirm={() => {
                        if (deleteId) {
                            this.removeUser();
                        }
                        if (restoreId) {
                            this.restoreUser();
                        }
                        this.setState({ showConfirm: false });
                    }}
                    onCancel={() => {
                        this.setState({ showConfirm: false });
                    }}
                />
            </>
        );
    }
}

Packages.contextType = StateContext;

export default Packages;
