export function toBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

export function orderBox(a, b) {
    if (a.DeskBoxNo < b.DeskBoxNo) {
        return -1;
    }
    if (a.DeskBoxNo > b.DeskBoxNo) {
        return 1;
    }
    return 0;
}

export function orderBoxDoorNumber(a, b) {
    if (a.door_number < b.door_number) {
        return -1;
    }
    if (a.door_number > b.door_number) {
        return 1;
    }
    return 0;
}
