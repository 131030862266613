import React from 'react';

import './Manuals.scss';

const Installation = (props) => {
    const { } = props;
    const API = process.env.REACT_APP_API_URL;
    return (
        <div className="manuals">
            <h3>Manual Instalacion</h3>

            <a href={`${API}/documentation/manual_instalacion.html`} target="_blank">Ver Documentación</a>
        </div>
    )
}

export default Installation;