/* eslint-disable max-len */
/* eslint-disable no-lonely-if */
/* eslint-disable no-loop-func */
/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
/* eslint-disable react/sort-comp */
/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    Row, Col, Spinner,
} from 'reactstrap';
import { StateContext } from '../../State';
import Input from '../../Components/Input/Input';
import Button from '../../Components/Button/Button';
import Select from '../../Components/Select/Select';
import { updateBoxes } from '../../Service/Api';
import sidelockerRef from '../../assets/img/ref.png';

import './BoxForm.scss';

class BoxForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            terminals: [],
            terminal: '-1',
            boxes: [],
            types: [],
            terminalData: null,
            driveboard: 0,
            loading: false,
            showImage: false,
        };
    }

    componentDidMount() {
        const { data, types } = this.props;
        const terminals = data.map((item, i) => ({
            id: i,
            name: i === 0 ? 'Terminal' : `SideLocker ${i}`,
            boxes: item,
            TerminalNo: item[0].TerminalNo,
        }));

        const formatedTypes = types.map((item) => {
            item.name = item.BoxTypeName;
            item.id = item.BoxType;
            return item;
        });
        this.setState({ terminals, types: formatedTypes });
    }

    onChange = ({ target }) => {
        const { terminals, terminal } = this.state;
        const { name, value } = target;
        let check = false;

        if (name === 'terminal') {
            const t = terminals.find((item) => parseInt(item.id) === parseInt(value));
            const { boxes } = t;
            this.setState({
                [name]: value,
                terminalData: t,
                driveboard: boxes.length > 0 ? boxes[0].DeskNo : 0,
                boxes,
            });
            check = true;
        }

        if (name.indexOf('box') === 0) {
            const split = name.split('.');
            terminals[terminal].boxes.forEach((item) => {
                if (item.BoxNo === split[1]) {
                    item.BoxType = value;
                }
            });

            this.setState({ terminals });
            check = true;
        }

        if (!check) {
            this.setState({
                [name]: value,
            });
        }
    }

    createNewBox = () => {
        const {
            boxes, driveboard, terminal, terminals,
        } = this.state;

        let allBoxes = [];

        terminals.forEach((item) => {
            allBoxes = [...allBoxes, ...item.boxes];
        });

        let iterator = 1;

        for (let i = 0; i < allBoxes.length; i += 1) {
            if (`${allBoxes[0].TerminalNo}-${iterator}` === allBoxes[i].BoxNo) {
                iterator += 1;
                i = 0;
            }
        }

        const name = `${allBoxes[0].TerminalNo}-${iterator}`;

        let deskBoxNo = 1;

        for (let i = 0; i < boxes.length; i += 1) {
            if (deskBoxNo === boxes[i].DeskBoxNo) {
                deskBoxNo += 1;
                i = 0;
            }
        }

        const box = {
            BoxName: `chica-${iterator}`,
            BoxNo: name,
            BoxStatus: '0',
            BoxType: '0',
            DeskBoxNo: deskBoxNo,
            DeskNo: driveboard,
            TerminalNo: allBoxes[0].TerminalNo,
        };

        boxes.push(box);

        const bx = boxes.sort((a, b) => {
            if (a.DeskBoxNo > b.DeskBoxNo) {
                return 1;
            }
            if (a.DeskBoxNo < b.DeskBoxNo) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });

        terminals[terminal].boxes = boxes;

        this.setState({ boxes: bx, terminals });
    }

    deleteBox = (value) => {
        const {
            boxes, terminalData, terminals, terminal,
        } = this.state;
        boxes.forEach((item, i) => {
            if (item.BoxNo === value) {
                const filter = boxes.filter((box) => box.DeskNo === item.DeskNo);
                if (terminalData.name.indexOf('Terminal') !== -1) {
                    if (filter.length === 1) {
                        alert('Un terminal debe tener al menos 1 caja');
                    } else {
                        boxes.splice(i, 1);
                    }
                } else {
                    if (filter.length === 1) {
                        let indice = null;
                        terminals.forEach((t, index) => {
                            if (parseInt(t.id) === parseInt(terminal)) {
                                indice = index;
                            }
                        });
                        terminals.splice(indice, 1);
                        this.setState({ terminal: '-1', boxes: [], terminalData: null });
                    } else {
                        boxes.splice(i, 1);
                    }
                }
            }
        });

        this.setState({ boxes });
    }

    saveChanges = () => {
        const { terminals } = this.state;
        const { callback } = this.props;
        let boxes = [];
        this.setState({ loading: true });
        terminals.forEach((item) => {
            boxes = [...boxes, ...item.boxes];
        });

        updateBoxes({ boxes }).then(() => {
            this.setState({ loading: false });
            callback();
        });
    }

    createNewSideLocker = () => {
        const { terminals } = this.state;
        const sidelocker = {
            id: terminals.length,
            name: terminals.length === 0 ? 'Terminal' : `SideLocker ${terminals.length}`,
            boxes: [],
            TerminalNo: terminals[0].boxes[0].TerminalNo,
        };

        terminals.push(sidelocker);


        this.setState({
            driveboard: sidelocker.id.toString(), terminals, terminalData: sidelocker, terminal: sidelocker.id.toString(), boxes: [],
        }, () => {
            this.createNewBox();
        });
    }

    updateDriveboard = () => {
        const { terminals, terminal, driveboard } = this.state;
        terminals[terminal].boxes.forEach((box) => {
            box.DeskNo = driveboard;
        });

        this.setState({
            terminals,
        }, () => {
            this.saveChanges();
        });
    }

    toogleImg = () => {
        const { showImage } = this.state;
        this.setState({ showImage: !showImage });
    }

    render() {
        const {
            errors, terminals, terminal, boxes, types, terminalData, driveboard, loading, showImage,
        } = this.state;


        return (
            <>
                <Row className="justify-content-end">
                    <Col md={4}>
                        <Button text={showImage ? 'Ocultar Referencia' : 'Mostrar Referencia'} onClick={this.toogleImg} />
                    </Col>
                    <Col md={4}>
                        <Button text="Crear nuevo Sidelocker" onClick={this.createNewSideLocker} />
                    </Col>
                </Row>
                <Row className={showImage ? 'sidelocker-img my-3 open' : 'sidelocker-img my-3'}>
                    <Col md={12} className="d-flex justify-content-center">
                        <img src={sidelockerRef} alt="sidelocker" />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Select label="Terminal y Side Locker" value={terminal} options={terminals} placeholder="Seleccione..." name="terminal" onChange={this.onChange} errors={errors} required />
                    </Col>

                </Row>

                <hr />
                {terminalData && (
                    <>
                        <Row>
                            <Col md={12}>
                                <h5>Driveboard</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Input label="DriveBoard" value={driveboard} name="driveboard" onChange={this.onChange} icon="fas fa-cog" readOnly={parseInt(driveboard) === 0} />
                            </Col>
                        </Row>
                        {driveboard !== 0 && (
                            <Row className="justify-content-end">
                                <Col md={4}>
                                    <Button text="Guardar Driveboard" onClick={this.updateDriveboard} />
                                </Col>
                            </Row>
                        )}

                        <hr />
                        <Row>
                            <Col md={12}>
                                <h5>Cajas</h5>
                            </Col>
                        </Row>
                        <Row className="justify-content-end">
                            <Col md={4}>
                                <Button text="Crear nueva caja" onClick={this.createNewBox} />
                            </Col>
                        </Row>
                        <Row>

                            {
                                boxes.map((item) => (
                                    <Fragment key={item.BoxNo}>
                                        <Col md={5}>
                                            <Select label={`Box ${item.DeskBoxNo}`} value={item.BoxType} options={types} placeholder="Seleccione..." name={`box.${item.BoxNo}`} onChange={this.onChange} errors={errors} required />
                                        </Col>
                                        <Col className="d-flex justify-content-center align-items-center" style={{ borderRight: '1px solid #e3e3e3' }} md={1}>
                                            <button className="modal-edit-button" style={{ textAlign: 'center', width: '100%' }} onClick={() => this.deleteBox(item.BoxNo)} type="button">
                                                <i className="fas fa-trash" />
                                            </button>
                                        </Col>
                                    </Fragment>
                                ))
                            }
                        </Row>

                    </>
                )}
                <Row className="mt-3 justify-content-end">
                    <Col md={2} className="d-flex justify-content-end">
                        {loading && <div className="spinner"><Spinner /></div>}
                    </Col>
                    <Col md={3}>
                        <Button text="Guardar" onClick={this.saveChanges} disabled={loading} />
                    </Col>
                </Row>

            </>
        );
    }
}

BoxForm.propTypes = {
    callback: PropTypes.func,
    data: PropTypes.any,
};

BoxForm.defaultProps = {
    callback: null,
    data: null,
};

BoxForm.contextType = StateContext;

export default BoxForm;
