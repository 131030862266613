import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import IMask from 'imask';
import './Input.scss';

const Input = (props) => {
    const {
        label, icon, errors, type, name, value, onChange, placeholder,
        readOnly, hideReadOnly, required, onKeyPress, onBlur, onFocus, id, mask, disabled,
        checkboxLabel
    } = props;
    const warning = (errors[name] && errors[name].length > 0);
    const inputRef = useRef(null);
    const maskRut = {
        mask: [
            {
                mask: '0.000.000-*',
            },
            {
                mask: '00.000.000-*',
            },
        ],
        overwrite: true,
    };
    const maskPhone = {
        mask: '(000) 0000 0000',
    };

    useEffect(() => {
        if (mask === 'rut') {
            IMask(inputRef.current, maskRut);
        }

        if (mask === 'phone') {
            IMask(inputRef.current, maskPhone);
        }
    }, []);

    const onKeyUp = (e) => {
        onChange(e);
    };

    if (hideReadOnly && readOnly) {
        return null;
    }

    if (readOnly) {
        return (
            <div className="read-only-container">
                <div className="label">{label}</div>
                <div className="content">
                    {icon && <i className={icon} />}
                    <div className={`read-only-value ${icon && 'padding-icon'}`}>{value}</div>
                </div>
            </div>
        );
    }


    return (
        <>
            {label}
            {required ? ' *' : ''}
            <div className="input-container">
                {icon && <i className={icon} />}
                <input
                    className={`${warning && 'border-error'} ${icon && 'padding-icon'}`}
                    type={type}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                    onKeyUp={onKeyUp}
                    placeholder={placeholder || label}
                    autoComplete="new-password"
                    onBlur={onBlur}
                    onFocus={onFocus}
                    id={id}
                    ref={inputRef}
                    disabled={disabled}
                />
                {checkboxLabel && <label className="custom-control-label" for={id}>{checkboxLabel}</label>}
                {warning && <div className="warning">{errors[name]}</div>}
            </div>
        </>
    );
};

Input.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.string,
    errors: PropTypes.object,
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.node,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    hideReadOnly: PropTypes.bool,
    required: PropTypes.bool,
    onKeyPress: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    id: PropTypes.any,
    mask: PropTypes.any,
};

Input.defaultProps = {
    label: '',
    icon: null,
    errors: {},
    type: 'text',
    value: '',
    placeholder: '',
    readOnly: false,
    hideReadOnly: false,
    required: false,
    onKeyPress: null,
    onBlur: null,
    onFocus: null,
    id: null,
    mask: null,
};

export default Input;
