/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { v4 } from 'uuid';
import './Uploader.scss';

class UploadImage extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        const {
            data, onChange, handleRemove, label, noRemove, noMultiple, errors, name,
        } = this.props;
        return (
            <>
                <div className={errors && errors[name] && errors[name].length > 0 ? 'container-uploader border border-danger d-flex p-3' : 'container-uploader border d-flex p-3'}>
                    {data && data.map((image) => (
                        <div className="container-uploader__image" key={v4()}>
                            <img src={image.src} alt="uploader" />
                            {!noRemove && <i className="bi bi-x-circle-fill" onClick={() => handleRemove(image.id)} />}
                            <input type="file" onChange={onChange} data-reference={image.id} name={name} />
                        </div>
                    ))}
                    <div className="container-uploader__empty">
                        {label || 'Elija una Imagen'}
                        <input type="file" onChange={onChange} multiple={!noMultiple} name={name} />
                    </div>
                </div>
                {errors && errors[name] && errors[name].length > 0 && (
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {errors[name].map((error) => (
                            <div className="invalid" key={v4()}>
                                {error}
                            </div>
                        ))}
                    </div>
                )}
            </>
        );
    }
}

export default UploadImage;
