/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';
import TopBar from '../../Components/TopBar/TopBar';
import UserForm from './TerminalForm';
import BoxForm from './BoxForm';
import { StateContext } from '../../State';
import ModalView from '../../Layout/ModalView/ModalView';
import { tableConfig } from '../../config';
import { ConfirmDialog } from '../../Components/Dialog/Dialog';
import { formatRut } from '../../Service/Utils';
import { getTerminals, deleteTerminal, restoreUser } from '../../Service/Api';

class Terminal extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            showConfirm: false,
            data: [],
            loading: false,
            deleteId: null,
            restoreId: null,
        };
        this.handleRemove = this.handleRemove.bind(this);
        this.handleRestore = this.handleRestore.bind(this);
        this.loadData = this.loadData.bind(this);
        this.findData = this.findData.bind(this);
        this.removeUser = this.removeUser.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    findData = (id) => {
        const { data } = this.state;
        return data.find((item) => item.TerminalNo.toString() === id.toString());
    }

    handleError = (error) => {
        const { status } = error.response;
        if (status === 401 || status === 403) {
            const [, dispatch] = this.context;
            dispatch({
                type: 'EXIT',
            });
        }
    }

    async loadData() {
        this.setState({
            loading: true,
        });

        await getTerminals()
            .then((response) => {
                if (this._isMounted) {
                    this.setState({
                        data: this.handleNewFields(response.data),
                        loading: false,
                        deleteId: null,
                        restoreId: null,
                        showConfirm: false,
                    });
                }
            }).catch((error) => {
                this.handleError(error);
            });
    }

    async removeUser() {
        const { deleteId } = this.state;
        await deleteTerminal(deleteId).then((response) => {
            if (response && response.status === 200) {
                this.setState({ showConfirm: false });
                this.loadData();
            }
        }).catch((error) => {
            this.handleError(error);
        });
    }

    async restoreUser() {
        const { restoreId } = this.state;
        await restoreUser(restoreId).then((response) => {
            if (response && response.status === 200) {
                this.loadData();
            }
        }).catch((error) => {
            this.handleError(error);
        });
    }

    handleNewFields = (terminals) => {
        terminals.forEach((item) => {
            const t = [];
            Object.keys(item.terminals).forEach((key) => {
                t.push(item.terminals[key]);
            });
            item.terminals = t;
            item.box_length = item.boxes.length;
            item.DepartmentName = item.department[0].DepartmentName;
        });
        return terminals;
    }

    handleRemove(id) {
        this.setState({
            showConfirm: true,
            deleteId: id,
        });
    }

    handleRestore(id) {
        this.setState({
            showConfirm: true,
            restoreId: id,
        });
    }

    render() {
        const {
            showConfirm, data, loading, deleteId,
        } = this.state;
        const columns = [
            {
                Header: 'Nombre',
                accessor: 'TerminalName',
                Cell: (row) => (
                    <ModalView title={row.original.TerminalName}>
                        <UserForm data={this.findData(row.original.TerminalNo)} readOnly />
                    </ModalView>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['TerminalName'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Terminales',
                accessor: (row) => (row.terminals.length),
                id: 'terminales',
                Cell: (row) => (
                    <div style={{ textAlign: 'center', width: '100%' }}>{row.original.terminals.length}</div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['terminales'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Boxes',
                accessor: 'box_length',
                maxWidth: 50,
                Cell: (row) => (
                    <ModalView size="lg" title={row.original.box_length.toString()} callback={this.loadData}>
                        <BoxForm data={row.original.terminals} types={row.original.boxType} readOnly />
                    </ModalView>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['box_length'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Usuario',
                accessor: 'Username',
                id: 'Username',
                Cell: (row) => (
                    <span>{row.original.Username}</span>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['Username'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Direccion',
                accessor: 'Location',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['Location'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Service Station',
                accessor: 'DepartmentName',
                Cell: (row) => (
                    <span>{formatRut(row.original.DepartmentName)}</span>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['DepartmentName'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Acciones',
                id: 'actions',
                filterable: false,
                sortable: false,
                maxWidth: 100,
                Cell: (row) => (
                    <div className="form-actions">
                        <ModalView title="Editar Terminal" type="edit" callback={this.loadData}>
                            <UserForm data={this.findData(row.original.TerminalNo)} />
                        </ModalView>
                        <ModalView size="lg" title={row.original.box_length.toString()} type="box" callback={this.loadData}>
                            <BoxForm data={row.original.terminals} types={row.original.boxType} readOnly />
                        </ModalView>
                    </div>
                ),
            },
        ];

        return (
            <>
                <TopBar>
                    <ModalView title="Crear Terminal" type="add" callback={this.loadData}>
                        <UserForm />
                    </ModalView>
                </TopBar>

                <ReactTable
                    data={data}
                    columns={columns}
                    {...tableConfig}
                    loading={loading}
                />

                <ConfirmDialog
                    show={showConfirm}
                    title={deleteId ? 'Eliminar usuario' : 'Restablecer usuario'}
                    onConfirm={this.removeUser}
                    onCancel={() => {
                        this.setState({ showConfirm: false });
                    }}
                />
            </>
        );
    }
}

Terminal.contextType = StateContext;

export default Terminal;
