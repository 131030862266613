/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-param-reassign */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';
import selectTableHOC from 'react-table/lib/hoc/selectTable';
import TopBar from '../../Components/TopBar/TopBar';
import UserForm from './CustomerForm';
import { StateContext } from '../../State';
import ModalView from '../../Layout/ModalView/ModalView';
import { tableConfig } from '../../config';
import { ConfirmDialog } from '../../Components/Dialog/Dialog';
import {
    deleteCustomer, restoreUser, getAllCustomers, deleteCustomers,
} from '../../Service/Api';
import DownloadCSV from '../../Components/DownloadCSVButton/DownloadCSVButton';
import csvExample from './csvExample';
import UploadForm from './UploadForm';

const SelectTable = selectTableHOC(ReactTable);


class Customer extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            showConfirm: false,
            data: [],
            loading: false,
            deleteId: null,
            restoreId: null,
            selection: [],
            selectAll: false,
            showConfirmMany: false,
        };
        this.handleRemove = this.handleRemove.bind(this);
        this.handleRestore = this.handleRestore.bind(this);
        this.loadData = this.loadData.bind(this);
        this.findData = this.findData.bind(this);
        this.removeUser = this.removeUser.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    findData = (id) => {
        const { data } = this.state;
        return data.find((item) => item.CustomerID.toString() === id.toString());
    }

    handleError = (error) => {
        const { status } = error.response;
        if (status === 401 || status === 403) {
            const [, dispatch] = this.context;
            dispatch({
                type: 'EXIT',
            });
        }
    }

    async loadData() {
        this.setState({
            loading: true,
        });

        await getAllCustomers()
            .then((response) => {
                this.setState({
                    data: response.data,
                    loading: false,
                    deleteId: null,
                    restoreId: null,
                });
            }).catch((error) => {
                this.handleError(error);
            });
    }

    async removeUser() {
        const { deleteId } = this.state;
        await deleteCustomer(deleteId).then((response) => {
            if (response && response.status === 200) {
                this.loadData();
            }
        }).catch((error) => {
            this.handleError(error);
        });
    }

    async restoreUser() {
        const { restoreId } = this.state;
        await restoreUser(restoreId).then((response) => {
            if (response && response.status === 200) {
                this.loadData();
            }
        }).catch((error) => {
            this.handleError(error);
        });
    }

    handleNewFields = (terminals) => {
        terminals.forEach((item) => {
            item.box_length = item.boxes.length;
            item.DepartmentName = item.department[0].DepartmentName;
        });
        return terminals;
    }

    handleRemove(id) {
        this.setState({
            showConfirm: true,
            deleteId: id,
        });
    }

    handleRestore(id) {
        this.setState({
            showConfirm: true,
            restoreId: id,
        });
    }

    handleStatus = (status) => {
        switch (parseInt(status)) {
            case 1:
                return 'Retirado';
            case 4:
                return 'Aguardando Courier';
            default:
                return 'En el Locker';
        }
    }

    isSelected = (key) => this.state.selection.includes(`select-${key}`);

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);

        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1),
            ];
        } else {
            selection.push(key);
        }
        this.setState({ selection });
    };

    toggleAll = () => {
        const selectAll = !this.state.selectAll;
        const selection = [];

        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach((item) => {
                selection.push(`select-${item._original.CustomerID}`);
            });
        }
        this.setState({ selectAll, selection });
    };

    handleDeleteSelected = () => {
        const [, dispatch] = this.context;
        dispatch({
            type: 'SET_LOADING',
            value: true,
        });
        const ids = [];
        this.state.selection.forEach((item) => {
            const [, id] = item.split('-');
            if (id) {
                ids.push(id);
            }
        });

        deleteCustomers(ids).then((response) => {
            this.loadData();

            dispatch({
                type: 'SET_LOADING',
                value: false,
            });
            this.setState({ showConfirmMany: false });
        }).catch(() => {
            dispatch({
                type: 'SET_LOADING',
                value: false,
            });
            this.setState({ showConfirmMany: false });
        });
    }

    render() {
        const {
            showConfirm, showConfirmMany, data, loading, deleteId,
        } = this.state;
        const columns = [
            {
                Header: 'ID',
                accessor: (row) => row.CustomerID,
                id: 'id',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        {row.original.CustomerID}
                    </div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['id'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Nombre',
                accessor: (row) => row.CustomerName,
                id: 'name',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'left', paddingLeft: '25px' }}>
                        {row.original.CustomerName}
                    </div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['name'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Dirección',
                accessor: (row) => row.Address,
                id: 'address',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'left', paddingLeft: '25px' }}>
                        {row.original.Address}
                    </div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['address'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Service Station',
                accessor: (row) => (row.delivery_service_user[0].department !== null ? row.delivery_service_user[0].department.DepartmentName : ''),
                id: 'delivery_service_user[0]',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'left', paddingLeft: '25px' }}>
                        {row.original.delivery_service_user[0].department !== null ? row.original.delivery_service_user[0].department.DepartmentName : ''}
                    </div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['delivery_service_user[0]'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Email',
                accessor: (row) => row.Email,
                id: 'contact',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'left', paddingLeft: '25px' }}>
                        {row.original.Email}
                    </div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['contact'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Telefono',
                accessor: (row) => row.CustomerMobile,
                id: 'phone',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'left', paddingLeft: '25px' }}>
                        {row.original.CustomerMobile}
                    </div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['phone'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Acciones',
                id: 'actions',
                filterable: false,
                sortable: false,
                maxWidth: 100,
                Cell: (row) => (
                    <div className="form-actions">
                        <ModalView title="Editar Residente" type="edit" callback={this.loadData}>
                            <UserForm data={this.findData(row.original.CustomerID)} />
                        </ModalView>
                        <button onClick={() => { this.handleRemove(row.original.CustomerID); }} type="button">
                            <i className="fas fa-trash" />
                        </button>
                    </div>
                ),
            },
        ];

        return (
            <>
                <TopBar>
                    <div className="w-100 d-flex justify-content-between align-items-center mb-2">
                        <div className="d-flex" style={{ color: '#666 !important' }}>
                            <div className="d-flex mr-5" style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <DownloadCSV data={csvExample} filename="model.csv" />
                                Descargar Base CSV
                            </div>

                            <div
                                className="d-flex mr-5"
                                style={{
                                    flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '30px', color: '#666 !important',
                                }}
                            >
                                <ModalView size="lg" title="Carga masiva" type="upload" callback={this.loadData}>
                                    <UploadForm />
                                </ModalView>
                                <span style={{ fontSize: '14px' }}>Carga Masiva</span>
                            </div>

                            <div
                                className="d-flex mr-5"
                                style={{
                                    flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer',
                                }}
                                onClick={() => { this.setState({ showConfirmMany: true }); }}
                            >
                                <i className="fas fa-trash" style={{ fontSize: '30px', padding: '9px' }} />
                                Eliminar Seleccionados
                            </div>
                        </div>

                        <ModalView title="Crear Residente" type="add" callback={this.loadData}>
                            <UserForm />
                        </ModalView>
                    </div>

                </TopBar>

                <SelectTable
                    ref={(r) => (this.checkboxTable = r)}
                    data={data}
                    columns={columns}
                    {...tableConfig}
                    loading={loading}
                    selectType="checkbox"
                    keyField="CustomerID"
                    toggleSelection={this.toggleSelection}
                    selectAll={this.state.selectAll}
                    toggleAll={this.toggleAll}
                    isSelected={this.isSelected}
                />

                <ConfirmDialog
                    show={showConfirm}
                    title={deleteId ? 'Eliminar Residente' : 'Restablecer usuario'}
                    onConfirm={() => {
                        if (deleteId) {
                            this.removeUser();
                        }
                        this.setState({ showConfirm: false });
                    }}
                    onCancel={() => {
                        this.setState({ showConfirm: false });
                    }}
                />

                <ConfirmDialog
                    show={showConfirmMany}
                    title="Eliminar Residentes"
                    onConfirm={() => {
                        this.handleDeleteSelected();
                    }}
                    onCancel={() => {
                        this.setState({ showConfirmMany: false });
                    }}
                />
            </>
        );
    }
}

Customer.contextType = StateContext;

export default Customer;
