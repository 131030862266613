import React from 'react';

import folder from '../../assets/img/folder.svg'
import './Folder.scss'

const Folder = (props) => {
    const { history, url, label, icon } = props;

    return (
        <div className="folder" onClick={() => history.push(url)}>
            <img src={folder} alt="folder" />

            {label}

            {icon && (
                <i className={icon} />
            )}
        </div>
    )
}

export default Folder;