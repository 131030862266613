/* eslint-disable react/prop-types */
/* eslint-disable no-case-declarations */
import React, { useContext, useEffect, useState } from 'react';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Button from '../../Components/Button/Button';
import Uploader from '../../Components/Uploader/Uploader';
import { uploadCsv, getDepartments } from '../../Service/Api';
import { toBase64 } from '../../Service/Utils';
import Select from '../../Components/Select/Select';

import { StateContext } from '../../State';

const UploadForm = (props) => {
    const { callback } = props;
    const [text, setText] = useState(undefined);
    const [csvFile, setCsvFile] = useState(undefined);
    const [errors, setErrors] = useState({});
    const [disabled, setDisabled] = useState(true);
    const [profiles, setProfiles] = useState([]);
    const [DepartmentID, setDepartmentID] = useState('-1');
    const context = useContext(StateContext);

    const formatDbDate = (data) => {
        const departments = [];
        data.forEach((item) => {
            departments.push({
                id: item.DepartmentID,
                name: item.DepartmentName,
            });
        });
        return departments;
    };

    const handleError = (error) => {
        const { status } = error.response;
        if (status === 401 || status === 403) {
            const [, dispatch] = context;
            dispatch({
                type: 'EXIT',
            });
        }
    };

    useEffect(() => {
        getDepartments()
            .then((response) => {
                setProfiles(formatDbDate(response.data));
            })
            .catch((error) => {
                handleError(error);
            });
    }, []);

    const onChange = (event) => {
        const { target } = event;
        const { name, value } = target;
        switch (name) {
            case 'csv':
                setDisabled(true);
                const e = { ...errors };
                delete e.file;
                setErrors(e);
                if (target.files.length) {
                    if (target.files[0].type === 'text/csv' || target.files[0].type === 'application/vnd.ms-excel') {
                        setText(target.files[0].name);
                        toBase64(target.files[0]).then((response) => {
                            setCsvFile(response);
                            setDisabled(false);
                        });
                    } else {
                        setErrors({ ...errors, ...{ file: 'Formato de archivo inválido' } });
                    }
                } else {
                    setText(undefined);
                    setCsvFile(undefined);
                }
                break;
            default:
                setDepartmentID(value);
        }
    };

    const handleSubmit = () => {
        const [, dispatch] = context;
        dispatch({
            type: 'SET_LOADING',
            value: true,
        });
        uploadCsv({ csvFile, DepartmentID }).then(() => {
            dispatch({
                type: 'SET_LOADING',
                value: false,
            });
            callback();
        }).catch(() => {
            dispatch({
                type: 'SET_LOADING',
                value: false,
            });
            callback();
        });
    };

    return (
        <Row>
            <Col md={12}>
                <Uploader name="csv" onChange={onChange} text={text} />
            </Col>
            <Col md={12}><Select label="Service Station" value={DepartmentID} options={profiles} placeholder="Seleccione..." name="DepartmentID" onChange={onChange} readOnly={false} errors={errors} required /></Col>
            <Col md={12}>
                <Button text="Guardar" onClick={handleSubmit} disabled={disabled || DepartmentID === '-1'} />
            </Col>
            <Col md={12}>
                {Object.keys(errors).map((item) => (
                    <Row key={item} className="justify-content-center">
                        <Col className="alert alert-danger my-3" md={8}>
                            {errors[item]}
                        </Col>
                    </Row>
                ))}
            </Col>
        </Row>
    );
};

export default UploadForm;
