/* eslint-disable no-alert */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';
import moment from 'moment';
import { debounce, filter } from 'lodash';
import TopBar from '../../Components/TopBar/TopBar';
import UserForm from './HistoryForm';
import { StateContext } from '../../State';
import ModalView from '../../Layout/ModalView/ModalView';
import { tableConfig } from '../../config';
import { ConfirmDialog } from '../../Components/Dialog/Dialog';
import {
    deleteUser, restoreUser, post,
} from '../../Service/Api';
import { checkString } from '../../Service/Utils';
import FilterForm from './FilterForm';
import DownloadCsv from '../../Components/DownloadCsv';
import { createCsv, createFieldsData } from '../../helpers/csv_generator';
import 'moment/dist/locale/es';

moment.locale('es');

class Packages extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            showConfirm: false,
            data: [],
            loading: false,
            loadingCsv: false,
            deleteId: null,
            restoreId: null,
            showOrderNumber: false,
            table: {
                pages: 0,
                page: 0,
                page_url: `${process.env.REACT_APP_API_URL}/get-delivery-history`,
                params: {
                    filters: [
                        { id: 'StoredDateStart', value: moment().subtract(30, 'days').format('YYYY-MM-DD') },
                        { id: 'StoredDateEnd', value: moment().format('YYYY-MM-DD') },
                    ],
                },

            },
            showDirection: true,
        };
        this.handleRemove = this.handleRemove.bind(this);
        this.handleRestore = this.handleRestore.bind(this);
        this.findData = this.findData.bind(this);
        this.removeUser = this.removeUser.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        const [state] = this.context;
        const { user } = state;
        const { features } = user;

        if (features && features.find((feat) => feat.title === 'Agregar Pedido Multi Caja')) {
            this.setState({ showDirection: false });
        }

        if (features) {
            const findFeature = features.find((item) => item.title === 'Agregar Pedido');
            if (findFeature) {
                this.setState({ showOrderNumber: true });
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    findData = (id) => {
        const { data } = this.state;
        const customer = data.find((item) => item.customer.CustomerID.toString() === id.toString());
        return customer;
    }

    handleError = (error) => {
        const { status } = error.response;
        if (status === 401 || status === 403) {
            const [, dispatch] = this.context;
            dispatch({
                type: 'EXIT',
            });
        }
    }

    async removeUser() {
        const { deleteId } = this.state;
        await deleteUser(deleteId).then((response) => {
            if (response && response.status === 200) {
                this.loadData();
            }
        }).catch((error) => {
            this.handleError(error);
        });
    }

    async restoreUser() {
        const { restoreId } = this.state;
        await restoreUser(restoreId).then((response) => {
            if (response && response.status === 200) {
                this.loadData();
            }
        }).catch((error) => {
            this.handleError(error);
        });
    }

    handleNewFields = (terminals) => {
        terminals.forEach((item) => {
            item.box_length = item.boxes.length;
            item.DepartmentName = item.department[0].DepartmentName;
        });
        return terminals;
    }

    handleRemove(id) {
        this.setState({
            showConfirm: true,
            deleteId: id,
        });
    }

    handleRestore(id) {
        this.setState({
            showConfirm: true,
            restoreId: id,
        });
    }

    handleStatus = (status) => {
        switch (parseInt(status)) {
            case 1:
                return 'Retirado';
            case 4:
                return 'Aguardando Courier';
            default:
                return 'En el Locker';
        }
    }

    filterFunction = (rows, filter, props) => {
        const r = [];
        rows.forEach((item) => {
            if (props === 'StoredDate') {
                if (checkString(filter.value, item[props].format('DD-MM-YYYY')) !== -1) {
                    r.push(item);
                }
            }
        });
        return r;
    }

    filterTableData = (filters, download = false) => {
        const { table } = this.state;
        const url = `${table.page_url}?page=${table.page + 1}`;
        const params = {};
        params.filters = filters;
        params.sorted = table.sorted;
        params.per_page = table.params.per_page;
        params.download = download;
        this.setState({ loading: true });
        post(url, params).then((response) => {
            const registers = [];
            let register = {};
            if (typeof response.data.data === 'object') {
                Object.keys(response.data.data).forEach((key) => {
                    register = response.data.data[key];
                    register.StoredTime = moment(`${register.StoredTime.substring(0, 19)}Z`).format('YYYY-MM-DD HH:mm:ss');
                    register.TakedTime = register.TakedTime ? moment(`${register.TakedTime.substring(0, 19)}Z`).format('YYYY-MM-DD HH:mm:ss') : null;
                    registers.push(register);
                });
            } else {
                response.data.forEach((item) => {
                    register = item;
                    register.StoredTime = moment(`${register.StoredTime.substring(0, 19)}Z`).format('YYYY-MM-DD HH:mm:ss');
                    register.TakedTime = register.TakedTime ? moment(`${register.TakedTime.substring(0, 19)}Z`).format('YYYY-MM-DD HH:mm:ss') : null;
                    registers.push(register);
                });
            }

            if (download) {
                const headers = [
                    'CustomerName',
                    'PackageID',
                    'StoredDate',
                    'StoredTime',
                    'TakedTime',
                    'BlankBoxKey',
                    'box_number',
                    'TerminalName',
                    'Location',
                    'PackageStatus',
                    'PackageStatusText',
                    'order_number',
                ];
                const data = createFieldsData(headers, response.data);
                createCsv({
                    data,
                    headers,
                }, `historico-generado-${moment().format('DD-MM-YYYY-HH-mm-ss')}`);
                this.setState({ loading: false });
            } else {
                this.setState({
                    data: registers,
                    loading: false,
                    deleteId: null,
                    restoreId: null,
                    table: {
                        ...table,
                        page: response.data.current_page - 1,
                        pages: response.data.last_page,
                        params,
                    },
                });
            }
        });
    }

    getTableData = (state) => {
        const { table } = this.state;
        this.setState({ loading: true });
        if (table.page_url) {
            const url = `${table.page_url}?page=${state.page + 1}`;
            const params = {};
            params.filters = table.params.filters || [];
            params.sorted = state.sorted;
            params.per_page = state.pageSize;
            post(url, params).then((response) => {
                const registers = [];
                let register = {};
                if (typeof response.data.data === 'object') {
                    Object.keys(response.data.data).forEach((key) => {
                        register = response.data.data[key];
                        register.StoredTime = moment(`${register.StoredTime.substring(0, 19)}Z`).format('YYYY-MM-DD HH:mm:ss');
                        register.TakedTime = register.TakedTime ? moment(`${register.TakedTime.substring(0, 19)}Z`).format('YYYY-MM-DD HH:mm:ss') : null;
                        registers.push(register);
                    });
                } else {
                    response.data.data.forEach((item) => {
                        register = item;
                        register.StoredTime = moment(`${register.StoredTime.substring(0, 19)}Z`).format('YYYY-MM-DD HH:mm:ss');
                        register.TakedTime = register.TakedTime ? moment(`${register.TakedTime.substring(0, 19)}Z`).format('YYYY-MM-DD HH:mm:ss') : null;
                        registers.push(register);
                    });
                }

                this.setState({
                    data: registers,
                    loading: false,
                    deleteId: null,
                    restoreId: null,
                    table: {
                        ...table,
                        page: response.data.current_page - 1,
                        pages: response.data.last_page,
                        params,
                    },
                });
            });
        }
    }

    downloadCsv = () => {
        let filters = [];
        const isStartDateSet = this.state.table.params.filters.find((filter) => filter.id === 'StoredDateStart') || false;
        const isEndDateSet = this.state.table.params.filters.find((filter) => filter.id === 'StoredDateEnd') || false;
        if (this.state.table.params.filters && isStartDateSet && isEndDateSet) {
            const start = moment(isStartDateSet.value);
            const end = moment(isEndDateSet.value);
            const diff = end.diff(start, 'days');
            if (diff > 31) {
                alert('El número máximo de días permitido es de 31 días.');
                return;
            }
            filters = this.state.table.params.filters;
        } else {
            const storedDateStartId = this.state.table.params.filters.findIndex((filter) => filter.id === 'StoredDateStart');
            const storedDateEndId = this.state.table.params.filters.findIndex((filter) => filter.id === 'StoredDateEnd');

            if (storedDateStartId === -1) {
                filters.push({ id: 'StoredDateStart', value: moment().subtract(30, 'days').format('YYYY-MM-DD') });
            }

            if (storedDateEndId === -1) {
                filters.push({ id: 'StoredDateEnd', value: moment().format('YYYY-MM-DD') });
            }
            filters = [
                ...filters,
                ...this.state.table.params.filters,
            ];
        }
        this.filterTableData(filters, true);
    }

    debounceEvent = debounce((e) => {
        this.getTableData(e);
    }, 300)

    render() {
        const {
            showConfirm, data, loading, loadingCsv, deleteId, restoreId, table,
        } = this.state;
        const columns = [
            {
                Header: 'Customer',
                accessor: (row) => row.CustomerName,
                id: 'CustomerName',
                Cell: (row) => (
                    <ModalView title={row.original.CustomerName}>
                        <UserForm data={this.findData(row.original.customer.CustomerID)} readOnly />
                    </ModalView>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['CustomerName'] }),
                filterAll: false,
                filterable: false,
            },
            {
                Header: 'Package ID',
                accessor: 'PackageID',
                Cell: (row) => row.original.PackageID,
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['PackageID'] }),
                filterAll: false,
                filterable: false,
            },
            {
                Header: 'Orden Venta',
                id: 'order_number',
                accessor: (row) => (row.order_number ? row.order_number : ''),
                Cell: (row) => (row.original.order_number ? row.original.order_number : ''),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['order_number'] }),
                filterAll: false,
                filterable: false,
                show: this.state.showOrderNumber,
            },
            {
                Header: 'Fecha Entrega',
                id: 'StoredTime',
                accessor: (row) => moment(row.StoredTime),
                Cell: (row) => <div style={{ width: '100%', textAlign: 'center' }}>{moment(row.original.StoredTime).format('DD-MM-YYYY')}</div>,
                filterMethod: (filter, rows) => this.filterFunction(rows, filter, 'StoredDate'),
                filterAll: false,
                filterable: false,
            },
            {
                Header: 'Hora Entrega',
                accessor: (row) => moment(row.StoredTime).format('HH:mm:ss'),
                id: 'StoredTime',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'center' }}>{moment(row.original.StoredTime).format('HH:mm:ss')}</div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['StoredTime'] }),
                filterAll: false,
                filterable: false,
            },
            {
                Header: 'Fecha Retiro',
                id: 'TakedTime',
                accessor: (row) => (parseInt(row.PackageStatus) === 0 ? '-' : moment(row.TakedTime).format('DD-MM-YYYY')),
                Cell: (row) => <div style={{ width: '100%', textAlign: 'center' }}>{(parseInt(row.original.PackageStatus) === 0 ? '-' : moment(row.original.TakedTime).format('DD-MM-YYYY'))}</div>,
                filterMethod: (filter, rows) => this.filterFunction(rows, filter, 'StoredDate'),
                filterAll: false,
                filterable: false,
            },
            {
                Header: 'Hora Retiro',
                id: 'TakedTime',
                accessor: (row) => (parseInt(row.PackageStatus) === 0 ? '-' : moment(row.TakedTime).format('HH:mm:ss')),
                Cell: (row) => <div style={{ width: '100%', textAlign: 'center' }}>{(parseInt(row.original.PackageStatus) === 0 ? '-' : moment(row.original.TakedTime).format('HH:mm:ss'))}</div>,
                filterMethod: (filter, rows) => this.filterFunction(rows, filter, 'StoredDate'),
                filterAll: false,
                filterable: false,
            },
            {
                Header: 'Clave',
                accessor: 'BlankBoxKey',
                Cell: (row) => (
                    <div style={{ textAlign: 'center', width: '100%' }}>{row.original.BlankBoxKey}</div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['BlankBoxKey'] }),
                filterAll: false,
                filterable: false,
            },
            {
                Header: 'Box',
                accessor: (row) => row.box_number,
                id: 'box_number',
                width: 100,
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        {row.original.box_number && Array.isArray(JSON.parse(row.original.box_number))
                            ? <>{JSON.parse(row.original.box_number).join(', ')}</>
                            : <>{row.original.box_number}</>}
                    </div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['BoxNo'] }),
                filterAll: false,
                filterable: false,
            },
            {
                Header: 'Terminal',
                accessor: (row) => row.TerminalName,
                id: 'TerminalName',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'center' }}>{row.original.TerminalName}</div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['TerminalName'] }),
                filterAll: false,
                filterable: false,
            },
            {
                Header: 'Direccion',
                accessor: (row) => row.Location,
                id: 'Location',
                Cell: (row) => (
                    <div>{row.original.Location}</div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['Location'] }),
                filterAll: false,
                filterable: false,
                show: this.state.showDirection,
            }, {
                Header: 'Departamento',
                accessor: (row) => row.Location,
                id: 'Address',
                Cell: (row) => (
                    <div>{row.original.customer.Address}</div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['Address'] }),
                filterAll: false,
                filterable: false,
                show: true,
            },
            {
                Header: 'Status',
                accessor: (row) => this.handleStatus(row.PackageStatus),
                id: 'PackageStatus',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'center' }}>{this.handleStatus(row.original.PackageStatus)}</div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['PackageStatus'] }),
                filterAll: false,
                filterable: false,
            },

        ];

        return (
            <>
                <TopBar>
                    <div className="w-100 d-flex justify-content-between align-items-center mb-2">
                        <div className="d-flex justify-content-between" style={{ color: '#666 !important', width: '100%' }}>
                            <div className="d-flex mr-5" style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <DownloadCsv handleCreateCsv={this.downloadCsv} />
                                {loadingCsv ? 'Cargando CSV Data...' : 'Descargar CSV'}
                            </div>
                            <div
                                className="d-flex mr-3"
                                style={{
                                    flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', color: '#666', fontWeight: 'bold',
                                }}
                            >
                                <div style={{ fontSize: '30px' }}>
                                    <ModalView title="Filtros" size="lg" type="filter" callback={() => console.log('callback')}>
                                        <FilterForm handleFilter={this.filterTableData} filters={this.state.table.params.filters} />
                                    </ModalView>
                                </div>
                                {this.state.table && this.state.table.params && this.state.table.params.filters && this.state.table.params.filters.length > 0 ? `Filtros (${this.state.table.params.filters.length})` : 'Filtros'}
                            </div>
                        </div>
                    </div>
                </TopBar>

                <ReactTable
                    data={data}
                    columns={columns}
                    {...tableConfig}
                    loading={loading}
                    manual
                    pages={table.pages}
                    page={table.page}
                    onFetchData={async (state, instance) => {
                        this.debounceEvent(state);
                    }}
                />

                <ConfirmDialog
                    show={showConfirm}
                    title={deleteId ? 'Eliminar usuario' : 'Restablecer usuario'}
                    onConfirm={() => {
                        if (deleteId) {
                            this.removeUser();
                        }
                        if (restoreId) {
                            this.restoreUser();
                        }
                        this.setState({ showConfirm: false });
                    }}
                    onCancel={() => {
                        this.setState({ showConfirm: false });
                    }}
                />
            </>
        );
    }
}

Packages.contextType = StateContext;

export default Packages;
