export default [
    {
        id: 1,
        title: 'Paquetes',
        path: '/admin/packages',
        icon: 'fas fa-box-open',
    },
    {
        id: 2,
        title: 'Histórico',
        path: '/admin/historic',
        icon: 'fas fa-history',
    },
    {
        id: 3,
        title: 'Reservas',
        path: '/admin/reservations',
        icon: 'fas fa-calendar-alt',
    },
    {
        id: 4,
        title: 'Api Token',
        path: '/admin/tokens',
        icon: 'fas fa-key',
    },
    {
        id: 5,
        title: 'Manuales',
        path: '/admin/ecommerce/manuals',
        icon: 'fas fa-file-pdf',
    },

];
