/* eslint-disable react/sort-comp */
/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Spinner } from 'reactstrap';
import { StateContext } from '../../State';
import Input from '../../Components/Input/Input';
import Button from '../../Components/Button/Button';
import { createTerminal, updateTerminal } from '../../Service/Api';
import {
    formatRut, unformatRut, formatPhone, unformatPhone,
} from '../../Service/Utils';

class TerminalForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createMode: true,
            data: {},
            errors: {},
            loading: false,
        };
        this.handleCreate = this.handleCreate.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onBlurRut = this.onBlurRut.bind(this);
        this.onFocusRut = this.onFocusRut.bind(this);
        this.onBlurPhone = this.onBlurPhone.bind(this);
        this.onFocusPhone = this.onFocusPhone.bind(this);
    }

    componentDidMount() {
        const { data } = this.props;

        if (data) {
            data.phone = formatPhone(data.phone);
            data.rut = formatRut(data.rut);
            this.setState({
                data,
                createMode: false,
            });
        }
    }

    formatDbDate = (data) => {
        const departments = [];
        data.forEach((item) => {
            departments.push({
                id: item.DepartmentID,
                name: item.DepartmentName,
            });
        });
        return departments;
    }

    onChange(event) {
        const { name, value } = event.target;
        const { data, errors } = this.state;
        data[name] = value;
        errors[name] = '';
        this.setState({
            data,
            errors,
        });
    }

    onBlurRut() {
        const { data } = this.state;
        data.rut = formatRut(data.rut);
        this.setState({
            data,
        });
    }

    onFocusRut() {
        const { data } = this.state;
        data.rut = unformatRut(data.rut);
        this.setState({
            data,
        });
    }

    onBlurPhone() {
        const { data } = this.state;
        data.phone = formatPhone(data.phone);
        this.setState({
            data,
        });
    }

    onFocusPhone() {
        const { data } = this.state;
        data.phone = unformatPhone(data.phone);
        this.setState({
            data,
        });
    }

    handleError = (error) => {
        const { status } = error.response;
        if (status === 401 || status === 403) {
            const [, dispatch] = this.context;
            dispatch({
                type: 'EXIT',
            });
        } else {
            this.setState({
                errors: this.formatError(error.response.data),
            });
        }
    }

    formatError = (value) => {
        const errors = [];
        Object.keys(value).forEach((keys) => {
            errors[keys] = value[keys][0];
        });
        return errors;
    }

    validForm() {
        const { data, createMode } = this.state;
        const {
            TerminalName, Username, DepartmentID, Location, Password, repeatPassword,
        } = data;
        const errors = {};
        let formIsValid = true;

        if (!TerminalName || TerminalName.trim().length === 0) {
            formIsValid = false;
            errors.TerminalName = ['Requerido'];
        }

        if (!Username || Username.trim().length === 0) {
            formIsValid = false;
            errors.Username = ['Requerido'];
        }

        if (!DepartmentID || DepartmentID.trim().length === 0) {
            formIsValid = false;
            errors.DepartmentID = ['Requerido'];
        }

        if (!Location || Location.trim().length === 0) {
            formIsValid = false;
            errors.Location = ['Requerido'];
        }


        if (createMode) {
            if (!Password || Password.trim().length === 0) {
                formIsValid = false;
                errors.Password = ['Requerido'];
            }

            if (!repeatPassword || repeatPassword.trim().length === 0) {
                formIsValid = false;
                errors.repeatPassword = ['Requerido'];
            } else if (repeatPassword !== Password) {
                formIsValid = false;
                errors.repeatPassword = ['Debe coincidir'];
            }
        }

        this.setState({
            errors,
        });

        return formIsValid;
    }

    async handleCreate() {
        if (this.validForm()) {
            this.toggleLoading(true);
            const { data } = this.state;
            data.phone = unformatPhone(data.phone);
            data.rut = unformatRut(data.rut);
            await createTerminal(data).then((response) => {
                if (response && response.status === 201) {
                    const { callback } = this.props;
                    callback();
                }
            }).catch((error) => {
                this.handleError(error);
            });
            this.toggleLoading(false);
        }
    }

    async handleUpdate() {
        if (this.validForm()) {
            this.toggleLoading(true);
            const { data } = this.state;
            await updateTerminal(data, data.TerminalNo).then((response) => {
                if (response && response.status === 200) {
                    const { callback } = this.props;
                    callback();
                }
            }).catch((error) => {
                this.handleError(error);
            });
            this.toggleLoading(false);
        }
    }

    toggleLoading(value) {
        this.setState({
            loading: value,
        });
    }

    render() {
        const { readOnly, data } = this.props;
        const {
            createMode, errors, loading,
        } = this.state;
        const { customer } = data;

        const {
            CustomerName, Address, Email, CustomerMobile,
        } = customer;

        return (
            <>
                <Row>
                    <Col md={12}><Input name="name" value={CustomerName} onChange={this.onChange} readOnly={readOnly} label="Nombre" placeholder="Nombre del Terminal" icon="fas fa-user-alt" errors={errors} required /></Col>
                    <Col md={12}><Input name="email" value={Email} onChange={this.onChange} readOnly={readOnly} label="E-mail" placeholder="Nombre del Terminal" icon="fas fa-envelope" errors={errors} required /></Col>
                    <Col md={12}><Input name="phone" value={CustomerMobile} onChange={this.onChange} readOnly={readOnly} label="Telefono" placeholder="Nombre del Terminal" icon="fas fa-phone" errors={errors} required /></Col>
                    <Col md={12}><Input name="address" value={Address} onChange={this.onChange} readOnly={readOnly} label="Direccion" placeholder="Nombre del Terminal" icon="fas fa-map-marked-alt" errors={errors} required /></Col>


                </Row>
                {!readOnly && (
                    <div className="form-footer">
                        {loading && <div className="spinner"><Spinner /></div>}
                        <Button text={createMode ? 'Crear' : 'Actualizar'} onClick={createMode ? this.handleCreate : this.handleUpdate} />
                    </div>
                )}
            </>
        );
    }
}

TerminalForm.propTypes = {
    callback: PropTypes.func,
    data: PropTypes.object,
    readOnly: PropTypes.bool,
};

TerminalForm.defaultProps = {
    callback: null,
    data: null,
    readOnly: false,
};

TerminalForm.contextType = StateContext;

export default TerminalForm;
