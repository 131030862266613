/* eslint-disable react/sort-comp */
/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Spinner } from 'reactstrap';
import { StateContext } from '../../State';
import Input from '../../Components/Input/Input';
import Button from '../../Components/Button/Button';
import { createCustomer, getDepartments, updateClient } from '../../Service/Api';
import Select from '../../Components/Select/Select';

class DLClientForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            createMode: true,
            data: {
                DepartmentID: '-1',
            },
            errors: {},
            loading: false,
            profiles: [],
        };
        this.handleCreate = this.handleCreate.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const { data } = this.props;
        if (data) {
            data.order_number = data.dsu.order_number;
            data.DepartmentID = data.dsu.DepartmentID;
            this.setState({
                data,
                createMode: false,
            });
        }

        getDepartments()
            .then((response) => {
                this.setState({ profiles: this.formatDbDate(response.data) });
            })
            .catch((error) => {
                this.handleError(error);
            });
    }

    formatDbDate = (data) => {
        const departments = [];
        data.forEach((item) => {
            departments.push({
                id: item.DepartmentID,
                name: item.DepartmentName,
            });
        });
        return departments;
    }

    onChange(event) {
        const { name, value } = event.target;
        const { data, errors } = this.state;
        data[name] = name === 'DepartmentID' ? value.toString() : value;
        errors[name] = '';
        this.setState({
            data,
            errors,
        });
    }

    handleError = (error) => {
        const { status } = error.response;
        if (status === 401 || status === 403) {
            const [, dispatch] = this.context;
            dispatch({
                type: 'EXIT',
            });
        } else {
            this.setState({
                errors: this.formatError(error.response.data),
            });
        }
    }

    formatError = (value) => {
        const errors = [];
        Object.keys(value).forEach((keys) => {
            errors[keys] = value[keys][0];
        });
        return errors;
    }

    validForm() {
        const { data } = this.state;
        const {
            Address, CustomerName, CustomerMobile, Email, order_number, IDCard, DepartmentID,
        } = data;
        const errors = {};
        let formIsValid = true;

        if (!CustomerName || CustomerName.trim().length === 0) {
            formIsValid = false;
            errors.CustomerName = ['Requerido'];
        }

        if (!Address || Address.trim().length === 0) {
            formIsValid = false;
            errors.Address = ['Requerido'];
        }

        if (!CustomerMobile || CustomerMobile.trim().length === 0) {
            formIsValid = false;
            errors.CustomerMobile = ['Requerido'];
        }


        if (!Email || Email.trim().length === 0) {
            formIsValid = false;
            errors.Email = ['Requerido'];
        }

        if (!order_number || order_number.trim().length === 0) {
            formIsValid = false;
            errors.order_number = ['Requerido'];
        }

        if (!IDCard || IDCard.trim().length === 0) {
            formIsValid = false;
            errors.IDCard = ['Requerido'];
        }

        if (!DepartmentID || DepartmentID.trim().length === 0 || DepartmentID === '-1') {
            formIsValid = false;
            errors.DepartmentID = ['Requerido'];
        }

        this.setState({
            errors,
        });

        return formIsValid;
    }

    async handleCreate() {
        if (this.validForm()) {
            this.toggleLoading(true);
            const { data } = this.state;
            const params = { ...data, feature: 'dual_locker' };
            params.Product = params.Address;
            await createCustomer(params).then((response) => {
                if (response && response.status === 200) {
                    const { callback } = this.props;
                    callback();
                }
            }).catch((error) => {
                this.handleError(error);
            });
            this.toggleLoading(false);
        }
    }

    async handleUpdate() {
        if (this.validForm()) {
            this.toggleLoading(true);
            const { data } = this.state;
            const params = { ...data, feature: 'dual_locker' };
            await updateClient(params).then((response) => {
                if (response && response.status === 200) {
                    const { callback } = this.props;
                    callback();
                }
            }).catch((error) => {
                this.handleError(error);
            });
            this.toggleLoading(false);
        }
    }

    toggleLoading(value) {
        this.setState({
            loading: value,
        });
    }

    render() {
        const { data, profiles } = this.state;
        const { readOnly } = this.props;
        const {
            createMode, errors, loading,
        } = this.state;

        const {
            Address, CustomerName, CustomerMobile, Email, order_number, IDCard, DepartmentID,
        } = data;

        return (
            <>
                <Row>
                    <form onSubmit={(e) => e.preventDefault()} className="w-100" autoComplete="off">
                        <Col md={12}><Input name="order_number" value={order_number} onChange={this.onChange} readOnly={readOnly} label="Número Pedido" placeholder="Numero del pedido" icon="fas fa-hashtag" errors={errors} required /></Col>
                        <Col md={12}><Input name="CustomerName" value={CustomerName} onChange={this.onChange} readOnly={readOnly} label="Nombre" placeholder="Nombre del cliente" icon="fas fa-user" errors={errors} required /></Col>
                        <Col md={12}><Input name="IDCard" value={IDCard} onChange={this.onChange} readOnly={readOnly} label="Rut" mask="rut" placeholder="Rut del cliente" icon="fas fa-id-card" errors={errors} required /></Col>
                        <Col md={12}><Input name="Address" value={Address} onChange={this.onChange} readOnly={readOnly} label="Producto" placeholder="Nombre del producto" icon="fas fa-box-open" errors={errors} required /></Col>
                        <Col md={12}><Input name="CustomerMobile" mask="phone" value={CustomerMobile} onChange={this.onChange} readOnly={readOnly} label="Telefono del cliente" placeholder="Telefono del cliente" icon="fas fa-phone" errors={errors} required /></Col>
                        <Col md={12}><Input name="Email" value={Email} onChange={this.onChange} readOnly={readOnly} label="Email" placeholder="Email del cliente" icon="fas fa-envelope" errors={errors} required /></Col>
                        <Col md={12}><Select label="Service Station" value={DepartmentID} options={profiles} placeholder="Seleccione..." name="DepartmentID" onChange={this.onChange} readOnly={readOnly} errors={errors} required /></Col>
                    </form>
                </Row>
                {!readOnly && (
                    <div className="form-footer">
                        {loading && <div className="spinner"><Spinner /></div>}
                        <Button text={createMode ? 'Crear' : 'Actualizar'} onClick={createMode ? this.handleCreate : this.handleUpdate} />
                    </div>
                )}
            </>
        );
    }
}

DLClientForm.propTypes = {
    callback: PropTypes.func,
    data: PropTypes.object,
    readOnly: PropTypes.bool,
};

DLClientForm.defaultProps = {
    callback: null,
    data: null,
    readOnly: false,
};

DLClientForm.contextType = StateContext;

export default DLClientForm;
