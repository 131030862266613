/* eslint-disable react/sort-comp */
/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Spinner } from 'reactstrap';
import { StateContext } from '../../State';
import Input from '../../Components/Input/Input';
import Multiple from '../../Components/Multiple/Multiple';
import Button from '../../Components/Button/Button';
import Select from '../../Components/Select/Select';
import { createTerminal, updateTerminal, getDepartments, getDevices } from '../../Service/Api';
import {
    formatRut, unformatRut, formatPhone, unformatPhone,
} from '../../Service/Utils';
import ReactSelect from "react-select";

class TerminalForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createMode: true,
            data: {},
            errors: {},
            profiles: [],
            loading: false,
            devices: [],
            deviceType: 0
        };
        this.handleCreate = this.handleCreate.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onBlurRut = this.onBlurRut.bind(this);
        this.onFocusRut = this.onFocusRut.bind(this);
        this.onBlurPhone = this.onBlurPhone.bind(this);
        this.onFocusPhone = this.onFocusPhone.bind(this);
        this.onChangeRadio = this.onChangeRadio.bind(this);
    }

    componentDidMount() {
        this._getDevices();
        const { data } = this.props;
        if (data) {
            data.DeviceType = data.DeviceType;
            const devices = data.devices.map(d => ({ label: d.DeviceName, value: d.ID }));
            data.Devices = devices;
            data.phone = formatPhone(data.phone);
            data.rut = formatRut(data.rut);
            data.Password = ""
            this.setState({
                data,
                createMode: false,
            });
        }
        getDepartments()
            .then((response) => {
                this.setState({ profiles: this.formatDbDate(response.data) });
            })
            .catch((error) => {
                this.handleError(error);
            });
    }

    _getDevices = () => {
        getDevices().then(resp => {
            const devices = resp.data.filter(d => d.terminal === null).map(d => ({ value: d.ID, label: d.DeviceName }));
            this.setState({ ...this.state, devices });
        }).catch(this.handleError);
    }

    formatDbDate = (data) => {
        const departments = [];
        data.forEach((item) => {
            departments.push({
                id: item.DepartmentID,
                name: item.DepartmentName,
            });
        });
        return departments;
    }

    onChange(event) {
        const { name, value } = event.target;
        const { data, errors } = this.state;
        data[name] = value;
        errors[name] = '';
        this.setState({
            data,
            errors,
        });
    }

    onBlurRut() {
        const { data } = this.state;
        data.rut = formatRut(data.rut);
        this.setState({
            data,
        });
    }

    onFocusRut() {
        const { data } = this.state;
        data.rut = unformatRut(data.rut);
        this.setState({
            data,
        });
    }

    onBlurPhone() {
        const { data } = this.state;
        data.phone = formatPhone(data.phone);
        this.setState({
            data,
        });
    }

    onFocusPhone() {
        const { data } = this.state;
        data.phone = unformatPhone(data.phone);
        this.setState({
            data,
        });
    }

    handleError = (error) => {
        const { status } = error.response;
        if (status === 401 || status === 403) {
            const [, dispatch] = this.context;
            dispatch({
                type: 'EXIT',
            });
        } else {
            this.setState({
                errors: this.formatError(error.response.data),
            });
        }
    }

    formatError = (value) => {
        const errors = [];
        Object.keys(value).forEach((keys) => {
            errors[keys] = value[keys][0];
        });
        return errors;
    }

    validForm() {
        const { data, createMode } = this.state;
        const {
            TerminalName, Username, DepartmentID, Location, Password, repeatPassword,
        } = data;
        const errors = {};
        let formIsValid = true;

        if (!TerminalName || TerminalName.trim().length === 0) {
            formIsValid = false;
            errors.TerminalName = ['Requerido'];
        }

        if (!Username || Username.trim().length === 0) {
            formIsValid = false;
            errors.Username = ['Requerido'];
        }

        if (!DepartmentID || DepartmentID.trim().length === 0) {
            formIsValid = false;
            errors.DepartmentID = ['Requerido'];
        }

        if (!Location || Location.trim().length === 0) {
            formIsValid = false;
            errors.Location = ['Requerido'];
        }


        if (createMode) {
            if (!Password || Password.trim().length === 0) {
                formIsValid = false;
                errors.Password = ['Requerido'];
            }

            if (!repeatPassword || repeatPassword.trim().length === 0) {
                formIsValid = false;
                errors.repeatPassword = ['Requerido'];
            } else if (repeatPassword !== Password) {
                formIsValid = false;
                errors.repeatPassword = ['Debe coincidir'];
            }
        }

        this.setState({
            errors,
        });

        return formIsValid;
    }

    async handleCreate() {
        if (this.validForm()) {
            this.toggleLoading(true);
            const { data } = this.state;
            data.phone = unformatPhone(data.phone);
            data.rut = unformatRut(data.rut);
            await createTerminal(data).then((response) => {
                if (response && response.status === 201) {
                    const { callback } = this.props;
                    callback();
                }
            }).catch((error) => {
                this.handleError(error);
            });
            this.toggleLoading(false);
        }
    }

    async handleUpdate() {
        if (this.validForm()) {
            this.toggleLoading(true);
            const { data } = this.state;
            await updateTerminal(data, data.TerminalNo).then((response) => {
                if (response && response.status === 200) {
                    const { callback } = this.props;
                    callback();
                }
            }).catch((error) => {
                this.handleError(error);
            });
            this.toggleLoading(false);
        }
    }

    toggleLoading(value) {
        this.setState({
            loading: value,
        });
    }

    onChangeRadio(data) {
        this.setState({ ...this.state, data: { ...this.state.data, Devices: data } })
    }

    render() {
        const { readOnly } = this.props;
        const {
            createMode, errors, data, loading, profiles
        } = this.state;
        const {
            TerminalName, Username, Location, DepartmentID, Password, repeatPassword, DeviceType, Devices
        } = data;

        const deviceTypeOptions = [{ name: "DLL", id: 0 }, { name: "ESP", id: 1 }];

        return (
            <>
                <Row>
                    <Col md={6}><Input name="TerminalName" value={TerminalName} onChange={this.onChange} readOnly={readOnly} label="Nombre Terminal" placeholder="Nombre del Terminal" icon="fas fa-user-alt" errors={errors} required /></Col>
                    <Col md={6}><Input name="Username" value={Username} onChange={this.onChange} readOnly={readOnly} label="Usuario" placeholder="Nombre del usuario" icon="fas fa-user-alt" errors={errors} required /></Col>
                    <Col md={12}><Input name="Location" value={Location} onChange={this.onChange} readOnly={readOnly} label="Dirección" placeholder="Direccion" icon="far fa-address-card" errors={errors} required onBlur={this.onBlurRut} onFocus={this.onFocusRut} /></Col>
                    <Col md={12}>
                        <Select label="Service Station" value={DepartmentID} options={profiles} placeholder="Seleccione..." name="DepartmentID" onChange={this.onChange} readOnly={readOnly} errors={errors} required />
                    </Col>
                    <Col md={12}><Multiple label="Tipo de dispositivo" options={deviceTypeOptions} name="DeviceType" onChange={this.onChange} /></Col>
                    <Col md={12}>
                        {
                            DeviceType === "1" && (
                                <>
                                    <div className="select-container" style={{ marginBottom: 20 }}>
                                        Dispositivos *
                                        <i className="far fa-microchip" />
                                        <ReactSelect defaultValue={Devices} disable={readOnly} placeholder="Seleccione..." name="Devices" isMulti options={this.state.devices} onChange={this.onChangeRadio} />
                                    </div>
                                </>
                            )
                        }
                    </Col>
                    {!createMode && (
                        <Col md={12}><Input name="Password" type="password" value={Password} onChange={this.onChange} readOnly={readOnly} hideReadOnly label="Contraseña" placeholder="Contraseña" errors={errors} required /></Col>
                    )}
                    {createMode && (
                        <>
                            <Col md={6}><Input name="Password" type="password" value={Password} onChange={this.onChange} readOnly={readOnly} hideReadOnly label="Contraseña" placeholder="Contraseña" errors={errors} required /></Col>
                            <Col md={6}><Input name="repeatPassword" type="password" value={repeatPassword} onChange={this.onChange} readOnly={readOnly} hideReadOnly label="Repita contraseña" placeholder="Repita contraseña" errors={errors} required /></Col>
                        </>
                    )}
                </Row>
                {
                    !readOnly && (
                        <div className="form-footer">
                            {loading && <div className="spinner"><Spinner /></div>}
                            <Button text={createMode ? 'Crear' : 'Actualizar'} onClick={createMode ? this.handleCreate : this.handleUpdate} />
                        </div>
                    )
                }
            </>
        );
    }
}

TerminalForm.propTypes = {
    callback: PropTypes.func,
    data: PropTypes.object,
    readOnly: PropTypes.bool,
};

TerminalForm.defaultProps = {
    callback: null,
    data: null,
    readOnly: false,
};

TerminalForm.contextType = StateContext;

export default TerminalForm;
