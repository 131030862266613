import React from 'react';

// Images
import image1 from '../assets/img/manuals/service_station1.png'
import image2 from '../assets/img/manuals/service_station2.png'

import Image from '../Components/Image/Image';

import './Manuals.scss';

const ServiceStation = (props) => {
    const { } = props;

    return (
        <div className="manuals">

            <h3>Service Stations</h3>

            <p>
                Un Service Station es una ubicación física asociada con una empresa donde se encuentra un Locker y/o se instalará uno.
            </p>

            <h3>Crear Service Station</h3>

            <p>
                1 - Para crear un service station, primero haga clic en Service Station en el menú de la izquierda.
                Luego haga clic en el símbolo de agregar en la esquina superior derecha.
            </p>

            <Image file={image1} />

            <p>2 -Completa los datos solicitados</p>

            <Image file={image2} />

            <p>3 - Haga click en Crear</p>
        </div>
    )
}

export default ServiceStation;