import React from 'react';

import image1 from '../assets/img/manuals/residents01.png'
import image2 from '../assets/img/manuals/residents02.png'
import image3 from '../assets/img/manuals/residents03.png'
import Image from '../Components/Image/Image';

import './Manuals.scss';

const Residents = (props) => {
    const { } = props;

    return (
        <div className="manuals">

            <h3>Residentes</h3>

            <p>
                Los residentes son las personas a las que se entregarán los pedidos. <br />
                Un residente está asociado a un service station, lo que hace con que esté disponible en todos los terminales asociados a ese service station.
            </p>

            <h3>Crear Residentes</h3>

            <p>
                1 - Para crear un residente, primero haga clic en Residentes en el menú de la izquierda.
                Luego haga clic en el símbolo de agregar en la esquina superior derecha.
            </p>

            <Image file={image1} />

            <p>2 -Completa los datos solicitados</p>

            <Image file={image3} />

            <p>3 - Haga click en Crear</p>


            <h3>Carga Masiva</h3>

            <p>
                1 - Descarga el archivo base y completar los campos
            </p>

            <p>
                2 - Eligir este archivo y un service station y guardar
            </p>

            <Image file={image2} />

        </div>
    )
}

export default Residents;