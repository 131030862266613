/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import classes from './DualLocker.module.scss';
import Select from '../../Components/Select/Select';

const DualLocker = (props) => {
    const {
        onChange, user, users, loading, dualLocker, saveDualLocker,
    } = props;

    return (
        <div className={classes.section}>
            <div className={classes.info}>
                * Los Dual Locker son casilleros donde hay una pantalla para realizar entregas por un lado y una pantalla para realizar retiros en el otro. Las dos computadoras están asociadas con el mismo terminal y pueden trabajar en sincronía con entregas y retiros.
            </div>

            <div className="row mt-3">
                <div className="col">
                    <Select label="Empresa" value={user.toString()} options={users} placeholder="Seleccione..." name="user" onChange={onChange} required loading={loading} />
                </div>
            </div>

            {user !== -1 && (
                <div className="controls">
                    <div className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" id="customSwitch1" name="dual-loacker-switch" checked={dualLocker} onChange={onChange} />
                        <label className="custom-control-label" htmlFor="customSwitch1">
                            {dualLocker ? 'Deshabilitar' : 'Habilitar'}
                            {' '}
                            el dual locker para esta empresa
                        </label>
                    </div>

                    <div className="col mt-4 d-flex justify-content-end">
                        <button type="button" className=" w-100 btn btn-secondary" onClick={saveDualLocker}>Guardar</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DualLocker;
