/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-param-reassign */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';
import moment from 'moment';
import { debounce } from 'lodash';
import TopBar from '../../Components/TopBar/TopBar';
import UserForm from './PackageForm';
import { StateContext } from '../../State';
import ModalView from '../../Layout/ModalView/ModalView';
import { tableConfig } from '../../config';
import { ConfirmDialog } from '../../Components/Dialog/Dialog';
import {
    getPackages, deleteUser, restoreUser, sendSMS, sendEmail, post,
} from '../../Service/Api';
import { checkString } from '../../Service/Utils';
import DownloadCSVButton from '../../Components/DownloadCSVButton/DownloadCSVButton';
import Modal from '../../Components/Modal';

class Packages extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            showConfirm: false,
            data: [],
            loading: false,
            deleteId: null,
            restoreId: null,
            loadingCsv: false,
            csvData: [],
            table: {
                pages: 0,
                page: 0,
                page_url: `${process.env.REACT_APP_API_URL}/get-package`,
                params: {},
                filtered: [],
                sorted: [],
                pageSize: 10,
                perPage: 10,

            },
            modal: {
                downloadCsv: {
                    open: false,
                },
            },
            showOrderNumber: false,
            showDirection: true,
        };
        this.handleRemove = this.handleRemove.bind(this);
        this.handleRestore = this.handleRestore.bind(this);
        this.loadData = this.loadData.bind(this);
        this.findData = this.findData.bind(this);
        this.removeUser = this.removeUser.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        const [state] = this.context;
        const { user } = state;
        const { features } = user;

        if (features && features.find((feat) => feat.title === 'Agregar Pedido Multi Caja')) {
            this.setState({ showDirection: false });
        }
        this.getTableData({
            filtered: [],
            sorted: [],
            perPage: 10,
            page: 0,
        });
        if (features) {
            const findFeature = features.find((item) => item.title === 'Agregar Pedido');
            if (findFeature) {
                this.setState({ showOrderNumber: true });
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    findData = (id) => {
        const { data } = this.state;
        return data.find((item) => item.customer.CustomerID.toString() === id.toString());
    }

    handleError = (error) => {
        const { status } = error.response;
        if (status === 401 || status === 403) {
            const [, dispatch] = this.context;
            dispatch({
                type: 'EXIT',
            });
        }
    }

    handleSMS = (id) => {
        sendSMS({ id }).then(() => {
            alert('SMS enviado con exito');
        }).catch(() => {
            alert('Envío fallido. Intente reenviar en unos momentos');
        });
    }

    handleEmail = (id) => {
        sendEmail({ id }).then(() => {
            alert('Email enviado con exito');
        }).catch(() => {
            alert('Envío fallido. Intente reenviar en unos momentos');
        });
    }

    async loadData() {
        this.setState({
            loading: true,
        });

        await getPackages()
            .then((response) => {
                if (this._isMounted) {
                    this.setState({
                        data: response.data,
                        loading: false,
                        deleteId: null,
                        restoreId: null,
                    });
                }
            }).catch((error) => {
                this.handleError(error);
            });
    }

    async removeUser() {
        const { deleteId } = this.state;
        await deleteUser(deleteId).then((response) => {
            if (response && response.status === 200) {
                this.loadData();
            }
        }).catch((error) => {
            this.handleError(error);
        });
    }

    async restoreUser() {
        const { restoreId } = this.state;
        await restoreUser(restoreId).then((response) => {
            if (response && response.status === 200) {
                this.loadData();
            }
        }).catch((error) => {
            this.handleError(error);
        });
    }

    handleNewFields = (terminals) => {
        terminals.forEach((item) => {
            item.box_length = item.boxes.length;
            item.DepartmentName = item.department[0].DepartmentName;
        });
        return terminals;
    }

    handleRemove(id) {
        this.setState({
            showConfirm: true,
            deleteId: id,
        });
    }

    handleRestore(id) {
        this.setState({
            showConfirm: true,
            restoreId: id,
        });
    }

    handleStatus = (status) => {
        switch (parseInt(status)) {
            case 1:
                return 'Retirado';
            case 4:
                return 'Aguardando Courier';
            default:
                return 'En el locker';
        }
    }

    filterFunction = (rows, filter, props) => {
        const r = [];
        rows.forEach((item) => {
            if (props === 'StoredDate') {
                if (checkString(filter.value, item[props].format('DD-MM-YYYY')) !== -1) {
                    r.push(item);
                }
            }
        });
        return r;
    }

    getTableData = (state) => {
        const { table } = this.state;
        this.setState({ loading: true, loadingCsv: true });
        if (table.page_url) {
            const url = `${table.page_url}?page=${state.page + 1}`;
            const params = {};
            params.filters = state.filtered;
            params.sorted = state.sorted;
            params.per_page = state.perPage;
            post(url, params).then((response) => {
                let registers = [];
                if (typeof response.data.data === 'object') {
                    Object.keys(response.data.data).forEach((key) => {
                        registers.push(response.data.data[key]);
                    });
                } else {
                    registers = response.data.data;
                }
                console.log(registers);
                this.setState({
                    data: registers,
                    loading: false,
                    deleteId: null,
                    restoreId: null,
                    table: {
                        ...table,
                        page: response.data.current_page - 1,
                        pages: response.data.last_page,
                        filtered: state.filtered,
                        sorted: state.sorted,
                        perPage: state.perPage,
                        params,
                    },
                });
            });
        }
    }

    debounceEvent = debounce((e) => {
        this.getTableData(e);
    }, 300)

    _toggleModal = () => {
        this.setState({ modal: { downloadCsv: { open: !this.state.modal.downloadCsv.open } } });
    }

    _loadDataToDownload = () => {
        const { table } = this.state;
        const modal = { downloadCsv: { open: true } };
        this.setState({ loadingCsv: true, modal });
        if (table.page_url) {
            const url = `${table.page_url}`;
            const params = this.state.table;
            params.download = true;
            params.filters = params.filtered;
            post(url, params).then((response) => {
                let registers = [];

                if (typeof response.data === 'object') {
                    Object.keys(response.data).forEach((key) => {
                        registers.push(response.data[key]);
                    });
                } else {
                    registers = response.data;
                }

                const data = registers.map((item) => {
                    delete item.customer;
                    delete item.reservation;
                    item.StoredTime = moment(item.StoredTime).format('HH:mm:ss');
                    item.PackageStatus = parseInt(item.PackageStatus) === 0 ? 'En el Locker' : 'Retirado';
                    return item;
                });

                this.setState({ csvData: data, loadingCsv: false });
            });
        }
    }

    render() {
        const {
            showConfirm, data, csvData, loadingCsv, loading, deleteId, restoreId, table,
        } = this.state;

        const [{ role }] = this.context;
        const columns = [
            {
                Header: 'Customer',
                accessor: (row) => row.customer.CustomerName,
                id: 'CustomerName',
                Cell: (row) => (
                    <ModalView title={row.original.customer.CustomerName}>
                        <UserForm data={this.findData(row.original.customer.CustomerID)} readOnly />
                    </ModalView>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['CustomerName'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Package ID',
                accessor: 'PackageID',
                Cell: (row) => row.original.PackageID,
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['PackageID'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Orden Venta',
                id: 'order_number',
                accessor: (row) => (row.order_number ? row.order_number : ''),
                Cell: (row) => (row.original.order_number ? row.original.order_number : ''),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['order_number'] }),
                filterAll: true,
                filterable: true,
                show: this.state.showOrderNumber,
            },
            {
                Header: 'Fecha de Almacenamiento',
                id: 'StoredDate',
                accessor: (row) => moment(row.StoredDate),
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'center' }}>{moment(row.original.StoredDate).format('DD-MM-YYYY')}</div>
                ),
                filterMethod: (filter, rows) => this.filterFunction(rows, filter, 'StoredDate'),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Hora de Almacenamiento',
                accessor: 'stored_time',
                id: 'stored_time',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'center' }}>{moment(row.original.stored_time).format('HH:mm:ss')}</div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['stored_time'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Clave',
                accessor: 'BlankBoxKey',
                Cell: (row) => (
                    <div style={{ textAlign: 'center', width: '100%' }}>{row.original.BlankBoxKey}</div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['BlankBoxKey'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Box',
                accessor: (row) => row.box_number,
                id: 'box_number',
                width: 100,
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        {row.original.box_number && Array.isArray(JSON.parse(row.original.box_number))
                            ? <>{JSON.parse(row.original.box_number).join(', ')}</>
                            : <>{row.original.box_number}</>
                        }
                    </div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['box_number'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Terminal',
                accessor: (row) => row.TerminalName,
                id: 'TerminalName',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'center' }}>{row.original.TerminalName}</div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['Terminal'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Direccion',
                accessor: (row) => row.Address,
                id: 'Address',
                Cell: (row) => (
                    <div>{row.original.Address}</div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['Address'] }),
                filterAll: true,
                filterable: true,
                show: this.state.showDirection,
            },
            {
                Header: 'Status',
                accessor: (row) => this.handleStatus(row.PackageStatus),
                id: 'PackageStatus',
                Cell: (row) => (
                    <div style={{ width: '100%', textAlign: 'center' }}>{this.handleStatus(row.original.PackageStatus)}</div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['PackageStatus'] }),
                filterAll: true,
                filterable: true,
            },
            {
                Header: 'Acciones',
                id: 'actions',
                Cell: (row) => (
                    <div style={{
                        width: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',
                    }}
                    >
                        <>
                            {role.name === 'administrator' && row.original.reservation !== null && row.original.reservation.id !== undefined && this.handleStatus(row.original.PackageStatus) !== 'Retirado' && (
                                <>
                                    <span style={{ margin: '0 10px', cursor: 'pointer' }} className="fas fa-envelope" onClick={() => this.handleEmail(row.original.reservation.id)} />
                                    <span style={{ margin: '0 10px', cursor: 'pointer' }} className="fas fa-comment-alt" onClick={() => this.handleSMS(row.original.reservation.id)} />
                                </>
                            )}

                        </>
                    </div>
                ),
                filterAll: false,
                filterable: false,
            },


        ];

        return (
            <>
                <TopBar>
                    <div className="w-100 d-flex justify-content-between align-items-center mb-2">
                        <div className="d-flex" style={{ color: '#666 !important' }}>
                            <div className="d-flex mr-5 my-3" style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <button className="btn" type="button" onClick={this._loadDataToDownload}><i className="fas fa-file-download" style={{ fontSize: '35px' }} /></button>
                                Descargar CSV
                            </div>
                        </div>
                    </div>
                </TopBar>

                <ReactTable
                    data={data}
                    columns={columns}
                    {...tableConfig}
                    loading={loading}
                    manual
                    pages={table.pages}
                    page={table.page}
                    onPageChange={(state) => this.debounceEvent({ ...this.state.table, page: state })}
                    onPageSizeChange={(perPage) => this.debounceEvent({ ...this.state.table, perPage })}
                    onFilteredChange={(filtered) => this.debounceEvent({ ...this.state.table, filtered })}
                    onSortedChange={(newSorted) => this.debounceEvent({ ...this.state.table, sorted: newSorted })}
                />

                <Modal open={this.state.modal.downloadCsv.open}>
                    <div className="bg-color-white rounded px-4">
                        <div className="row px-2 py-3">
                            <div className="col-12 pb-2" style={{ fontSize: '1.3em', borderBottom: '1px solid #d3d3d3' }}>
                                Descargar CSV
                            </div>
                            {!this.state.loadingCsv && (
                                <>
                                    <div className="col-12 text-center pt-2">
                                        <DownloadCSVButton data={csvData} filename="packages.csv" disabled={loadingCsv} />
                                    </div>
                                    <div className="col-12 text-center pb-2">
                                        Descargar CSV
                                    </div>
                                </>
                            )}

                            {this.state.loadingCsv && (
                                <div className="col-12 pt-2 d-flex justify-content-center align-items-center">
                                    <span style={{ fontSize: '1.2em' }}>Espera mientras generamos el archivo de descarga...</span>
                                    <div className="spinner-border ml-3" role="status" style={{ width: '15px', height: '15px' }} />
                                </div>
                            )}

                            <div className="col-12 text-right mt-3">
                                <button type="button" className="btn btn-danger" onClick={this._toggleModal}>Cerrar</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <ConfirmDialog
                    show={showConfirm}
                    title={deleteId ? 'Eliminar usuario' : 'Restablecer usuario'}
                    onConfirm={() => {
                        if (deleteId) {
                            this.removeUser();
                        }
                        if (restoreId) {
                            this.restoreUser();
                        }
                        this.setState({ showConfirm: false });
                    }}
                    onCancel={() => {
                        this.setState({ showConfirm: false });
                    }}
                />
            </>
        );
    }
}

Packages.contextType = StateContext;

export default Packages;
