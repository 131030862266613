/* eslint-disable react/prop-types */
import React from 'react';

const Modal = (props) => {
    const { children, open } = props;

    const styles = {
        width: '100%',
        height: '100vh',
        background: '#00000040',
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: 1000,
        justifyContent: 'center',
        alignItems: 'center',
    };

    return (
        <div className={`modal-component ${open ? 'd-flex' : 'd-none'}`} style={styles}>
            {children}
        </div>
    );
};

export default Modal;
