/* eslint-disable camelcase */
/* eslint-disable consistent-return */
export const createCsv = (data, name = null) => {
    try {
        let csvContent = 'data:text/csv;charset=utf-8,';
        csvContent += data.headers.join(',');
        csvContent += '\n';
        csvContent += data.data.map((e) => e.join(',')).join('\n');
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', name || 'my_data.csv');
        document.body.appendChild(link);
        link.click();
    } catch (e) {
        console.log(e.message);
        return false;
    }
};

export const createCsvByText = (data, name = null) => {
    try {
        const csvFile = new Blob([data], {
            type: 'text/csv',
        });
        const link = document.createElement('a');
        link.setAttribute('href', window.URL.createObjectURL(csvFile));
        link.setAttribute('download', name || 'my_data.csv');
        document.body.appendChild(link);
        link.click();
    } catch (e) {
        console.log('LOGGER', e.message);
        return false;
    }
};

export const createFieldsData = (fields, data) => {
    try {
        const csvData = data.map((element) => {
            const row = [];
            fields.forEach((item) => {
                const arrayFields = item.split('.');
                let info = null;
                arrayFields.forEach((sub_item) => {
                    if (info === null || info === undefined) {
                        info = element[sub_item];
                    } else {
                        info = info[sub_item];
                    }
                });
                let result = '';

                if (info) {
                    info = info.toString();
                    result = info.replace(/,/g, ' -');
                    result = `"${result}"`;
                }

                row.push(result);
            });
            return row;
        });
        return csvData;
    } catch (e) {
        console.log(e.message);
        return false;
    }
};
