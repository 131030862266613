/* eslint-disable react/sort-comp */
/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Spinner } from 'reactstrap';
import { StateContext } from '../../State';
import Input from '../../Components/Input/Input';
import Button from '../../Components/Button/Button';
import Select from '../../Components/Select/Select';
import { createDepartment, updateDepartment, getCompany } from '../../Service/Api';

class ServiceStationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createMode: true,
            data: {},
            errors: {},
            loading: false,
            profiles: [],
        };
        this.handleCreate = this.handleCreate.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const { data } = this.props;

        getCompany().then((response) => {
            const profiles = response.data;
            profiles.sort((a, b) => {
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                return 0;
            });
            this.setState({
                profiles,
            });
        }).catch();

        if (data) {
            if (data.company !== null) {
                data.user_id = data.company.user_id;
            }

            this.setState({
                data,
                createMode: false,
            });
        }
    }

    formatDbDate = (data) => {
        const departments = [];
        data.forEach((item) => {
            departments.push({
                id: item.DepartmentID,
                name: item.DepartmentName,
            });
        });

        return departments;
    }

    onChange(event) {
        const { name, value } = event.target;
        const { data, errors } = this.state;
        data[name] = value;
        errors[name] = '';
        this.setState({
            data,
            errors,
        });
    }

    handleError = (error) => {
        const { status } = error.response;
        if (status === 401 || status === 403) {
            const [, dispatch] = this.context;
            dispatch({
                type: 'EXIT',
            });
        } else {
            this.setState({
                errors: this.formatError(error.response.data),
            });
        }
    }

    formatError = (value) => {
        const errors = [];
        Object.keys(value).forEach((keys) => {
            errors[keys] = value[keys][0];
        });
        return errors;
    }

    validForm() {
        const { data } = this.state;
        const {
            DepartmentName, Address, ContactTel, ContactPerson,
        } = data;
        const errors = {};
        let formIsValid = true;

        if (!DepartmentName || DepartmentName.trim().length === 0) {
            formIsValid = false;
            errors.DepartmentName = ['Requerido'];
        }

        if (!Address || Address.trim().length === 0) {
            formIsValid = false;
            errors.Address = ['Requerido'];
        }

        if (!ContactTel || ContactTel.trim().length === 0) {
            formIsValid = false;
            errors.ContactTel = ['Requerido'];
        }

        if (!ContactPerson || ContactPerson.trim().length === 0) {
            formIsValid = false;
            errors.ContactPerson = ['Requerido'];
        }

        this.setState({
            errors,
        });

        return formIsValid;
    }

    async handleCreate() {
        if (this.validForm()) {
            this.toggleLoading(true);
            const { data } = this.state;
            await createDepartment(data).then((response) => {
                if (response && response.status === 200) {
                    const { callback } = this.props;
                    callback();
                }
            }).catch((error) => {
                this.handleError(error);
            });
            this.toggleLoading(false);
        }
    }

    async handleUpdate() {
        if (this.validForm()) {
            this.toggleLoading(true);
            const { data } = this.state;
            await updateDepartment(data).then((response) => {
                if (response && response.status === 200) {
                    const { callback } = this.props;
                    callback();
                }
            }).catch((error) => {
                this.handleError(error);
            });
            this.toggleLoading(false);
        }
    }

    toggleLoading(value) {
        this.setState({
            loading: value,
        });
    }

    render() {
        const { data, profiles } = this.state;
        const { readOnly } = this.props;
        const {
            createMode, errors, loading,
        } = this.state;

        const {
            Address, DepartmentID, DepartmentName, ContactTel, ContactPerson, user_id,
        } = data;

        return (
            <>
                <Row>
                    {data.DepartmentID && (
                        <Col md={12}><Input name="DepartmentID" value={DepartmentID} onChange={this.onChange} readOnly label="Service Station ID" placeholder="Service Station ID" icon="fas fa-hashtag" errors={errors} required /></Col>
                    )}
                    <Col md={12}><Input name="DepartmentName" value={DepartmentName} onChange={this.onChange} readOnly={readOnly} label="Nombre" placeholder="Nombre del Service Station" icon="fas fa-building" errors={errors} required /></Col>
                    <Col md={12}><Input name="Address" value={Address} onChange={this.onChange} readOnly={readOnly} label="Dirección" placeholder="Dirección del Service Station" icon="fas fa-map-marked-alt" errors={errors} required /></Col>
                    <Col md={12}><Input name="ContactPerson" value={ContactPerson} onChange={this.onChange} readOnly={readOnly} label="Contato" placeholder="Nombre del contato" icon="fas fa-user" errors={errors} required /></Col>
                    <Col md={12}><Input name="ContactTel" mask="phone" value={ContactTel} onChange={this.onChange} readOnly={readOnly} label="Telefono" placeholder="Telefono del Service Station" icon="fas fa-phone" errors={errors} required /></Col>
                    <Col md={12}><Select label="Empresa" value={user_id} options={profiles} placeholder="Seleccione..." name="user_id" onChange={this.onChange} readOnly={readOnly} errors={errors} required /></Col>
                </Row>
                {!readOnly && (
                    <div className="form-footer">
                        {loading && <div className="spinner"><Spinner /></div>}
                        <Button text={createMode ? 'Crear' : 'Actualizar'} onClick={createMode ? this.handleCreate : this.handleUpdate} />
                    </div>
                )}
            </>
        );
    }
}

ServiceStationForm.propTypes = {
    callback: PropTypes.func,
    data: PropTypes.object,
    readOnly: PropTypes.bool,
};

ServiceStationForm.defaultProps = {
    callback: null,
    data: null,
    readOnly: false,
};

ServiceStationForm.contextType = StateContext;

export default ServiceStationForm;
